import dayjs from 'dayjs';
import { ChartsColors } from '../../../../../../../../../constants/charts';

const chartConfig = {
  xField: 'timestamp',
  yField: 'temperature',
  seriesField: 'channel',
  theme: {
    styleSheet: {
      fontFamily: 'Acciona Font',
    },
  },
  meta: {
    timestamp: {
      // tickCount: 10,
      formatter: (v, i) => dayjs(v).format('DD/MM/YYYY'),
    },
    temperature: {
      formatter: (v) => `${v}ºC`,
    },
  },
  color: ({ channel }) => {
    return ChartsColors[channel === 'mean' ? 9 : channel[2] - 1];
  },

  lineStyle: ({ channel }) => {
    if (channel === 'mean') {
      return { lineWidth: 3, lineDash: [5, 5] };
    }
  },
};

export default chartConfig;
