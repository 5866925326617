import React from 'react';
import { PropTypes } from 'prop-types';
import spinner from '../../style/img/ajax-loader.svg';

const Spinner = ({ hidden }) => (
  <div
    style={{ minHeight: '100vh', position: 'fixed' }}
    id="spinner"
    className="spinner"
    hidden={hidden}
  >
    <img src={spinner} height="64" width="64" alt="Loading"></img>
  </div>
);

Spinner.propTypes = {
  hidden: PropTypes.bool,
};

Spinner.defaultProps = {
  hidden: false,
};

export default Spinner;
