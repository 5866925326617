import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import AccionaButton from '../../../../../../../components/AccionaButton';
import Modal from '../../../../../../../components/AccionaModal';
import styles from './index.module.scss';

const { buttons, text } = styles;

const InProgressPreregisterModal = ({ open, onCancel, onOk }) => {
  const intl = useIntl();
  return (
    <Modal open={open} onCancel={onCancel} width="80vw">
      <>
        <div className={`${text}`}>
          <p>{intl.formatMessage({ id: 'preregister.pending' })}</p>
        </div>
        <div className={`form-row ${buttons}`}>
          <AccionaButton
            msg={intl.formatMessage({ id: 'yes' })}
            onClick={onOk}
          />
          <AccionaButton
            msg={intl.formatMessage({ id: 'no' })}
            onClick={onCancel}
          />
        </div>
      </>
    </Modal>
  );
};

InProgressPreregisterModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
};

InProgressPreregisterModal.defaultProps = {
  open: false,
};

export default InProgressPreregisterModal;
