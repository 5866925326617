import { useState } from 'react';
import Step from '../../../../../../../../components/Step';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import AntdFormItem from '../../../../../../../../components/AntdFormItem';
import AntdTimePicker from '../../../../../../../../components/AntdTimePicker';
import dayjs from 'dayjs';

const FinishDischargingTime = ({
  onFinish,
  preregister,
  onCancel,
  stepNumber,
}) => {
  const intl = useIntl();
  const [finishDischargingTime, setFinishDischargingTime] = useState(
    preregister.finish_discharging_time
      ? dayjs(preregister.finish_discharging_time, 'HH:mm')
      : dayjs(),
  );

  return (
    <Step
      stepNumber={stepNumber}
      title={intl.formatMessage({
        id: 'preregisterWizard.section.preregister.step06',
      })}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.preregister',
      })}
      content={
        <>
          <AntdFormItem
            layout="vertical"
            label={intl.formatMessage({ id: 'finish_discharging_time' })}
          >
            <AntdTimePicker
              size="large"
              onChange={(inputValue) => setFinishDischargingTime(inputValue)}
              value={finishDischargingTime}
              disabledTime={(timeString) => {
                const customTime = dayjs(
                  preregister.start_discharging_time,
                  'HH:mm',
                );
                const customHour = customTime.hour();
                const customMinute = customTime.minute();

                return {
                  disabledHours: () => {
                    const hours = [];
                    for (let i = 0; i < customHour; i++) {
                      hours.push(i);
                    }
                    return hours;
                  },
                  disabledMinutes: (selectedHour) => {
                    if (selectedHour === customHour) {
                      const minutes = [];
                      for (let i = 0; i <= customMinute; i++) {
                        minutes.push(i);
                      }
                      return minutes;
                    }
                    return [];
                  },
                };
              }}
              allowClear
            />
          </AntdFormItem>
        </>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish({
          ...preregister,
          finish_discharging_time: finishDischargingTime.format('HH:mm'),
        });
      }}
      disableNextButton={!finishDischargingTime}
    />
  );
};

FinishDischargingTime.propTypes = {
  preregister: PropTypes.shape({
    finish_discharging_time: PropTypes.string,
    start_discharging_time: PropTypes.string,
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
};

FinishDischargingTime.defaultProps = {
  preregister: {},
};

export default FinishDischargingTime;
