import { PropTypes } from 'prop-types';
import AccionaIcon from '../AccionaIcon';

const AccionaListButton = ({
  onClick,
  hidden,
  disabled,
  toolTip,
  type,
  style,
}) => {
  const classButtons = {
    verticalAlign: 'initial',
    border: '0px',
    width: '42px',
    height: '36px',
    margin: '0px 2px',
    borderRadius: '3px',
    padding: '0px 12px',
  };

  const getFAClassFromType = () => {
    switch (type) {
      case 'view':
        return 'fas fa-eye';
      case 'delete':
        return 'fas fa-trash-alt';
      case 'csv':
        return 'fas fa-file-csv';
      case 'edit':
        return 'fas fa-pencil-alt';
      case 'details':
        return 'fas fa-file';
      case 'upload':
        return 'fas fa-upload';
      default:
        return 'fas fa-eye';
    }
  };

  return (
    <AccionaIcon
      style={{ ...classButtons, ...style }}
      className="btn btn-darkgray"
      onClick={onClick}
      hidden={hidden}
      classIcon={`icon ${getFAClassFromType()}`}
      toolTip={toolTip}
      disabled={disabled}
    />
  );
};

AccionaListButton.defaultProps = {
  onClick: () => {},
  hidden: false,
  disabled: false,
  toolTip: 'Default action button',
  type: 'view',
  style: {},
};

AccionaListButton.propTypes = {
  onClick: PropTypes.func,
  hidden: PropTypes.bool,
  disabled: PropTypes.bool,
  toolTip: PropTypes.string,
  type: PropTypes.oneOf(['view', 'delete', 'csv', 'edit', 'details']),
  style: PropTypes.shape({}),
};

export default AccionaListButton;
