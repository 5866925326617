import { useIntl } from 'react-intl';
import Step from '../../../../../../../../components/Step';
import { PropTypes } from 'prop-types';
import Acciona2CheckButton from '../../../../../../../../components/Acciona2CheckButton';
import PhotoList from '../../../../../../../../components/AccionaPhotoList';
import { useState } from 'react';
import AccionaPhoto from '../../../../../../../../components/AccionaPhoto';
import AccionaInput from '../../../../../../../../components/AccionaInput';
import AccionaFormItem from '../../../../../../../../components/AccionaFormItem';

const PRMLimitTime = ({ onFinish, preregister, onCancel }) => {
  const intl = useIntl();
  const [limitTime, setLimitTime] = useState(
    preregister.prm_data?.limit_time || false,
  );
  const [limitTimeImage, setLimitTimeImage] = useState(
    preregister.prm_data?.limit_time_img || [],
  );
  const [limitTimeComments, setLimitTimeComments] = useState(
    preregister.prm_data?.limit_time_comments,
  );

  const updatePhotoValues = (files) => {
    setLimitTimeImage((prev) => [...prev, ...files]);
  };

  const deleteImage = (index) => {
    setLimitTimeImage((prev) => {
      return [...prev.slice(0, index), ...prev.slice(index + 1)];
    });
  };

  return (
    <Step
      title={intl.formatMessage({ id: 'preregisterWizard.section.prm.step01' })}
      stepNumber={1}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.prm',
      })}
      content={
        <>
          <AccionaFormItem>
            <Acciona2CheckButton
              label="limit_time"
              state={{
                value: limitTime,
              }}
              onChange={(value) => setLimitTime(value)}
            />
          </AccionaFormItem>
          <PhotoList images={limitTimeImage} onDelete={deleteImage} />
          <AccionaPhoto onChange={updatePhotoValues} label="limitTimePhoto" />
          <AccionaFormItem>
            <AccionaInput
              type="text"
              label="comment"
              onChange={(value) => setLimitTimeComments(value)}
              state={{
                value: limitTimeComments,
              }}
            />
          </AccionaFormItem>
        </>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish({
          ...preregister,
          prm_data: {
            ...preregister.prm_data,
            limit_time: limitTime,
            limit_time_img: limitTimeImage,
            limit_time_comments: limitTimeComments,
          },
        });
      }}
    />
  );
};

PRMLimitTime.propTypes = {
  preregister: PropTypes.shape({
    prm_data: PropTypes.shape({
      limit_time: PropTypes.bool,
      limit_time_img: PropTypes.number,
      limit_time_comments: PropTypes.number,
    }),
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

PRMLimitTime.defaultProps = {
  preregister: {},
};

export default PRMLimitTime;
