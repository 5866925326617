import { all, fork, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import * as Api from '../../../../api/hoodedControl/monitoring';
import Monitoring from '../../../../../models/hoodedControl/Monitoring';
import { callToAPI } from '../../../../../utils/callToAPI';

function* getMonitorings({ payload }) {
  yield callToAPI({
    apiCall: Api.getMonitorings,
    successResponseCode: 200,
    errorType: types.GET_HOOD_MONITORINGS_ERROR,
    *callback({ data }) {
      yield put({
        type: types.GET_HOOD_MONITORINGS_SUCCESS,
        payload: {
          monitorings: data.data.map((monitorings) => {
            return new Monitoring(monitorings);
          }),
          total: data.total,
        },
      });
    },
    payload,
  });
}

function* createMonitoring({ payload }) {
  yield callToAPI({
    apiCall: Api.createMonitoring,
    successResponseCode: 201,
    errorType: types.CREATE_HOOD_MONITORING_ERROR,
    *callback({ data }) {
      yield put({
        type: types.CREATE_HOOD_MONITORING_SUCCESS,
        payload: new Monitoring(data),
      });
    },
    payload,
  });
}

function* updateMonitoring({ payload }) {
  yield callToAPI({
    apiCall: Api.updateMonitoring,
    successResponseCode: 200,
    errorType: types.UPDATE_HOOD_MONITORING_ERROR,
    *callback({ data }) {
      yield put({
        type: types.UPDATE_HOOD_MONITORING_SUCCESS,
        payload: new Monitoring(data),
      });
    },
    payload,
  });
}

function* finalizeMonitoring({ payload }) {
  yield callToAPI({
    apiCall: Api.finalizeMonitoring,
    successResponseCode: 200,
    errorType: types.FINALIZE_HOOD_MONITORING_ERROR,
    *callback({ data }) {
      yield put({
        type: types.FINALIZE_HOOD_MONITORING_SUCCESS,
        payload: new Monitoring(data),
      });
    },
    payload,
  });
}

function* deleteMonitorings({ payload }) {
  yield callToAPI({
    apiCall: Api.deleteMonitoring,
    successResponseCode: 200,
    errorType: types.DELETE_HOOD_MONITORINGS_ERROR,
    *callback() {
      yield put({
        type: types.DELETE_HOOD_MONITORINGS_SUCCESS,
        payload,
      });
    },
    payload,
  });
}

/* watchers */
function* watcherGetMonitorings() {
  yield takeEvery(types.GET_HOOD_MONITORINGS_REQUEST, getMonitorings);
}
function* watcherDeleteMonitorings() {
  yield takeEvery(types.DELETE_HOOD_MONITORINGS_REQUEST, deleteMonitorings);
}
function* watcherCreateMonitoring() {
  yield takeEvery(types.CREATE_HOOD_MONITORING_REQUEST, createMonitoring);
}
function* watcherUpdateMonitoring() {
  yield takeEvery(types.UPDATE_HOOD_MONITORING_REQUEST, updateMonitoring);
}
function* watcherFinalizeMonitoring() {
  yield takeEvery(types.FINALIZE_HOOD_MONITORING_REQUEST, finalizeMonitoring);
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetMonitorings),
    fork(watcherDeleteMonitorings),
    fork(watcherCreateMonitoring),
    fork(watcherUpdateMonitoring),
    fork(watcherFinalizeMonitoring),
  ]);
}
