import {
  CLEAR_CREATED_THERMAL_CONTROL_DEVICE,
  CLEAR_EDITED_THERMAL_CONTROL_DEVICE,
  CREATE_THERMAL_CONTROL_DEVICE_REQUEST,
  DELETE_THERMAL_CONTROL_DEVICES_REQUEST,
  FETCH_THERMAL_CONTROL_DEVICES_REQUEST,
  GET_THERMAL_CONTROL_DEVICES_REQUEST,
  GET_UNUSED_THERMAL_CONTROL_DEVICES_REQUEST,
  UPDATE_THERMAL_CONTROL_DEVICE_REQUEST,
} from './types';

const getDevices = (payload) => ({
  type: GET_THERMAL_CONTROL_DEVICES_REQUEST,
  payload,
});

const fetchDevices = (payload) => ({
  type: FETCH_THERMAL_CONTROL_DEVICES_REQUEST,
  payload,
});

const getUnusedDevices = (payload) => ({
  type: GET_UNUSED_THERMAL_CONTROL_DEVICES_REQUEST,
  payload,
});

const deleteDevices = (payload) => ({
  type: DELETE_THERMAL_CONTROL_DEVICES_REQUEST,
  payload,
});

const createDevice = (payload) => ({
  type: CREATE_THERMAL_CONTROL_DEVICE_REQUEST,
  payload,
});

const updateDevice = (payload) => ({
  type: UPDATE_THERMAL_CONTROL_DEVICE_REQUEST,
  payload,
});

const clearErrors = () => ({
  type: 'CLEAR_ERRORS',
});

const clearCreated = () => ({
  type: CLEAR_CREATED_THERMAL_CONTROL_DEVICE,
});

const clearEdited = () => ({
  type: CLEAR_EDITED_THERMAL_CONTROL_DEVICE,
});

export {
  deleteDevices,
  getDevices,
  getUnusedDevices,
  createDevice,
  updateDevice,
  fetchDevices,
  clearErrors,
  clearCreated,
  clearEdited,
};
