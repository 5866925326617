import * as TYPES from './types';

const initialState = {
  list: [],
  fetching: false,
  deleted: null,
  total: 0,
  errors: 0,
  uploaded: 0,
};
const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES.GET_PRE_DN_OFFLINE_REQUEST:
    case TYPES.DELETE_PRE_DN_OFFLINE_REQUEST:
    case TYPES.GET_PRE_DN_OFFLINE_ERROR:
    case TYPES.DELETE_PRE_DN_OFFLINE_ERROR:
      return {
        ...state,
        fetching: false,
      };

    case TYPES.DELETE_PRE_DN_OFFLINE_SUCCESS: {
      return {
        ...state,
        deleted: payload,
      };
    }

    case TYPES.GET_PRE_DN_OFFLINE_SUCCESS: {
      return {
        ...state,
        total: payload.total,
        list: [...state.list, ...payload.list],
        fetching: false,
      };
    }

    case 'EMPTY_OFFLINE_LIST': {
      return { ...state, list: [] };
    }

    default:
      return { ...state };
  }
};

export default reducer;
