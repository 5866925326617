import { useIntl } from 'react-intl';
import { Dot, Circle } from 'react-bootstrap-icons';

const CE = () => {
  const intl = useIntl();
  const normative = [
    'supplierID',
    'serialNumber',
    'concreteCenter',
    'petitioneName',
    'arrivingDateTime',
    'concreteAmount',
    'concreteDesignation',
    'actualDosage',
  ];
  const actualDosage = [
    'environments',
    'type',
    'relation_wc',
    'admixtures',
    'admixtures_quantity',
    'cement_id',
    'place_id',
    'truck_id',
    'limit_time',
  ];
  return (
    <div id="normativeEHE_CE">
      {normative.map((normative) => {
        return (
          <p key={normative}>
            <Dot /> {intl.formatMessage({ id: `normative.${normative}` })}
          </p>
        );
      })}
      <div className="secondList">
        {actualDosage.map((actualDosage) => {
          return (
            <p key={actualDosage}>
              <Circle
                size={3}
                style={{ position: 'absolute', top: '8px', left: '-10px' }}
              />
              <span>
                {intl.formatMessage({ id: `actualDosage.${actualDosage}` })}
              </span>
            </p>
          );
        })}
      </div>
    </div>
  );
};
const EHE = () => {
  const intl = useIntl();
  const normative = [
    'supplierID',
    'serialNumber',
    'concreteCenter',
    'petitioneName',
    'arrivingDateTime',
    'concreteAmount',
    'concreteDesignation2',
    'actualDosage',
    'cement_id',
    'place_id',
    'truck_id',
    'limit_time',
  ];
  const actualDosage = [
    'type',
    'relation_wc',
    'admixtures',
    'admixtures_quantity',
  ];
  return (
    <div id="normativeEHE_CE">
      {normative.map((normative) => {
        let response = (
          <p key={normative}>
            {' '}
            <Dot /> {intl.formatMessage({ id: `normative.${normative}` })}
          </p>
        );
        if (normative === 'actualDosage') {
          let response2 = (
            <div key="onlyDiv">
              <p key={normative}>
                {' '}
                <Dot /> {intl.formatMessage({ id: `normative.${normative}` })}
              </p>
              <div className="secondList">
                {actualDosage.map((actualDosage) => {
                  return (
                    <p key={actualDosage}>
                      <Circle
                        size={3}
                        style={{
                          position: 'absolute',
                          top: '8px',
                          left: '-10px',
                        }}
                      />
                      <span>
                        {intl.formatMessage({
                          id: `actualDosage.${actualDosage}`,
                        })}
                      </span>
                    </p>
                  );
                })}
              </div>
            </div>
          );
          return response2;
        } else {
          return response;
        }
      })}
    </div>
  );
};

export { EHE, CE };
