import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import './index.css';

const AccionaDropdown = ({ options, label, onClick, selected, id }) => {
  const [active, setActive] = useState('');
  const [block, setBlock] = useState({ display: 'none' });

  const onOpen = () => {
    if (active === 'active') {
      setActive('');
      setBlock({ display: 'none' });
    } else {
      setActive('active');
      setBlock({ display: 'block' });
    }
  };

  return (
    <div className="select" onClick={onOpen} id={id}>
      <div className={'select-styled ' + active}>{selected}</div>
      <ul
        className="select-options select-opt-timezones"
        id="ul-dropdown"
        style={block}
      >
        {options.map((currentValue, index) => (
          <li
            key={`${index}-${currentValue}`}
            id={`${label}`}
            onClick={() => onClick(currentValue)}
          >
            {currentValue}
          </li>
        ))}
      </ul>
    </div>
  );
};

AccionaDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.string,
  id: PropTypes.string,
};

AccionaDropdown.defaultProps = {
  selected: undefined,
  id: undefined,
};

export default AccionaDropdown;
