import { PRMApi, PRMCache } from './PRM';

export class PreRegisterDocument {
  constructor({
    id,
    project,
    docket_number,
    arriving_time,
    arriving_date,
    start_discharging_time,
    mash_control,
    specimens_number,
    slump,
    finish_discharging_time,
    detailed_location,
    mash_control_extra,
    elements,
    model,
    plant,
    manufacture_time,
    delivered,
    recipe,
    prm_data,
    used = false,
  }) {
    this.id = id;
    this.project = project;
    this.docket_number = docket_number;
    this.arriving_time = arriving_time;
    this.arriving_date = arriving_date;
    this.start_discharging_time = start_discharging_time;
    this.mash_control = mash_control;
    this.specimens_number = specimens_number;
    this.slump = slump;
    this.finish_discharging_time = finish_discharging_time;
    this.detailed_location = detailed_location;
    this.mash_control_extra = mash_control_extra;
    this.elements = elements;
    this.model = model;
    this.plant = plant;
    this.manufacture_time = manufacture_time;
    this.delivered = delivered;
    this.recipe = recipe;
    this.prm_data = prm_data;
    this.used = used;
  }
}

export class PreRegisterDocumentApi extends PreRegisterDocument {
  constructor({
    id,
    image_name,
    project,
    docket_number,
    arriving_time,
    arriving_date,
    start_discharging_time,
    mash_control,
    specimens_number,
    slump,
    finish_discharging_time,
    detailed_location,
    mash_control_extra,
    elements,
    model,
    plant,
    manufacture_time,
    delivered,
    recipe,
    prm_data,
    used = false,
  }) {
    super({
      id,
      project,
      docket_number,
      arriving_time,
      arriving_date,
      start_discharging_time,
      mash_control,
      specimens_number,
      slump,
      finish_discharging_time,
      detailed_location,
      mash_control_extra,
      elements,
      model,
      plant,
      manufacture_time,
      delivered,
      recipe,
      used,
      prm_data: prm_data ? new PRMApi(prm_data) : null,
    });
    this.image_name = image_name;
  }
}
export class PreRegisterDocumentCache extends PreRegisterDocument {
  constructor({
    id,
    photo_element,
    project,
    docket_number,
    arriving_time,
    arriving_date,
    start_discharging_time,
    mash_control,
    specimens_number,
    slump,
    finish_discharging_time,
    detailed_location,
    mash_control_extra,
    elements,
    model,
    plant,
    manufacture_time,
    delivered,
    recipe,
    prm_data,
    used = false,
  }) {
    super({
      id,
      project,
      docket_number,
      arriving_time,
      arriving_date,
      start_discharging_time,
      mash_control,
      specimens_number,
      slump,
      finish_discharging_time,
      detailed_location,
      mash_control_extra,
      elements,
      model,
      plant,
      manufacture_time,
      delivered,
      recipe,
      used,
      prm_data: prm_data ? new PRMCache(prm_data) : null,
    });
    this.photo_element = photo_element;
  }
  getFileName = () => {
    return `delivery-${this.docket_number}-${this.project}.png`;
  };

  toApi = () =>
    new PreRegisterDocumentApi({
      ...this,
      image_name: this.getFileName(),
      prm_data: this.prm_data?.toApi(),
    });

  static fromCache = (jsonCache) => {
    return new PreRegisterDocumentCache({
      ...jsonCache,
      id: jsonCache.idCache,
      prm_data: PRMCache.fromCache(jsonCache),
    });
  };
  toJSON = async () => {
    return {
      id: this.id,
      project: this.project,
      docket_number: this.docket_number,
      arriving_time: this.arriving_time,
      arriving_date: this.arriving_date,
      start_discharging_time: this.start_discharging_time,
      mash_control: this.mash_control,
      specimens_number: this.specimens_number,
      slump: this.slump,
      finish_discharging_time: this.finish_discharging_time,
      detailed_location: this.detailed_location,
      mash_control_extra: this.mash_control_extra,
      elements: this.elements,
      model: this.model,
      plant: this.plant,
      manufacture_time: this.manufacture_time,
      delivered: this.delivered,
      recipe: this.recipe,
      used: this.used,
      photo_element: this.photo_element,
      prm_data: await this.prm_data?.toJSON(),
    };
  };
}
