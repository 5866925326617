export const extractChannelIdentifiers = (data, type = 'temperature') => {
  const channelSet = new Set(
    data.reduce((acc, cur) => {
      if (cur[type]) {
        acc.push(cur.channel);
      }

      return acc;
    }, []),
  );
  let channelIdentifiers = Array.from(channelSet)
    .filter((channel) => channel !== 'mean')
    .map((channel) => {
      if (channel.startsWith('ch')) {
        return parseInt(channel.substring(2), 10);
      }
      return channel;
    });

  channelIdentifiers.sort((a, b) => {
    return a - b;
  });

  if (channelSet.has('mean')) {
    channelIdentifiers.push('mean');
  }
  return channelIdentifiers;
};
