import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useOutletContext } from 'react-router-dom';
import AccionaBigButton from '../../../../../components/AccionaBigButton';
import { ConnectionContext } from '../../../../../context/ConnectionContext/provider';
import usePrevious from '../../../../../hooks/usePrevious';
import InitPreregisterModal from './components/InitPreregisterModal';
import PreregistersInCacheModal from './components/PreregistersInCacheModal';
import { getAllPreDNPid } from '../../../../../indexedDB/utils';

const Home = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { project } = useOutletContext();
  const [showModal, setShowModal] = useState(false);
  const [openModal, setOpenModal] = useState(null);
  const [cachedDeliveryNotes, setCachedDeliveryNotes] = useState([]);
  const { online } = useContext(ConnectionContext);
  const onAcceptMandatoryFields = (model, pending = false) =>
    navigate(`${model}/preregister${pending ? '?pending=true' : ''}`);
  const onCancelModal = () => {
    setShowModal(false);
  };
  const prevOnline = usePrevious(online.state);

  useEffect(() => {
    if (
      cachedDeliveryNotes.length > 0 &&
      openModal === null &&
      project.id_project &&
      online.state
    ) {
      setOpenModal(true);
    }
  }, [cachedDeliveryNotes.length, openModal, project.id_project, online.state]);

  useEffect(() => {
    if (online.state === false) setOpenModal(false);
    if (online.state === true && prevOnline === false) {
      setOpenModal(null);
    }
  }, [online.state, project.id_project, prevOnline]);

  useEffect(() => {
    const getAllCachedDeliveryNotes = async () => {
      const response = await getAllPreDNPid(project.id_project);
      setCachedDeliveryNotes(response);
    };
    getAllCachedDeliveryNotes();
  }, [project.id_project]);

  return (
    <div className="wrapper fullscreen">
      <PreregistersInCacheModal
        open={Boolean(openModal)}
        onCancel={() => setOpenModal(false)}
        cachedDeliveryNotes={cachedDeliveryNotes}
      />
      <InitPreregisterModal
        visible={showModal}
        onCancel={onCancelModal}
        project={project}
        onOk={onAcceptMandatoryFields}
      />

      <div className="row">
        <div className="col-xs-12 col-sm-4 col-md-4">
          <AccionaBigButton
            icon="delivery-ocr"
            id="preRegisterDocument"
            onClick={() => {
              setShowModal(true);
            }}
            msg={formatMessage({ id: `mashPreRegister` })}
          />
        </div>
        <div className="col-xs-12 col-sm-4 col-md-4">
          <AccionaBigButton
            icon="smartphone-docs"
            id="preRegisterDocument"
            onClick={() => navigate(`preregisterList`)}
            msg={formatMessage({ id: `listPreRegister` })}
          />
        </div>
      </div>
    </div>
  );
};
export default Home;
