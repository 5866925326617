import { all } from 'redux-saga/effects';
import ProjectsSaga from './projects/saga';
import AuthSaga from './auth/saga';
import OnlineSaga from './ocr/preDNOnline/saga';
import OfflineSaga from './ocr/preDNOffline/saga';
import ThermalControlDevicesSaga from './strength/thermalControl/devices/saga';
import ThermalControlMixturesSaga from './strength/thermalControl/mixtures/saga';
import ThermalControlMonitoringsSaga from './strength/thermalControl/monitorings/saga';
import HoodedControlDevicesSaga from './strength/hoodedControl/devices/saga';
import HoodedControlMonitoringsSaga from './strength/hoodedControl/monitorings/saga';

export default function* rootSaga() {
  yield all([
    ProjectsSaga(),
    AuthSaga(),
    OnlineSaga(),
    OfflineSaga(),
    ThermalControlDevicesSaga(),
    ThermalControlMixturesSaga(),
    ThermalControlMonitoringsSaga(),
    HoodedControlDevicesSaga(),
    HoodedControlMonitoringsSaga(),
  ]);
}
