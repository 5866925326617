import { useNavigate, useParams } from 'react-router-dom';
import { COLUMNS_HEADERS } from './columns';
import { getMixtures } from '../../../../../../../services/redux/strength/thermalControl/mixtures/actions';
import AccionaList from '../../../../../../../components/AccionaList';
import { useIntl } from 'react-intl';
import BatteryStatus from '../../../../../../../components/BatteryStatus';
import AccionaListButton from '../../../../../../../components/AccionaList/AccionaListButton';
import AccionaButton from '../../../../../../../components/AccionaButton';
import { useState } from 'react';
import Modal from '../../../../../../../components/AccionaModal';
import ViewMixture from '../View';
import dayjs from 'dayjs';

const MixturesList = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [showViewModal, setShowViewModal] = useState(false);
  const [mixtureForView, setMixtureForView] = useState();
  const { id } = useParams();
  return (
    <div className="wrapper">
      <AccionaList
        pid={id}
        getItems={getMixtures}
        entity="thermalControlMixtures"
        columns={COLUMNS_HEADERS}
        title={intl.formatMessage({
          id: 'strength.thermalcontrol.mixtures.list',
        })}
        headingWidth="180px"
        dataParseFunction={(data) =>
          data.map((d) => ({
            ...d,
            device: (
              <>
                {d.device_data?.reference_number}
                {'  '}
                {d.device_data?.batt !== undefined ? (
                  <BatteryStatus battery={d.device_data?.batt} />
                ) : (
                  ''
                )}
              </>
            ),
            start_time: dayjs(d.start_time).format('DD/MM/YYYY hh:mm'),
            finished: d.finished
              ? intl.formatMessage({ id: 'yes' })
              : intl.formatMessage({ id: 'no' }),
            rawObject: d,
          }))
        }
        actions={(mixture) => (
          <>
            <AccionaListButton
              onClick={() => {
                setShowViewModal(true);
                setMixtureForView(mixture.rawObject.id);
              }}
              type="view"
              toolTip={intl.formatMessage({
                id: 'viewAction.mixtures',
              })}
            />
          </>
        )}
      />
      <div className="form-row">
        <AccionaButton
          msg={intl.formatMessage({ id: 'back' })}
          onClick={() => navigate(-1)}
          classIcon="icon fas fa-chevron-left"
          style={{ float: 'right', marginTop: '10px', marginRight: '0px' }}
        />
      </div>
      {mixtureForView && (
        <Modal
          title={intl
            .formatMessage({
              id: 'strength.thermalcontrol.mixtures.list.modal.view.title',
            })
            .toUpperCase()}
          open={showViewModal}
          onCancel={() => {
            setShowViewModal(false);
          }}
          closable
          width="90vw"
          height="80vh"
        >
          <ViewMixture
            mixtureId={mixtureForView}
            onCancel={() => {
              setShowViewModal(false);
              setMixtureForView(undefined);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default MixturesList;
