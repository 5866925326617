import { useNavigate, useParams } from 'react-router-dom';
import { COLUMNS_HEADERS } from './columns';
import { getDevices } from '../../../../../../../services/redux/strength/thermalControl/devices/actions';
import AccionaList from '../../../../../../../components/AccionaList';
import { useIntl } from 'react-intl';
import BatteryStatus from '../../../../../../../components/BatteryStatus';
import AccionaButton from '../../../../../../../components/AccionaButton';

const DevicesList = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <div className="wrapper">
      <AccionaList
        pid={id}
        getItems={getDevices}
        entity="thermalControlDevices"
        columns={COLUMNS_HEADERS}
        title={intl.formatMessage({
          id: 'strength.thermalcontrol.devices.list',
        })}
        dataParseFunction={(data) =>
          data.map((d) => ({
            ...d,
            reference_number: (
              <>
                {d.reference_number} {'  '}
                <BatteryStatus battery={d.batt} />
              </>
            ),
            project: d.project
              ? `${d.project.work_code} ${d.project.name}`
              : '--',
            location: d.location
              ? intl.formatMessage({ id: d.location })
              : '--',
            inUse: d.inUse ? (
              <>
                {intl.formatMessage({ id: 'no' })}{' '}
                <i className="iconRed fas fa-times-circle fa-lg"></i>
              </>
            ) : (
              <>
                {intl.formatMessage({ id: 'yes' })}{' '}
                <i className="iconGreen fas fa-check-circle fa-lg"></i>
              </>
            ),
            rawObject: d,
          }))
        }
      />
      <div className="form-row">
        <AccionaButton
          msg={intl.formatMessage({ id: 'back' })}
          onClick={() => navigate(-1)}
          classIcon="icon fas fa-chevron-left"
          style={{ float: 'right', marginTop: '10px', marginRight: '0px' }}
        />
      </div>
    </div>
  );
};

export default DevicesList;
