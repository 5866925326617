import { PropTypes } from 'prop-types';
import styles from './index.module.scss';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import AntdRangePicker from '../../../../../../../../../components/AntdRangePicker';
import { getMixtureDataHistoricals } from '../../../../../../../../../services/api/thermalControl/mixtures';
import dayjs from 'dayjs';
import { ConnectionContext } from '../../../../../../../../../context/ConnectionContext/provider';

const { headerWrapper, wrapper, chartWrapper } = styles;

const ChartWrapper = ({ render, mixtureId, startTime, finished, endTime }) => {
  const noDataFoundTimeOut = useRef();
  const realTimeInterval = useRef();
  const { createError } = useContext(ConnectionContext);
  const [range, setRange] = useState([
    dayjs(startTime),
    finished ? dayjs(endTime) : dayjs(),
  ]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getData = useCallback(
    async (id) => {
      try {
        const { data } = await getMixtureDataHistoricals({
          mixtureId,
          startDate: range[0].toISOString(),
          endDate: range[1].toISOString(),
        });
        setData(data);
        setLoading(false);
      } catch (e) {
        if (e.response.status === 425) {
          if (noDataFoundTimeOut.current) {
            clearTimeout(noDataFoundTimeOut.current);
            noDataFoundTimeOut.current = undefined;
          }
          noDataFoundTimeOut.current = setTimeout(() => getData(id), 10000);
        } else {
          createError(e.response);
          setLoading(false);
        }
      }
    },
    [mixtureId, range, createError],
  );

  useEffect(() => {
    if (mixtureId) {
      setLoading(true);
      getData(mixtureId);
    }
  }, [mixtureId, getData, range, createError]);

  useEffect(() => {
    if (!finished) {
      realTimeInterval.current = setInterval(() => {
        getData(mixtureId);
      }, 60000);

      return () => {
        clearInterval(realTimeInterval.current);
      };
    }
  }, [mixtureId, getData, finished]);

  return (
    <div className={wrapper}>
      <div
        className={headerWrapper}
        style={{
          justifyContent: 'flex-end',
        }}
      >
        <div>
          <AntdRangePicker defaultValue={range} onChange={setRange} />
        </div>
      </div>
      <div className={chartWrapper}>{render({ loading, data, range })}</div>
    </div>
  );
};

ChartWrapper.propTypes = {
  render: PropTypes.func.isRequired,
  finished: PropTypes.bool.isRequired,
  mixtureId: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  availableChannels: PropTypes.arrayOf(
    PropTypes.shape({
      channel: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  startTime: PropTypes.string,
  endTime: PropTypes.string,
};

ChartWrapper.defaultProps = {
  mixtureId: undefined,
  availableChannels: undefined,
  startTime: undefined,
  endTime: undefined,
};
export default ChartWrapper;
