import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

const componentStyle = {
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  minHeight: '47px',
  fontSize: '16px',
};
const activeClass =
  'ui-checkboxradio-label ui-corner-all ui-button ui-widget ui-checkboxradio-radio-label ui-checkboxradio-checked ui-state-active';
const noActiveclass =
  'ui-checkboxradio-label ui-corner-all ui-button ui-widget ui-checkboxradio-radio-label ui-state-hover';

const Acciona3CheckButton = ({ label, state, onChange, disabled }) => {
  const intl = useIntl();
  return (
    <div
      id={`input-${label}`}
      style={{
        witdth: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <label
        disabled={disabled}
        id={`input-${label}-yes`}
        style={componentStyle}
        onClick={() => onChange(true)}
        className={state.value === true ? activeClass : noActiveclass}
      >
        {intl.formatMessage({ id: 'pass' })}
      </label>
      <label
        disabled={disabled}
        style={componentStyle}
        id={`input-${label}-no`}
        onClick={() => onChange(false)}
        className={state.value === false ? activeClass : noActiveclass}
      >
        {intl.formatMessage({ id: 'notPass' })}
      </label>
      <label
        disabled={disabled}
        style={componentStyle}
        id={`input-${label}-null`}
        onClick={() => onChange(null)}
        className={state.value === null ? activeClass : noActiveclass}
      >
        N/A
      </label>
    </div>
  );
};

Acciona3CheckButton.propTypes = {
  label: PropTypes.string.isRequired,
  state: PropTypes.shape({ value: PropTypes.bool }).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Acciona3CheckButton.defaultProps = {
  disabled: false,
};

export default Acciona3CheckButton;
