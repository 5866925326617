import * as TYPES from './types';

const initialState = {
  list: [],
  unusedList: [],
  fetching: false,
  errorCreated: false,
  isCreated: false,
  isEdited: false,
  errorMessages: false,
  total: 0,
  fetchedNewDevices: null,
};
const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES.GET_THERMAL_CONTROL_DEVICES_REQUEST:
    case TYPES.DELETE_THERMAL_CONTROL_DEVICES_REQUEST:
    case TYPES.CREATE_THERMAL_CONTROL_DEVICE_REQUEST:
    case TYPES.UPDATE_THERMAL_CONTROL_DEVICE_REQUEST:
    case TYPES.GET_UNUSED_THERMAL_CONTROL_DEVICES_REQUEST:
    case TYPES.FETCH_THERMAL_CONTROL_DEVICES_REQUEST: {
      return {
        ...state,
        fetching: true,
        isCreated: false,
        errorCreated: false,
        fetchedNewDevices: null,
      };
    }
    case TYPES.GET_THERMAL_CONTROL_DEVICES_ERROR:
    case TYPES.DELETE_THERMAL_CONTROL_DEVICES_ERROR:
    case TYPES.UPDATE_THERMAL_CONTROL_DEVICE_ERROR:
    case TYPES.GET_UNUSED_THERMAL_CONTROL_DEVICES_ERROR:
    case TYPES.FETCH_THERMAL_CONTROL_DEVICES_ERROR:
      return {
        ...state,
        errorMessages: payload,
        fetching: false,
        fetchedNewDevices: null,
      };

    case TYPES.CREATE_THERMAL_CONTROL_DEVICE_ERROR: {
      return {
        ...state,
        errorCreated: payload,
        fetching: false,
      };
    }

    case TYPES.DELETE_THERMAL_CONTROL_DEVICES_SUCCESS: {
      const list = state.list;
      const index = list.findIndex((item) => item.id === payload.id);
      if (index !== -1) list.splice(index, 1);
      else list.push(index);
      return {
        ...state,
        list,
        fetching: false,
        total: state.total - 1,
      };
    }

    case TYPES.CREATE_THERMAL_CONTROL_DEVICE_SUCCESS: {
      return {
        ...state,
        list: [...state.list, payload],
        fetching: false,
        total: state.total + 1,
        isCreated: true,
      };
    }

    case TYPES.UPDATE_THERMAL_CONTROL_DEVICE_SUCCESS: {
      const list = state.list;
      const index = list.findIndex((item) => item.id === payload.id);
      if (index !== -1) list.splice(index, 1, payload);
      else list.push(payload);
      return {
        ...state,
        list,
        fetching: false,
        isEdited: true,
      };
    }

    case TYPES.GET_THERMAL_CONTROL_DEVICES_SUCCESS: {
      return {
        ...state,
        total: payload.total,
        list: [...state.list, ...payload.devices],
        fetching: false,
      };
    }

    case TYPES.FETCH_THERMAL_CONTROL_DEVICES_SUCCESS: {
      return {
        ...state,
        total: payload.total,
        list: [...state.list, ...payload.devices],
        fetching: false,
        fetchedNewDevices: payload.total - state.total,
      };
    }

    case TYPES.GET_UNUSED_THERMAL_CONTROL_DEVICES_SUCCESS: {
      return {
        ...state,
        total: payload.total,
        unusedList: [...payload.devices],
        fetching: false,
      };
    }

    case 'CLEAR_ERRORS': {
      return {
        ...state,
        errorCreated: false,
        errorMessages: false,
      };
    }

    case TYPES.CLEAR_CREATED_THERMAL_CONTROL_DEVICE: {
      return { ...state, isCreated: false };
    }

    case TYPES.CLEAR_EDITED_THERMAL_CONTROL_DEVICE: {
      return { ...state, isEdited: false };
    }

    case 'EMPTY_THERMALCONTROLDEVICES_LIST': {
      return { ...state, list: [] };
    }

    default:
      return { ...state };
  }
};

export default reducer;
