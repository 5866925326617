import { useIntl } from 'react-intl';
import Step from '../../../../../../../../components/Step';
import { PropTypes } from 'prop-types';
import PhotoList from '../../../../../../../../components/AccionaPhotoList';
import { useState } from 'react';
import AccionaPhoto from '../../../../../../../../components/AccionaPhoto';
import AccionaInput from '../../../../../../../../components/AccionaInput';
import style from './index.module.scss';
import { useOutletContext } from 'react-router-dom';
import { docility } from '../../../../../../../../utils/utils';
import AccionaFormItem from '../../../../../../../../components/AccionaFormItem';

const { info } = style;

const PRMSpecimens = ({ onFinish, preregister, onCancel }) => {
  const intl = useIntl();
  const { project } = useOutletContext();
  const { prm_data, slump, specimens_number } = preregister;
  const [specimensImage, setSpecimensImage] = useState(
    prm_data?.specimens_img || [],
  );
  const [specimensComments, setSpecimensComments] = useState(
    prm_data?.specimens_comments,
  );

  const updatePhotoValues = (files) => {
    setSpecimensImage((prev) => [...prev, ...files]);
  };

  const deleteImage = (index) => {
    setSpecimensImage((prev) => {
      return [...prev.slice(0, index), ...prev.slice(index + 1)];
    });
  };

  return (
    <Step
      title={intl.formatMessage({ id: 'preregisterWizard.section.prm.step03' })}
      stepNumber={2}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.prm',
      })}
      content={
        <>
          <div className={info}>
            <p>
              <strong>{`${intl.formatMessage({
                id: 'specimensNumber',
              })}`}</strong>
              {`: ${specimens_number}`}
            </p>
            <p>
              <strong>{`${intl.formatMessage({
                id: 'slumpResult',
              })}`}</strong>
              {`: ${intl.formatMessage({
                id: docility(slump, project.normative_name),
              })} [${slump}mm]`}
            </p>
          </div>
          <PhotoList images={specimensImage} onDelete={deleteImage} />
          <AccionaPhoto onChange={updatePhotoValues} label="specimensPhoto" />
          <AccionaFormItem>
            <AccionaInput
              type="text"
              label="comment"
              onChange={(value) => setSpecimensComments(value)}
              state={{
                value: specimensComments,
              }}
            />
          </AccionaFormItem>
        </>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish({
          ...preregister,
          prm_data: {
            ...preregister.prm_data,
            specimens_img: specimensImage,
            specimens_comments: specimensComments,
          },
        });
      }}
    />
  );
};

PRMSpecimens.propTypes = {
  preregister: PropTypes.shape({
    slump: PropTypes.number,
    specimens_number: PropTypes.number,
    prm_data: PropTypes.shape({
      specimens_img: PropTypes.number,
      specimens_comments: PropTypes.number,
    }),
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

PRMSpecimens.defaultProps = {
  preregister: {},
};

export default PRMSpecimens;
