import styles from './index.module.scss';
import { useIntl } from 'react-intl';
import AccionaOnOffButton from '../../../../../../../../../components/AccionaOnOffButton';
import { Form } from 'antd';
import AntdInput from '../../../../../../../../../components/AntdInput';
import AntdDatePicker from '../../../../../../../../../components/AntdDatePicker';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import AntdFormItem from '../../../../../../../../../components/AntdFormItem';

const { sensor } = styles;

const SensorsFormField = ({ value, onChange }) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  useEffect(() => {
    if (value) {
      const parsedValue = {};

      Object.keys(value).forEach((key) => {
        if (key.includes('ch') && value[key])
          parsedValue[key] = {
            ...value[key],
            description:
              key === 'ch5'
                ? intl.formatMessage({ id: 'environment' })
                : value[key].description,
            ts: value[key].ts ? dayjs(value[key].ts) : undefined,
          };
      });
      form.setFieldsValue(parsedValue);
    }
  }, [form, intl, value]);

  return [...Array(5)].map((_, index) => (
    <div className={sensor} key={`Ch${index + 1}`}>
      <div>
        {intl.formatMessage(
          { id: 'strength.thermalcontrol.monitorings.create.channel' },
          { number: index + 1 },
        )}
      </div>
      <Form
        form={form}
        layout="vertical"
        style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
        validateTrigger="onChange"
      >
        <AntdFormItem
          name={[`ch${index + 1}`, 'description']}
          label={intl.formatMessage({ id: 'description' })}
          style={{ marginRight: '10px', flex: 1 }}
        >
          <AntdInput
            placeholder={intl.formatMessage({ id: 'description.placeholder' })}
            disabled={!value[`ch${index + 1}`]?.enabled || index === 4}
            size="large"
            onChange={({ target: { value: description } }) => {
              onChange((prev) => ({
                ...prev,
                [`ch${index + 1}`]: {
                  ...prev[`ch${index + 1}`],
                  description: description,
                },
              }));
            }}
          />
        </AntdFormItem>
        <AntdFormItem
          name={[`ch${index + 1}`, 'ts']}
          label={intl.formatMessage({ id: 'dates' })}
          style={{ marginRight: '10px', flex: 1 }}
          rules={[
            {
              required: value[`ch${index + 1}`]?.enabled,
              message: intl.formatMessage({ id: 'required' }),
            },
          ]}
        >
          <AntdDatePicker
            size="large"
            onChange={(date, ts) => {
              form.validateFields();
              if (date) {
                onChange((prev) => {
                  return {
                    ...prev,
                    [`ch${index + 1}`]: {
                      ...prev[`ch${index + 1}`],
                      ts: date.utc().toISOString(),
                    },
                  };
                });
              }
            }}
            disabled={!value[`ch${index + 1}`]?.enabled}
          />
        </AntdFormItem>
      </Form>
      <AccionaOnOffButton
        value={value[`ch${index + 1}`]?.enabled}
        label="enabled"
        onChange={(enabled) => {
          form.validateFields();
          if (enabled) {
            onChange((prev) => ({
              ...prev,
              [`ch${index + 1}`]: {
                ...prev[`ch${index + 1}`],
                enabled,
              },
            }));
          } else {
            onChange((prev) => ({
              ...prev,
              [`ch${index + 1}`]: {
                ts: null,
                description: null,
                enabled,
              },
            }));

            form.resetFields([
              [`ch${index + 1}`, 'ts'],
              [`ch${index + 1}`, 'description'],
            ]);
          }
        }}
      />
    </div>
  ));
};

export default SensorsFormField;
