import { ChartsColors } from '../../../../../../../../../constants/charts';

const chartConfig = {
  xField: 'timestamp',
  yField: 'maturity',
  seriesField: 'channel',
  theme: {
    styleSheet: {
      fontFamily: 'Acciona Font',
    },
  },
  color: ({ channel }) => {
    return ChartsColors[channel[2] - 1];
  },
};

export default chartConfig;
