import { PropTypes } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import AccionaButton from '../AccionaButton';
import AccionaModal from '../AccionaModal';

const RemoveModal = ({ onOk, onCancel, hidden, msg }) => {
  const intl = useIntl();
  return (
    <>
      <AccionaModal
        width="90vw"
        open={!hidden}
        onCancel={onCancel}
        id="RemoveModal"
      >
        <>
          <div className="modal-body" style={{ textAlign: 'center' }}>
            <h3 style={{ marginTop: '20px' }}>{msg}</h3>
          </div>
          <div
            className="modal-footer form-row"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <AccionaButton
              onClick={onOk}
              msg={intl.formatMessage({ id: 'ok' })}
            />
            <AccionaButton
              onClick={onCancel}
              msg={intl.formatMessage({ id: 'cancel' })}
              type="default"
            />
          </div>
        </>
      </AccionaModal>
    </>
  );
};

RemoveModal.propTypes = {
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  msg: PropTypes.string.isRequired,
  hidden: PropTypes.bool.isRequired,
};

RemoveModal.defaultProps = {
  onOk: () => {},
  onCancel: () => {},
  msg: 'delete',
  hidden: false,
};

export default RemoveModal;
