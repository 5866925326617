import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import AccionaBigButton from '../../components/AccionaBigButton';

const Project = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  return (
    <div className="wrapper fullscreen">
      <div className="row">
        <div className="col-xs-12 col-sm-4 col-md-4">
          <AccionaBigButton
            icon="ocr-module"
            id="ocrModule"
            onClick={() => navigate(`ocr`)}
            msg={formatMessage({ id: `ocrModule` })}
          />
        </div>
        <div className="col-xs-12 col-sm-4 col-md-4">
          <AccionaBigButton
            icon="strength-module"
            id="strengthModule"
            onClick={() => navigate(`strength`)}
            msg={formatMessage({ id: `strengthModule` })}
          />
        </div>
      </div>
    </div>
  );
};
export default Project;
