// import { cloneDeep } from 'lodash';
import * as TYPES from './types';

const initialState = {
  list: [],
  fetching: false,
  errorMessages: null,
  errorMessagesProfile: null,
  isCreated: false,
  createdProject: {},
  total: 0,
};
const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES.GET_PROJECT_REQUEST:
    case TYPES.GET_PROJECTS_REQUEST:
      return { ...state, fetching: true, errorFetching: false };

    case TYPES.GET_PROJECT_ERROR:
    case TYPES.GET_PROJECTS_ERROR:
      return {
        ...state,
        errorMessages: payload,
        fetching: false,
      };

    case TYPES.GET_PROJECT_SUCCESS:
    case TYPES.GET_PROJECTS_SUCCESS:
      return {
        ...state,
        total: payload.total,
        list: [...state.list, ...payload.projects],
        fetching: false,
      };

    case TYPES.CLEAR_PROJECTS_STATE: {
      return {
        ...state,
        fetching: false,
        errorMessages: null,
        errorMessagesProfile: null,
        isCreated: false,
        createdProject: {},
      };
    }

    case TYPES.CREATE_ERRORS:
      return {
        ...state,
        errorMessages: payload,
        fetching: false,
      };

    case TYPES.CLEAR_ERRORS: {
      return { ...state, errorMessages: null };
    }

    case TYPES.INIT_REDUCER:
      return {
        ...initialState,
        list: state.list,
        errorMessages: null,
        errorMessagesProfile: null,
      };

    default:
      return { ...state };
  }
};

export default reducer;
