import { Modal } from 'antd';
import { PropTypes } from 'prop-types';

const AccionaSimpleModal = ({ show, onCancel, title, content }) => {
  return (
    <>
      <Modal
        title={title}
        open={show}
        onCancel={onCancel}
        centered
        cancelText="NO"
        okText="SI"
        okType="secondary"
        footer={false}
      >
        {content}
      </Modal>
    </>
  );
};

AccionaSimpleModal.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
  content: PropTypes.element.isRequired,
};

AccionaSimpleModal.defaultProps = {
  show: false,
  title: undefined,
};

export default AccionaSimpleModal;
