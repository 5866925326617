import React from 'react';
import { PropTypes } from 'prop-types';

const AccionaIcon = ({
  classIcon,
  onClick,
  className,
  hidden,
  style,
  styleIcon,
  disabled,
}) => (
  <button
    disabled={disabled}
    style={style}
    hidden={hidden}
    className={className}
    onClick={onClick}
    type="button"
    id="AccionaIcon"
  >
    <i className={classIcon} style={styleIcon} />
  </button>
);

AccionaIcon.propTypes = {
  classIcon: PropTypes.string,
  hidden: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  styleIcon: PropTypes.object,
  disabled: PropTypes.bool,
};

AccionaIcon.defaultProps = {
  classIcon: undefined,
  hidden: false,
  className: 'btn',
  style: undefined,
  styleIcon: undefined,
  disabled: false,
};

export default AccionaIcon;
