import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import styles from './index.module.scss';
import { CloseCircleFilled } from '@ant-design/icons';

const {
  select,
  selectStyled,
  selectOptions,
  isDisabled,
  isLoading,
  sm,
  placehold,
} = styles;

const AccionaSelect = ({
  options,
  onChange,
  value,
  id,
  disabled,
  loading,
  defaultValue,
  size,
  placeholder,
  clearable,
  onClear,
}) => {
  const [active, setActive] = useState('');
  const [block, setBlock] = useState({ display: 'none' });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !(event.target.closest('.' + select) || event.target.closest('#' + id))
      ) {
        setActive('');
        setBlock({ display: 'none' });
      }
    };
    if (active) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [active, id]);

  const onOpen = () => {
    if (!disabled) {
      if (active === 'active') {
        setActive('');
        setBlock({ display: 'none' });
      } else {
        setActive('active');
        setBlock({ display: 'block' });
      }
    }
  };

  return (
    <div
      className={`${select} ${disabled ? isDisabled : ''} ${
        size === 'small' ? sm : ''
      }`}
      onClick={onOpen}
      id={id}
    >
      {value ? (
        <div
          className={`${selectStyled} ${active} ${loading ? isLoading : ''}`}
        >
          {options.find((opt) => opt.value === value)?.label ||
            defaultValue?.label ||
            value}
          {clearable && <CloseCircleFilled onClick={onClear} />}
        </div>
      ) : (
        <div
          className={`${placehold} ${selectStyled} ${active} ${
            loading ? isLoading : ''
          }`}
        >
          {defaultValue?.label || placeholder}
        </div>
      )}
      <ul
        className={`${selectOptions} select-opt-timezones ${
          size === 'small' ? sm : ''
        }`}
        style={block}
      >
        {options.map((option, index) => (
          <li
            key={`${index}-${option.label}`}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

AccionaSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  clearable: PropTypes.bool,
  defaultValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  size: PropTypes.oneOf(['small', 'large']),
  placeholder: PropTypes.string,
  onClear: PropTypes.func,
};

AccionaSelect.defaultProps = {
  value: undefined,
  id: undefined,
  disabled: false,
  loading: false,
  clearable: false,
  defaultValue: undefined,
  size: 'large',
  placeholder: '',
  onClear: () => {},
};

export default AccionaSelect;
