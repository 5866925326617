class Monitoring {
  constructor({
    id = '',
    project = '',
    device = undefined,
    device_data = undefined,
    mixture = undefined,
    mixture_data = undefined,
    description = '',
    finished,
    ch1,
    ch2,
    ch3,
    ch4,
    ch5,
    ch6,
    ch7,
    ch8,
    mean,
    maturity_method,
  }) {
    this.id = id;
    this.project = project;
    this.device = device;
    this.device_data = device_data;
    this.mixture = mixture;
    this.mixture_data = mixture_data;
    this.maturity_method = maturity_method;
    this.description = description;
    this.finished = finished;
    this.ch1 =
      ch1 && Object.values(ch1).every((v) => v === null) ? undefined : ch1;
    this.ch2 =
      ch2 && Object.values(ch2).every((v) => v === null) ? undefined : ch2;
    this.ch3 =
      ch3 && Object.values(ch3).every((v) => v === null) ? undefined : ch3;
    this.ch4 =
      ch4 && Object.values(ch4).every((v) => v === null) ? undefined : ch4;
    this.ch5 =
      ch5 && Object.values(ch5).every((v) => v === null) ? undefined : ch5;
    this.ch6 =
      ch6 && Object.values(ch6).every((v) => v === null) ? undefined : ch6;
    this.ch7 =
      ch7 && Object.values(ch7).every((v) => v === null) ? undefined : ch7;
    this.ch8 =
      ch8 && Object.values(ch8).every((v) => v === null) ? undefined : ch8;
    this.mean =
      mean && Object.values(mean).every((v) => v === null) ? undefined : mean;
  }
}

export default Monitoring;
