import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import AccionaButton from '../../../../../../../components/AccionaButton';
import Modal from '../../../../../../../components/AccionaModal';
import { useState } from 'react';
import AccionaUploadModal from '../../../../../../../components/AccionaUploadModal';
import styles from './index.module.scss';

const { buttons, text } = styles;

const PreregistersInCacheModal = ({ open, onCancel, cachedDeliveryNotes }) => {
  const intl = useIntl();
  const [uploadDocuments, setUploadDocuments] = useState(false);
  return uploadDocuments ? (
    <AccionaUploadModal
      onCancel={() => {
        setUploadDocuments(false);
        onCancel();
      }}
      show={Boolean(uploadDocuments)}
      cachedDeliveryNotes={cachedDeliveryNotes}
    />
  ) : (
    <Modal open={open} onCancel={onCancel} width="80vw">
      <>
        <div className={`form-row ${text}`} style={{ margin: '10px' }}>
          <p>
            {`${intl.formatMessage(
              { id: 'upload.pending' },
              { total: cachedDeliveryNotes.length },
            )}`}
          </p>
        </div>
        <div className={`form-row ${buttons}`}>
          <AccionaButton
            msg={intl.formatMessage({ id: 'upload' })}
            onClick={() => setUploadDocuments(true)}
          />
          <AccionaButton
            msg={intl.formatMessage({ id: 'cancel' })}
            onClick={onCancel}
          />
        </div>
      </>
    </Modal>
  );
};

PreregistersInCacheModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  cachedDeliveryNotes: PropTypes.arrayOf(PropTypes.object),
};

PreregistersInCacheModal.defaultProps = {
  cachedDeliveryNotes: [],
};

export default PreregistersInCacheModal;
