import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import AccionaButton from '../AccionaButton';
import styles from './index.module.scss';

const { photoTaken, handlers, handler, removeButton } = styles;

const PhotoList = ({ images, onDelete }) => {
  const [currentIMG, setCurrentIMG] = useState(images?.length);

  const updateSelected = (number) => setCurrentIMG((prev) => prev + number);

  useEffect(() => {
    if (images.length > 0) setCurrentIMG(images.length - 1);
  }, [images.length]);

  if (images.length > 0 && images[currentIMG])
    return (
      <div className={photoTaken}>
        <AccionaButton
          classIcon="icon fas fa-trash"
          className={removeButton}
          onClick={() => {
            onDelete(currentIMG);
          }}
        />
        <div className={handlers}>
          <AccionaButton
            classIcon="icon fas fa-chevron-left"
            className={handler}
            disabled={currentIMG === 0}
            onClick={() => updateSelected(-1)}
          />
          <AccionaButton
            classIcon="icon fas fa-chevron-right"
            className={handler}
            disabled={currentIMG === images.length - 1}
            onClick={() => updateSelected(+1)}
          />
        </div>
        <div className="carousel-wrapper">
          <div style={{ textAlign: 'center' }}>
            <img
              id="imgPreregister"
              src={URL.createObjectURL(images[currentIMG])}
              alt="photoDelivery"
            />
          </div>
        </div>
        <div></div>
      </div>
    );
  else return null;
};

PhotoList.propTypes = {
  images: PropTypes.arrayOf(PropTypes.instanceOf(File)).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default PhotoList;
