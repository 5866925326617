import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ProjectsList from './ProjectsList';

const Main = () => {
  const navigate = useNavigate();
  const projectsList = useSelector(({ projects }) => projects.list);

  return (
    <ProjectsList
      allProjects={projectsList}
      onClick={(project) => {
        navigate(`/app/project/${project.id_project}`);
      }}
    />
  );
};

export default Main;
