import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { PreRegisterDocumentApi } from '../../../../models/PreRegisterDocument';
import { callToAPI } from '../../../../utils/callToAPI';
import * as Api from '../../../api/preDeliveryNote';
import * as types from './types';

function* getPreDNOnline({ payload }) {
  yield callToAPI({
    apiCall: Api.listPreDeliveryNote,
    successResponseCode: 200,
    errorType: types.GET_PRE_DN_ONLINE_ERROR,
    *callback({ data }) {
      yield put({
        type: types.GET_PRE_DN_ONLINE_SUCCESS,
        payload: {
          list: data.data.map((predn) => new PreRegisterDocumentApi(predn)),
          total: data.total,
        },
      });
    },
    payload,
  });
}

function* deletePreDNOnline({ payload }) {
  yield callToAPI({
    apiCall: Api.deletePreDeliveryNote,
    successResponseCode: 200,
    errorType: types.DELETE_PRE_DN_ONLINE_ERROR,
    *callback() {
      yield put({
        type: types.DELETE_PRE_DN_ONLINE_SUCCESS,
        payload,
      });
    },
    payload,
  });
}

function* watcherGetPreRegisterDocuments() {
  yield takeEvery(types.GET_PRE_DN_ONLINE_REQUEST, getPreDNOnline);
}
function* watcherDeletePreRegisterDocuments() {
  yield takeEvery(types.DELETE_PRE_DN_ONLINE_REQUEST, deletePreDNOnline);
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetPreRegisterDocuments),
    fork(watcherDeletePreRegisterDocuments),
  ]);
}
