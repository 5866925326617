import * as types from './types';
import * as Api from '../../api/projects';
import { callToAPI } from '../../../utils/callToAPI';
import Project from '../../../models/Project';
import { all, fork, put, takeEvery } from 'redux-saga/effects';

function* getProject({ payload }) {
  yield callToAPI({
    apiCall: Api.getProject,
    successResponseCode: 200,
    errorType: types.GET_PROJECT_ERROR,
    *callback(response) {
      yield put({
        type: types.GET_PROJECT_SUCCESS,
        payload: new Project(response.data),
      });
    },
    payload,
  });
}

function* getProjects({ payload }) {
  if (navigator.onLine) {
    yield callToAPI({
      apiCall: Api.getProjects,
      successResponseCode: 200,
      errorType: types.GET_PROJECTS_ERROR,
      *callback(response) {
        let listProjects = [];
        yield put({
          type: types.GET_PROJECTS_SUCCESS,
          payload: {
            projects: response.data.data.map((project) => {
              listProjects.push(project);
              return new Project(project);
            }),
            total: response.data.total,
          },
        });
        localStorage.setItem('projectsList', JSON.stringify(listProjects));
      },
      payload,
    });
  } else {
    let itemsFound = JSON.parse(localStorage.getItem('projectsList'));
    if (itemsFound !== null || itemsFound !== undefined) {
      yield put({
        type: types.GET_PROJECTS_SUCCESS,
        payload: {
          projects: itemsFound,
          total: itemsFound.length,
        },
      });
    } else {
      yield put({
        type: 'CREATE_ERRORS',
        payload: {
          statusText: 'No items found in cache',
          status: 404,
          errorType: types.GET_PROJECTS_ERROR,
        },
      });
    }
  }
}

/* watchers */
function* watcherGetProject() {
  yield takeEvery(types.GET_PROJECT_REQUEST, getProject);
}

function* watcherGetProjects() {
  yield takeEvery(types.GET_PROJECTS_REQUEST, getProjects);
}

export default function* rootSaga() {
  yield all([fork(watcherGetProject), fork(watcherGetProjects)]);
}
