import { Form } from 'antd';
import { PropTypes } from 'prop-types';
import styles from './index.module.scss';
const { formitem } = styles;

const AntdFormItem = ({ children, style, ...props }) => {
  return (
    <Form.Item
      className={formitem}
      colon={false}
      style={{ ...style, marginBottom: '10px' }}
      {...props}
    >
      {children}
    </Form.Item>
  );
};

AntdFormItem.propTypes = {
  children: PropTypes.element.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

AntdFormItem.defaultProps = {
  style: {},
};
export default AntdFormItem;
