class Mixture {
  constructor({
    id,
    name,
    details,
    maturity_method,
    start_time,
    parameters,
    device,
    finished,
    device_data,
    project,
    equations,
    project_data,
    graph_data,
  }) {
    this.id = id;
    this.name = name;
    this.details = details;
    this.maturity_method = maturity_method;
    this.start_time = start_time;
    this.parameters = parameters;
    this.device = device;
    this.finished = finished;
    this.device_data = device_data;
    this.project = project;
    this.equations = equations;
    this.project_data = project_data;
    this.graph_data = graph_data;
  }
}

export default Mixture;
