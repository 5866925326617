import React, { useRef } from 'react';
import { PropTypes } from 'prop-types';
import { injectIntl, useIntl } from 'react-intl';
import { photoExtensions } from '../../constants/general';
import spinner from '../../style/img/ajax-loader.svg';
import styles from './index.module.scss';
const { contentPhotoButton, photoButton, iconPhoto, titlePhoto } = styles;

const AccionaPhoto = ({ onChange, label, loading }) => {
  const intl = useIntl();
  const numberRandom = Math.floor(Math.random() * 10000) + 1;
  const inputFileRef = useRef(null);
  const onFilechange = ({ target }) => {
    if (target.files?.length) {
      if (validationPhotoExtension(target.files[0].type)) {
        onChange([...target?.files]);
      } else {
        alert('Formato de fichero no soportado');
      }
    }
  };
  const validationPhotoExtension = (photo) => {
    return photoExtensions.includes(photo.split('/')[1]);
  };

  return (
    <>
      <div className="row row-project" style={{ margin: '20px 0px' }}>
        <div
          className="col-xs-12 col-sm-12 col-md-12"
          style={{ margin: '20px 0' }}
        >
          {' '}
          <div>
            <input
              id={`imgInput${numberRandom}`}
              type="file"
              capture
              accept="image/jpg, image/jpeg, image/png"
              style={{ display: 'none' }}
              ref={inputFileRef}
              onChangeCapture={onFilechange}
            />
          </div>
          <div
            className={`col-xs-12 col-sm-12 col-md-12 ${contentPhotoButton}`}
          >
            <button
              className={photoButton}
              onClick={() => {
                inputFileRef.current.click();
              }}
            >
              {loading ? (
                <img src={spinner} height="64" width="64" alt="Loading"></img>
              ) : (
                <i className={`icon fas fa-camera ${iconPhoto}`} />
              )}
              {label && (
                <p className={titlePhoto}>
                  {intl.formatMessage({ id: label })}
                </p>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

AccionaPhoto.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  loading: PropTypes.bool,
};

AccionaPhoto.defaultProps = {
  label: undefined,
  loading: false,
};

export default injectIntl(AccionaPhoto);
