import { useState } from 'react';
import Step from '../../../../../../../../components/Step';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import AntdFormItem from '../../../../../../../../components/AntdFormItem';
import AntdTimePicker from '../../../../../../../../components/AntdTimePicker';
import dayjs from 'dayjs';

const StartDischargingTime = ({
  onFinish,
  preregister,
  onCancel,
  stepNumber,
}) => {
  const intl = useIntl();
  const [startDischargingTime, setStartDischargingTime] = useState(
    preregister.start_discharging_time
      ? dayjs(preregister.start_discharging_time, 'HH:mm')
      : dayjs(),
  );

  return (
    <Step
      stepNumber={stepNumber}
      title={intl.formatMessage({
        id: 'preregisterWizard.section.preregister.step03',
      })}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.preregister',
      })}
      content={
        <>
          <AntdFormItem
            layout="vertical"
            label={intl.formatMessage({ id: 'start_discharging_time' })}
          >
            <AntdTimePicker
              size="large"
              onChange={(inputValue) => setStartDischargingTime(inputValue)}
              value={startDischargingTime}
              disabledTime={(timeString) => {
                const customTime = dayjs(preregister.arriving_time, 'HH:mm');
                const customHour = customTime.hour();
                const customMinute = customTime.minute();

                return {
                  disabledHours: () => {
                    const hours = [];
                    for (let i = 0; i < customHour; i++) {
                      hours.push(i);
                    }
                    return hours;
                  },
                  disabledMinutes: (selectedHour) => {
                    if (selectedHour === customHour) {
                      const minutes = [];
                      for (let i = 0; i < customMinute; i++) {
                        minutes.push(i);
                      }
                      return minutes;
                    }
                    return [];
                  },
                };
              }}
              allowClear
            />
          </AntdFormItem>
        </>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish({
          ...preregister,
          start_discharging_time: startDischargingTime.format('HH:mm'),
        });
      }}
      disableNextButton={!startDischargingTime}
    />
  );
};

StartDischargingTime.propTypes = {
  preregister: PropTypes.shape({
    start_discharging_time: PropTypes.string,
    arriving_time: PropTypes.string,
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
};

StartDischargingTime.defaultProps = {
  preregister: {},
};

export default StartDischargingTime;
