import { all, fork, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import * as Api from '../../../../api/thermalControl/mixtures';
import { callToAPI } from '../../../../../utils/callToAPI';
import Mixture from '../../../../../models/thermalControl/Mixture';

function* getMixtures({ payload }) {
  yield callToAPI({
    apiCall: Api.getMixtures,
    successResponseCode: 200,
    errorType: types.GET_THERMAL_CONTROL_MIXTURES_ERROR,
    *callback({ data }) {
      yield put({
        type: types.GET_THERMAL_CONTROL_MIXTURES_SUCCESS,
        payload: {
          mixtures: data.data.map((mixtures) => {
            return new Mixture(mixtures);
          }),
          total: data.total,
        },
      });
    },
    payload,
  });
}

function* getMixture({ payload }) {
  yield callToAPI({
    apiCall: Api.getMixture,
    successResponseCode: 200,
    errorType: types.GET_THERMAL_CONTROL_MIXTURE_ERROR,
    *callback({ data }) {
      yield put({
        type: types.GET_THERMAL_CONTROL_MIXTURE_SUCCESS,
        payload: new Mixture(data),
      });
    },
    payload,
  });
}

function* createMixture({ payload }) {
  yield callToAPI({
    apiCall: Api.createMixture,
    successResponseCode: 201,
    errorType: types.CREATE_THERMAL_CONTROL_MIXTURE_ERROR,
    *callback({ data }) {
      yield put({
        type: types.CREATE_THERMAL_CONTROL_MIXTURE_SUCCESS,
        payload: new Mixture(data),
      });
    },
    payload,
  });
}

function* finalizeMixture({ payload }) {
  yield callToAPI({
    apiCall: Api.finalizeMixture,
    successResponseCode: 200,
    errorType: types.FINALIZE_THERMAL_CONTROL_MIXTURE_ERROR,
    *callback({ data }) {
      yield put({
        type: types.FINALIZE_THERMAL_CONTROL_MIXTURE_SUCCESS,
        payload: new Mixture(data),
      });
    },
    payload,
  });
}

function* updateMixture({ payload }) {
  yield callToAPI({
    apiCall: Api.updateMixture,
    successResponseCode: 200,
    errorType: types.UPDATE_THERMAL_CONTROL_MIXTURE_ERROR,
    *callback({ data }) {
      yield put({
        type: types.UPDATE_THERMAL_CONTROL_MIXTURE_SUCCESS,
        payload: new Mixture(data),
      });
    },
    payload,
  });
}

function* deleteMixtures({ payload }) {
  yield callToAPI({
    apiCall: Api.deleteMixture,
    successResponseCode: 200,
    errorType: types.DELETE_THERMAL_CONTROL_MIXTURES_ERROR,
    *callback() {
      yield all(
        payload.map((item) => {
          return put({
            type: types.DELETE_THERMAL_CONTROL_MIXTURES_SUCCESS,
            payload: item,
          });
        }),
      );
    },
    payload,
  });
}

/* watchers */
function* watcherGetMixtures() {
  yield takeEvery(types.GET_THERMAL_CONTROL_MIXTURES_REQUEST, getMixtures);
}
function* watcherGetMixture() {
  yield takeEvery(types.GET_THERMAL_CONTROL_MIXTURE_REQUEST, getMixture);
}
function* watcherDeleteMixtures() {
  yield takeEvery(
    types.DELETE_THERMAL_CONTROL_MIXTURES_REQUEST,
    deleteMixtures,
  );
}
function* watcherCreateMixture() {
  yield takeEvery(types.CREATE_THERMAL_CONTROL_MIXTURE_REQUEST, createMixture);
}
function* watcherFinalizeMixture() {
  yield takeEvery(
    types.FINALIZE_THERMAL_CONTROL_MIXTURE_REQUEST,
    finalizeMixture,
  );
}
function* watcherUpdateMixture() {
  yield takeEvery(types.UPDATE_THERMAL_CONTROL_MIXTURE_REQUEST, updateMixture);
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetMixtures),
    fork(watcherGetMixture),
    fork(watcherDeleteMixtures),
    fork(watcherCreateMixture),
    fork(watcherUpdateMixture),
    fork(watcherFinalizeMixture),
  ]);
}
