import Axios from 'axios';

const instance = Axios.create();

instance.interceptors.request.use(function (config) {
  config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
  return config;
});

export default instance;
