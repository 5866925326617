import {
  GET_LOGIN_ERROR,
  GET_LOGIN_REQUEST,
  GET_LOGIN_SUCCESS,
  GET_LOGOUT_REQUEST,
  SET_LOGIN_ERROR,
} from './types';

const reducer = (
  state = {
    isLoggedIn: localStorage.getItem('token'),
    loading: false,
    token: undefined,
    loginError: false,
  },
  { type, payload },
) => {
  switch (type) {
    case GET_LOGIN_REQUEST:
      return { ...state, loading: true };

    case GET_LOGOUT_REQUEST:
      localStorage.clear();
      return { ...state, loading: false, isLoggedIn: false, loginError: false };

    case GET_LOGIN_SUCCESS:
      return { ...state, isLoggedIn: true, token: payload, loginError: false };

    case GET_LOGIN_ERROR:
      return {
        ...state,
        isLoggedIn: false,
        token: undefined,
        loginError: true,
      };
    case SET_LOGIN_ERROR:
      return { ...state, loginError: false };

    default:
      return state;
  }
};

export default reducer;
