import { PropTypes } from 'prop-types';
import style from './index.module.scss';

const { onOffButton, onState } = style;

const AccionaOnOffButton = ({ value, onChange }) => {
  return (
    <div
      className={`${onOffButton} ${value ? onState : ''}`}
      onClick={() => onChange(!value)}
    >
      {value ? 'ON' : 'OFF'}
    </div>
  );
};

AccionaOnOffButton.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

AccionaOnOffButton.defaultProps = {
  value: false,
  onChange: () => {},
};

export default AccionaOnOffButton;
