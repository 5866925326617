class Project {
  constructor({
    id = '',
    id_project = 0,
    name = '',
    description = '',
    country_code = '',
    work_code = '',
    requester = '',
    normative_name = '',
  }) {
    this.id = id;
    this.id_project = id_project;
    this.name = name;
    this.description = description;
    this.country_code = country_code;
    this.work_code = work_code;
    this.requester = requester;
    this.normative_name = normative_name;
  }
}

export default Project;
