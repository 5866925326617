import React, { useState, useRef, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import AccionaButton from '../AccionaButton';

const SignaturePad = ({ onChange, signature }) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    if (signature) {
      const img = new Image();
      img.src = signature;

      img.onload = () => {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      };
    }
  }, [signature]);

  useEffect(() => {
    const preventScroll = (e) => {
      if (isDrawing) {
        e.preventDefault();
      }
    };

    window.addEventListener('touchmove', preventScroll, { passive: false });

    return () => {
      window.removeEventListener('touchmove', preventScroll);
    };
  }, [isDrawing]);

  const startDrawing = (e) => {
    setIsDrawing(true);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.lineWidth = 2;
    ctx.lineCap = 'round';
    ctx.strokeStyle = 'black';

    const x =
      e.nativeEvent.offsetX ||
      (e.touches[0]
        ? e.touches[0].pageX - canvas.getBoundingClientRect().left
        : 0);
    const y =
      e.nativeEvent.offsetY ||
      (e.touches[0]
        ? e.touches[0].pageY - canvas.getBoundingClientRect().top
        : 0);

    ctx.beginPath();
    ctx.moveTo(x, y);
  };

  const draw = (e) => {
    if (!isDrawing || e.nativeEvent.offsetX <= 1 || e.nativeEvent.offsetY <= 1)
      return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const x =
      e.nativeEvent.offsetX ||
      (e.touches[0]
        ? e.touches[0].pageX - canvas.getBoundingClientRect().left
        : 0);
    const y =
      e.nativeEvent.offsetY ||
      (e.touches[0]
        ? e.touches[0].pageY - canvas.getBoundingClientRect().top
        : 0);

    ctx.lineTo(x, y);
    ctx.stroke();
  };

  const endDrawing = () => {
    setIsDrawing(false);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const dataURL = canvas.toDataURL('image/png');
    onChange(dataURL);
    ctx.closePath();
  };

  const clearSignature = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    onChange(null);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <div style={{ position: 'absolute', right: '1rem', top: '1rem' }}>
        <AccionaButton
          classIcon="icon fas fa-times"
          className="img-button"
          onClick={clearSignature}
        />
      </div>
      <canvas
        style={{
          backgroundColor: 'white',
          borderRadius: '10px',
          boxShadow: '0 0 10px 0px rgba(0, 0, 0, 0.05)',
          marginTop: '5px',
        }}
        ref={canvasRef}
        width={350}
        height={300}
        className="signature-canvas"
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={endDrawing}
        onTouchStart={startDrawing}
        onTouchMove={draw}
        onTouchEnd={endDrawing}
      />
    </div>
  );
};

SignaturePad.propTypes = {
  onChange: PropTypes.func.isRequired,
  signature: PropTypes.string,
};

SignaturePad.defaultProps = {
  signature: undefined,
};

export default SignaturePad;
