import {
  DELETE_PRE_DN_OFFLINE_REQUEST,
  GET_PRE_DN_OFFLINE_REQUEST,
} from './types';

export const getPreDNOffline = (payload) => ({
  type: GET_PRE_DN_OFFLINE_REQUEST,
  payload,
});

export const deletePreDNOffline = (payload) => ({
  type: DELETE_PRE_DN_OFFLINE_REQUEST,
  payload,
});
