import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  deletePreRegisterCache,
  getAllPreRegisterProjectCache,
} from '../../../../indexedDB/utils';
import * as types from './types';

function* getPreDNOffline({ payload }) {
  const response = yield call(getAllPreRegisterProjectCache, payload);
  yield put({
    type: types.GET_PRE_DN_OFFLINE_SUCCESS,
    payload: {
      list: response.data,
      total: response.total,
    },
  });
}

function* deletePreDNOffline({ payload }) {
  yield call(deletePreRegisterCache, payload);
  yield put({
    type: types.DELETE_PRE_DN_OFFLINE_SUCCESS,
    payload,
  });
}

function* watcherGetPreRegisterDocuments() {
  yield takeEvery(types.GET_PRE_DN_OFFLINE_REQUEST, getPreDNOffline);
}

function* watcherDeletePreRegisterDocuments() {
  yield takeEvery(types.DELETE_PRE_DN_OFFLINE_REQUEST, deletePreDNOffline);
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetPreRegisterDocuments),
    fork(watcherDeletePreRegisterDocuments),
  ]);
}
