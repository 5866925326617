import { Descriptions } from 'antd';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import styles from './index.module.scss';

const { description, title } = styles;

const PRMResume = ({ preregister }) => {
  const intl = useIntl();
  const { prm_data, specimens_number } = preregister;

  const getResultLabel = (value) => {
    if (value === true) {
      return 'pass';
    } else if (value === false) {
      return 'notPass';
    } else {
      return 'null';
    }
  };

  return (
    <>
      <p className={title}>
        {intl.formatMessage({
          id: 'preregisterWizard.section.prm.resume.title',
        })}
      </p>
      <Descriptions
        className={description}
        title={intl.formatMessage({
          id: 'preregisterWizard.section.prm.step01',
        })}
        contentStyle={{ width: '50%' }}
        bordered
        column={{
          xxl: 2,
          xl: 2,
          lg: 2,
          md: 1,
          sm: 1,
          xs: 1,
        }}
        items={[
          {
            label: intl.formatMessage({ id: 'right_appearance' }),
            children: intl.formatMessage({
              id: prm_data.right_appearance ? 'yes' : 'no',
            }),
          },
          {
            label: intl.formatMessage({ id: 'photos' }),
            children: prm_data.right_appearance_img.map((file) => (
              <img src={URL.createObjectURL(file)} alt={`${file.name}`} />
            )),
          },
          {
            label: intl.formatMessage({ id: 'comment' }),
            children: prm_data.right_appearance_comments || '-',
          },
          {
            label: intl.formatMessage({ id: 'limit_time' }),
            children: intl.formatMessage({
              id: prm_data.limit_time ? 'yes' : 'no',
            }),
          },
          {
            label: intl.formatMessage({ id: 'photos' }),
            children: prm_data.limit_time_img.map((file) => (
              <img src={URL.createObjectURL(file)} alt={`${file.name}`} />
            )),
          },
          {
            label: intl.formatMessage({ id: 'comment' }),
            children: prm_data.limit_time_comments || '-',
          },
          {
            label: intl.formatMessage({ id: 'defects_results' }),
            children: intl
              .formatMessage({
                id: getResultLabel(prm_data.defects_results),
              })
              .toUpperCase(),
          },
          {
            label: intl.formatMessage({ id: 'comment' }),
            children: prm_data.defects_results_comments || '-',
          },
        ]}
      />
      <Descriptions
        className={description}
        title={intl.formatMessage({
          id: 'preregisterWizard.section.prm.step02',
        })}
        contentStyle={{ width: '50%' }}
        bordered
        column={{
          xxl: 2,
          xl: 2,
          lg: 2,
          md: 1,
          sm: 1,
          xs: 1,
        }}
        items={[
          {
            label: intl.formatMessage({ id: 'added_substance' }),
            children: intl
              .formatMessage({
                id: prm_data.added_substance ? 'yes' : 'no',
              })
              .toUpperCase(),
          },
          {
            label: intl.formatMessage({ id: 'photos' }),
            children: prm_data.added_substance_img.map((file) => (
              <img src={URL.createObjectURL(file)} alt={`${file.name}`} />
            )),
          },
          {
            label: intl.formatMessage({ id: 'comment' }),
            children: prm_data.added_substance_comments || '-',
          },
          {
            label: intl.formatMessage({ id: 'substance_results' }),
            children: intl
              .formatMessage({
                id: getResultLabel(prm_data.substance_results),
              })
              .toUpperCase(),
          },
          {
            label: intl.formatMessage({ id: 'comment' }),
            children: prm_data.substance_results_comments || '-',
          },
        ]}
      />

      {specimens_number > 0 && (
        <Descriptions
          className={description}
          title={intl.formatMessage({
            id: 'preregisterWizard.section.prm.step03',
          })}
          contentStyle={{ width: '50%' }}
          bordered
          column={{
            xxl: 2,
            xl: 2,
            lg: 2,
            md: 1,
            sm: 1,
            xs: 1,
          }}
          items={[
            {
              label: intl.formatMessage({ id: 'photos' }),
              children: prm_data.specimens_img.map((file) => (
                <img src={URL.createObjectURL(file)} alt={`${file.name}`} />
              )),
            },
            {
              label: intl.formatMessage({ id: 'comment' }),
              children: prm_data.specimens_comments,
            },
          ]}
        />
      )}
    </>
  );
};

PRMResume.propTypes = {
  preregister: PropTypes.shape({
    specimens_number: PropTypes.number,
    prm_data: PropTypes.shape({
      right_appearance: PropTypes.bool,
      right_appearance_img: PropTypes.arrayOf(PropTypes.instanceOf(File)),
      right_appearance_comments: PropTypes.bool,
      limit_time: PropTypes.bool,
      limit_time_img: PropTypes.arrayOf(PropTypes.instanceOf(File)),
      limit_time_comments: PropTypes.string,
      defects_results: PropTypes.bool,
      defects_results_comments: PropTypes.string,
      added_substance: PropTypes.bool,
      added_substance_img: PropTypes.arrayOf(PropTypes.instanceOf(File)),
      added_substance_comments: PropTypes.string,
      substance_results: PropTypes.bool,
      substance_results_comments: PropTypes.string,
      specimens_comments: PropTypes.string,
      specimens_img: PropTypes.arrayOf(PropTypes.instanceOf(File)),
    }),
  }),
};

PRMResume.defaultProps = {
  preregister: {},
};

export default PRMResume;
