import { useIntl } from 'react-intl';
import Step from '../../../../../../../../components/Step';
import { PropTypes } from 'prop-types';
import PhotoList from '../../../../../../../../components/AccionaPhotoList';
import { useState } from 'react';
import AccionaPhoto from '../../../../../../../../components/AccionaPhoto';
import AccionaInput from '../../../../../../../../components/AccionaInput';
import AccionaFormItem from '../../../../../../../../components/AccionaFormItem';
import Acciona2CheckButton from '../../../../../../../../components/Acciona2CheckButton';

const PRMAddedSubstance = ({ onFinish, preregister, onCancel }) => {
  const intl = useIntl();
  const [addedSubstance, setAddedSubstance] = useState(
    preregister.prm_data?.added_substance || false,
  );
  const [addedSubstanceImage, setAddedSubstanceImage] = useState(
    preregister.prm_data?.added_substance_img || [],
  );
  const [addedSubstanceComments, setAddedSubstanceComments] = useState(
    preregister.prm_data?.added_substance_comments || '',
  );

  const updatePhotoValues = (files) => {
    setAddedSubstanceImage((prev) => [...prev, ...files]);
  };

  const deleteImage = (index) => {
    setAddedSubstanceImage((prev) => {
      return [...prev.slice(0, index), ...prev.slice(index + 1)];
    });
  };

  return (
    <Step
      title={intl.formatMessage({ id: 'preregisterWizard.section.prm.step02' })}
      stepNumber={2}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.prm',
      })}
      content={
        <>
          <AccionaFormItem>
            <Acciona2CheckButton
              label="added_substance"
              state={{
                value: addedSubstance,
              }}
              onChange={(value) => setAddedSubstance(value)}
            />
          </AccionaFormItem>
          <PhotoList images={addedSubstanceImage} onDelete={deleteImage} />
          <AccionaPhoto onChange={updatePhotoValues} label="concretePhoto" />
          <AccionaFormItem>
            <AccionaInput
              type="text"
              label="comment"
              onChange={(value) => setAddedSubstanceComments(value)}
              state={{
                value: addedSubstanceComments,
              }}
            />
          </AccionaFormItem>
        </>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish({
          ...preregister,
          prm_data: {
            ...preregister.prm_data,
            added_substance: addedSubstance,
            added_substance_img: addedSubstanceImage,
            added_substance_comments: addedSubstanceComments,
          },
        });
      }}
    />
  );
};

PRMAddedSubstance.propTypes = {
  preregister: PropTypes.shape({
    prm_data: PropTypes.shape({
      added_substance: PropTypes.bool,
      added_substance_img: PropTypes.number,
      added_substance_comments: PropTypes.number,
    }),
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

PRMAddedSubstance.defaultProps = {
  preregister: {},
};

export default PRMAddedSubstance;
