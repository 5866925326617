import {
  CLEAR_CREATED_HOOD_DEVICE,
  CLEAR_EDITED_HOOD_DEVICE,
  CREATE_HOOD_DEVICE_REQUEST,
  DELETE_HOOD_DEVICES_REQUEST,
  FETCH_HOOD_DEVICES_REQUEST,
  GET_HOOD_DEVICES_REQUEST,
  GET_UNUSED_HOOD_DEVICES_REQUEST,
  UPDATE_HOOD_DEVICE_REQUEST,
} from './types';

const getDevices = (payload) => ({
  type: GET_HOOD_DEVICES_REQUEST,
  payload,
});

const fetchDevices = (payload) => ({
  type: FETCH_HOOD_DEVICES_REQUEST,
  payload,
});

const getUnusedDevices = (payload) => ({
  type: GET_UNUSED_HOOD_DEVICES_REQUEST,
  payload,
});

const deleteDevices = (payload) => ({
  type: DELETE_HOOD_DEVICES_REQUEST,
  payload,
});

const createDevice = (payload) => ({
  type: CREATE_HOOD_DEVICE_REQUEST,
  payload,
});

const updateDevice = (payload) => ({
  type: UPDATE_HOOD_DEVICE_REQUEST,
  payload,
});

const clearErrors = () => ({
  type: 'CLEAR_ERRORS',
});

const clearCreated = () => ({
  type: CLEAR_CREATED_HOOD_DEVICE,
});

const clearEdited = () => ({
  type: CLEAR_EDITED_HOOD_DEVICE,
});

export {
  deleteDevices,
  getDevices,
  getUnusedDevices,
  createDevice,
  updateDevice,
  fetchDevices,
  clearErrors,
  clearCreated,
  clearEdited,
};
