import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import styles from './index.module.scss';
import AccionaButton from '../../../../../../../../components/AccionaButton';
import Step from '../../../../../../../../components/Step';
import PreregisterResume from '../../../common/PreregisterResume';

const { title, extraContent } = styles;

const Resume = ({
  onFinish,
  preregister,
  onCancel,
  onCreatePRM,
  stepNumber,
}) => {
  const intl = useIntl();
  return (
    <Step
      isTheLastStep
      stepNumber={stepNumber}
      title={intl.formatMessage({
        id: 'preregisterWizard.section.preregister.step09',
      })}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.preregister',
      })}
      extraContent={
        <div className={extraContent}>
          <AccionaButton
            className="btn btn-red"
            onClick={onCreatePRM}
            msg={intl.formatMessage({
              id: 'createPRM',
            })}
            classIconRight="icon-right fas fa-chevron-right"
          />
        </div>
      }
      content={
        <>
          <h4 className={title}>
            {intl.formatMessage({ id: `finishPreRegisterContent` })}
          </h4>
          <PreregisterResume preregister={preregister} />
        </>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish();
      }}
    />
  );
};

Resume.propTypes = {
  preregister: PropTypes.shape({
    docket_number: PropTypes.string,
    arriving_date: PropTypes.string,
    arriving_time: PropTypes.string,
    start_discharging_time: PropTypes.string,
    finish_discharging_time: PropTypes.string,
    mash_control: PropTypes.bool,
    mash_control_extra: PropTypes.bool,
    specimens_number: PropTypes.number,
    slump: PropTypes.number,
    detailed_location: PropTypes.string,
    elements: PropTypes.string,
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  onCreatePRM: PropTypes.func.isRequired,
};

Resume.defaultProps = {
  preregister: {},
};

export default Resume;
