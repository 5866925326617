export const parseFilters = (filters) => {
  let params = '';
  Object.keys(filters).forEach((filter) => {
    if (filter === 'sortable')
      params += `orderBy=${filters[filter].replace('-', ':')}&`;
    else params += `${filter}=${filters[filter]}&`;
  });
  return params;
};

export const getQueryData = (page, size, filters) => {
  const queryPage = `page=${page || 1}`;
  const querySize = `size=${size || 5}`;
  const queryFilters = filters ? parseFilters(filters) : undefined;
  return `?${queryPage}&${querySize}${
    queryFilters ? '&'.concat(queryFilters) : ''
  }`;
};

export default parseFilters;
