import {
  CLEAR_CREATED_THERMAL_CONTROL_MONITORING,
  CLEAR_EDITED_THERMAL_CONTROL_MONITORING,
  CREATE_THERMAL_CONTROL_MONITORING_REQUEST,
  DELETE_THERMAL_CONTROL_MONITORINGS_REQUEST,
  FINALIZE_THERMAL_CONTROL_MONITORING_REQUEST,
  GET_THERMAL_CONTROL_MONITORINGS_REQUEST,
  UPDATE_THERMAL_CONTROL_MONITORING_REQUEST,
} from './types';

const getMonitorings = (payload) => ({
  type: GET_THERMAL_CONTROL_MONITORINGS_REQUEST,
  payload,
});

const deleteMonitorings = (payload) => ({
  type: DELETE_THERMAL_CONTROL_MONITORINGS_REQUEST,
  payload,
});

const createMonitoring = (payload) => ({
  type: CREATE_THERMAL_CONTROL_MONITORING_REQUEST,
  payload,
});

const updateMonitoring = (payload) => ({
  type: UPDATE_THERMAL_CONTROL_MONITORING_REQUEST,
  payload,
});

const finalizeMonitoring = (payload) => ({
  type: FINALIZE_THERMAL_CONTROL_MONITORING_REQUEST,
  payload,
});

const clearErrors = () => ({
  type: 'CLEAR_ERRORS',
});

const clearCreated = () => ({
  type: CLEAR_CREATED_THERMAL_CONTROL_MONITORING,
});

const clearEdited = () => ({
  type: CLEAR_EDITED_THERMAL_CONTROL_MONITORING,
});

export {
  deleteMonitorings,
  getMonitorings,
  createMonitoring,
  updateMonitoring,
  finalizeMonitoring,
  clearErrors,
  clearCreated,
  clearEdited,
};
