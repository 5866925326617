import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';

const AntdDatePicker = ({ style, showTime, ...props }) => {
  return (
    <DatePicker
      allowClear={false}
      showTime={showTime ? { format: 'HH:mm' } : false}
      format={`DD/MM/YYYY ${showTime ? 'HH:mm' : ''}`}
      showNow={false}
      popupStyle={{ transform: 'scale3d(0.9, 0.9, 0.9)' }}
      style={{
        fontSize: '16px',
        color: ' #666666',
        background: '#f1f1f1',
        borderRadius: 0,
        width: '100%',
        padding: props.size === 'large' ? '14px' : undefined,
        ...style,
      }}
      popupClassName="antdDatePicker"
      cellRender={(current, { type, originNode }) => {
        if (type === 'year' || type === 'month') {
          return (
            <div className={`ant-picker-cell-inner ${type}`}>
              {type === 'year'
                ? current.year()
                : dayjs(current.toDate()).format('MMM')}
            </div>
          );
        }
        return originNode;
      }}
      inputReadOnly
      needConfirm
      {...props}
    />
  );
};

AntdDatePicker.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  showTime: PropTypes.bool,
};

AntdDatePicker.defaultProps = {
  style: {},
  size: 'medium',
  showTime: true,
};

export default AntdDatePicker;
