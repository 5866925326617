import * as TYPES from './types';

const initialState = {
  list: [],
  fetching: false,
  deleted: null,
  total: 0,
};
const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES.GET_PRE_DN_ONLINE_REQUEST:
    case TYPES.DELETE_PRE_DN_ONLINE_REQUEST:
      return { ...state, fetching: true };

    case TYPES.GET_PRE_DN_ONLINE_ERROR:
    case TYPES.DELETE_PRE_DN_ONLINE_ERROR:
      return {
        ...state,
        fetching: false,
      };
    case TYPES.DELETE_PRE_DN_ONLINE_SUCCESS: {
      return { ...state, deleted: payload };
    }
    case TYPES.GET_PRE_DN_ONLINE_SUCCESS: {
      return {
        ...state,
        total: payload.total,
        list: [...state.list, ...payload.list],
        fetching: false,
      };
    }

    case 'EMPTY_ONLINE_LIST': {
      return { ...state, list: [] };
    }

    default:
      return { ...state };
  }
};

export default reducer;
