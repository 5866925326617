import { NonConformityAPI, NonConformityCache } from './NonConformity';

export class PRM {
  constructor({
    right_appearance,
    right_appearance_comments,
    limit_time,
    limit_time_comments,
    defects_results,
    defects_results_comments,
    added_substance,
    added_substance_comments,
    substance_results,
    substance_results_comments,
    specimens_comments,
    signer,
    signature,
    non_conformity_data,
    specimens_img,
    added_substance_img,
    limit_time_img,
    right_appearance_img,
  }) {
    this.right_appearance = right_appearance;
    this.right_appearance_comments = right_appearance_comments;
    this.limit_time = limit_time;
    this.limit_time_comments = limit_time_comments;
    this.defects_results = defects_results;
    this.defects_results_comments = defects_results_comments;
    this.added_substance = added_substance;
    this.added_substance_comments = added_substance_comments;
    this.substance_results = substance_results;
    this.substance_results_comments = substance_results_comments;
    this.specimens_comments = specimens_comments;
    this.signer = signer;
    this.signature = signature;
    this.non_conformity_data = non_conformity_data;
    this.specimens_img = specimens_img;
    this.added_substance_img = added_substance_img;
    this.limit_time_img = limit_time_img;
    this.right_appearance_img = right_appearance_img;
  }
}

export class PRMApi extends PRM {
  constructor({
    id,
    prm_number,
    specimens_img,
    added_substance_img,
    limit_time_img,
    right_appearance_img,
    right_appearance,
    right_appearance_comments,
    limit_time,
    limit_time_comments,
    defects_results,
    defects_results_comments,
    added_substance,
    added_substance_comments,
    substance_results,
    substance_results_comments,
    specimens_comments,
    signer,
    signature,
    non_conformity_data,
  }) {
    super({
      right_appearance,
      right_appearance_comments,
      limit_time,
      limit_time_comments,
      defects_results,
      defects_results_comments,
      added_substance,
      added_substance_comments,
      substance_results,
      substance_results_comments,
      specimens_comments,
      signer,
      signature,
      specimens_img,
      added_substance_img,
      limit_time_img,
      right_appearance_img,
      non_conformity_data: non_conformity_data
        ? new NonConformityAPI(non_conformity_data)
        : null,
    });
    this.id = id;
    this.prm_number = prm_number;
  }
}

export class PRMCache extends PRM {
  constructor({
    specimens_img = [],
    added_substance_img,
    limit_time_img,
    right_appearance_img,
    right_appearance,
    right_appearance_comments,
    limit_time,
    limit_time_comments,
    defects_results,
    defects_results_comments,
    added_substance,
    added_substance_comments,
    substance_results,
    substance_results_comments,
    specimens_comments,
    signer,
    signature,
    non_conformity_data,
  }) {
    super({
      right_appearance,
      right_appearance_comments,
      limit_time,
      limit_time_comments,
      defects_results,
      defects_results_comments,
      added_substance,
      added_substance_comments,
      substance_results,
      substance_results_comments,
      specimens_comments,
      signer,
      signature,
      specimens_img,
      added_substance_img,
      limit_time_img,
      right_appearance_img,
      non_conformity_data: non_conformity_data
        ? new NonConformityCache(non_conformity_data)
        : null,
    });
  }
  async toJSON() {
    return {
      specimens_img: this.specimens_img,
      added_substance_img: this.added_substance_img,
      limit_time_img: this.limit_time_img,
      right_appearance_img: this.right_appearance_img,
      right_appearance: this.right_appearance,
      right_appearance_comments: this.right_appearance_comments,
      limit_time: this.limit_time,
      limit_time_comments: this.limit_time_comments,
      defects_results: this.defects_results,
      defects_results_comments: this.defects_results_comments,
      added_substance: this.added_substance,
      added_substance_comments: this.added_substance_comments,
      substance_results: this.substance_results,
      substance_results_comments: this.substance_results_comments,
      specimens_comments: this.specimens_comments,
      signer: this.signer,
      signature: this.signature,
      non_conformity_data: this.non_conformity_data?.toJSON(),
    };
  }

  static fromCache = ({ prm_data }) => {
    if (prm_data) {
      return new PRMCache({
        ...prm_data,
      });
    } else return null;
  };
  toApi() {
    return new PRMApi({
      ...this,
      specimens_img: this.specimens_img.length,
      added_substance_img: this.added_substance_img.length,
      limit_time_img: this.limit_time_img.length,
      right_appearance_img: this.right_appearance_img.length,
      non_conformity_data: this.non_conformity_data?.toApi(),
    });
  }
}
