import { useIntl } from 'react-intl';
import AccionaButton from '../AccionaButton';
import styles from './index.module.scss';
import { PropTypes } from 'prop-types';

const { wrapper, container, footer, extra, header, section } = styles;

const Step = ({
  content,
  onBackButtonClick,
  onNextButtonClick,
  disableNextButton,
  isTheLastStep,
  extraContent,
  title,
  sectionTitle,
  stepNumber,
}) => {
  const intl = useIntl();
  return (
    <div className={wrapper}>
      {sectionTitle && <div className={section}>{sectionTitle}</div>}
      {title && <div className={header}>{`${stepNumber}. ${title}`}</div>}
      <div className={container}>
        <div>{content}</div>
      </div>
      {extraContent ? <div className={extra}>{extraContent}</div> : null}
      <div className={footer}>
        <AccionaButton
          msg={intl.formatMessage({ id: 'back' })}
          onClick={onBackButtonClick}
          classIcon="icon-left icon fas fa-chevron-left"
        />

        <AccionaButton
          className="btn btn-red"
          onClick={onNextButtonClick}
          disabled={disableNextButton}
          msg={intl.formatMessage({
            id: isTheLastStep ? 'button.create.end' : 'continue',
          })}
          classIconRight={
            isTheLastStep ? '' : 'icon-right fas fa-chevron-right'
          }
        />
      </div>
    </div>
  );
};

Step.propTypes = {
  content: PropTypes.element.isRequired,
  extraContent: PropTypes.element,
  onBackButtonClick: PropTypes.func.isRequired,
  onNextButtonClick: PropTypes.func.isRequired,
  disableNextButton: PropTypes.bool,
  isTheLastStep: PropTypes.bool,
  title: PropTypes.string,
  sectionTitle: PropTypes.string,
  stepNumber: PropTypes.number.isRequired,
};

Step.defaultProps = {
  disableNextButton: false,
  isTheLastStep: false,
  extraContent: undefined,
  title: undefined,
  sectionTitle: undefined,
};

export default Step;
