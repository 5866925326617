import { ChartsColors } from '../../../../../../../../../constants/charts';
import dayjs from 'dayjs';

const chartConfig = {
  xField: 'timestamp',
  yField: 'resistance',
  seriesField: 'channel',
  theme: {
    styleSheet: {
      fontFamily: 'Acciona Font',
    },
  },
  meta: {
    timestamp: {
      tickCount: 5,
      formatter: (v, i) => dayjs(v).format('DD/MM/YYYY'),
    },
    resistance: {
      formatter: (v) => `${v} Mpa`,
    },
  },
  color: ({ channel }) => {
    return ChartsColors[channel[2] - 1];
  },
};

export default chartConfig;
