import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  savePreDNOffline,
  savePreDeliveryNote,
} from '../../../../../services/api/preDeliveryNote';
import SuccessPage from '../../../../Utils/SuccessPage';
import Cemex from './steps/preregister/015Cemex';
import DocketNumber from './steps/preregister/01DocketNumber';
import Delivery from './steps/preregister/02Delivery';
import StartDischargingTime from './steps/preregister/03StartDischargingTime';
import MashControl from './steps/preregister/04MashControl';
import Elements from './steps/preregister/05Elements';
import FinishDischargingTime from './steps/preregister/06FinishDischargingTime';
import DetailedLocation from './steps/preregister/07DetailedLocation';
import PhotoUploading from './steps/preregister/08PhotoUploading';
import Resume from './steps/preregister/09Resume';
import PRMRightAppearance from './steps/prm/01RightAppearance';
import PRMLimitTime from './steps/prm/02LimitTime';
import PRMDefectsResults from './steps/prm/03DefectsResults';
import PRMAddedSubstance from './steps/prm/04AddedSubstance';
import PRMResume from './steps/prm/07Resume';
import PRMSubstanceResults from './steps/prm/05SubstanceResults';
import PRMSpecimens from './steps/prm/06Specimens';
import PRMSignature from './steps/prm/09Signature';
import NCData from './steps/nc/01Data';
import NCResume from './steps/nc/02Resume';
import { PENDING_PREREGISTERS_TABLE } from '../../../../../indexedDB/data';
import {
  getObjectStore,
  putToCache,
  removeFromCache,
} from '../../../../../indexedDB/utils';
import { ConnectionContext } from '../../../../../context/ConnectionContext/provider';

const PreregisterWizard = () => {
  const [searchParams] = useSearchParams();
  const { model, id: pid } = useParams();
  const { setLoading, createError } = useContext(ConnectionContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [preregister, setPreregister] = useState({
    model,
    project: Number(pid),
  });

  const savePreregister = useCallback(
    (data) => {
      setLoading(true);
      const parsedPreregister = {
        docket_number: data.docket_number,
        project: Number(pid),
        arriving_time: data.arriving_time,
        arriving_date: data.arriving_date,
        detailed_location: data.detailed_location,
        finish_discharging_time: data.finish_discharging_time,
        mash_control: data.mash_control,
        mash_control_extra: data.mash_control_extra,
        slump: data.slump ? data.slump / 10 : data.slump,
        specimens_number: data.specimens_number,
        start_discharging_time: data.start_discharging_time,
        used: false,
        elements: data.elements,
        model: model.state || 'other',
        photo_element: data.photo_element,
        prm_data: data.prm_data,
      };

      if (navigator.onLine) {
        try {
          savePreDeliveryNote(parsedPreregister)
            .then(() => setCurrentStep(99))
            .catch(({ response }) => {
              createError({
                status: response.status,
                statusText: response.statusText,
                errorType: 'preRegisterErrorStep',
              });
            })
            .finally(() => {
              removeFromCache(PENDING_PREREGISTERS_TABLE, +pid);
              setLoading(false);
            });
        } catch (error) {
          createError({
            status: 500,
            statusText: 'Unknown Error',
            errorType: 'preRegisterErrorStep',
          });
        }
      } else {
        savePreDNOffline(parsedPreregister)
          .then(setCurrentStep(100))
          .catch((error) => {
            createError({
              status: error.status,
              statusText: error.statusText,
              errorType: 'preRegisterErrorStep',
            });
          })
          .finally(() => {
            removeFromCache(PENDING_PREREGISTERS_TABLE, +pid);
            setLoading(false);
          });
      }
    },
    [pid, createError, model.state, setLoading],
  );

  useEffect(() => {
    const getPendingPreregister = async () => {
      const object = await getObjectStore(PENDING_PREREGISTERS_TABLE);
      const request = object.get(+pid);
      request.onsuccess = () => {
        setPreregister(request.result);
      };

      request.onerror = (event) => {
        console.error('Error al recuperar el objeto:', event.target.error);
      };
      return object;
    };
    if (searchParams.get('pending') && pid) {
      getPendingPreregister();
    }
  }, [searchParams, pid]);

  useEffect(() => {
    if (currentStep !== 1) {
      putToCache(PENDING_PREREGISTERS_TABLE, preregister);
    }
  }, [preregister, currentStep]);

  const step = useMemo(() => {
    switch (currentStep) {
      case 1:
        return (
          <DocketNumber
            stepNumber={currentStep}
            onFinish={(updatedPreregister) => {
              if (model === 'cemex') {
                setCurrentStep((prev) => 1.5);
              } else {
                setCurrentStep((prev) => prev + 1);
              }
              setPreregister(updatedPreregister);
            }}
            preregister={preregister}
          />
        );
      case 1.5:
        return (
          <Cemex
            stepNumber={model === 'cemex' ? currentStep + 1 : currentStep}
            onFinish={(updatedPreregister) => {
              setCurrentStep(() => 2);
              setPreregister(updatedPreregister);
            }}
            onCancel={() => {
              setCurrentStep(1);
            }}
            preregister={preregister}
          />
        );
      case 2:
        return (
          <Delivery
            stepNumber={model === 'cemex' ? currentStep + 1 : currentStep}
            onFinish={(updatedPreregister) => {
              setCurrentStep((prev) => prev + 1);
              setPreregister(updatedPreregister);
            }}
            onCancel={() => {
              if (model === 'cemex') {
                setCurrentStep((prev) => 1.5);
              } else {
                setCurrentStep((prev) => prev - 1);
              }
            }}
            preregister={preregister}
          />
        );

      case 3:
        return (
          <StartDischargingTime
            stepNumber={model === 'cemex' ? currentStep + 1 : currentStep}
            onFinish={(updatedPreregister) => {
              setCurrentStep((prev) => prev + 1);
              setPreregister(updatedPreregister);
            }}
            onCancel={() => {
              setCurrentStep((prev) => prev - 1);
            }}
            preregister={preregister}
          />
        );

      case 4:
        return (
          <MashControl
            stepNumber={model === 'cemex' ? currentStep + 1 : currentStep}
            onFinish={(updatedPreregister) => {
              setCurrentStep((prev) => prev + 1);
              setPreregister(updatedPreregister);
            }}
            onCancel={() => {
              setCurrentStep((prev) => prev - 1);
            }}
            preregister={preregister}
          />
        );

      case 5:
        return (
          <Elements
            stepNumber={model === 'cemex' ? currentStep + 1 : currentStep}
            onFinish={(updatedPreregister) => {
              setCurrentStep((prev) => prev + 1);
              setPreregister(updatedPreregister);
            }}
            onCancel={() => {
              setCurrentStep((prev) => prev - 1);
            }}
            preregister={preregister}
          />
        );

      case 6:
        return (
          <FinishDischargingTime
            stepNumber={model === 'cemex' ? currentStep + 1 : currentStep}
            onFinish={(updatedPreregister) => {
              setCurrentStep((prev) => prev + 1);
              setPreregister(updatedPreregister);
            }}
            onCancel={() => {
              setCurrentStep((prev) => prev - 1);
            }}
            preregister={preregister}
          />
        );

      case 7:
        return (
          <DetailedLocation
            stepNumber={model === 'cemex' ? currentStep + 1 : currentStep}
            onFinish={(updatedPreregister) => {
              setCurrentStep((prev) => prev + 1);
              setPreregister(updatedPreregister);
            }}
            onCancel={() => {
              setCurrentStep((prev) => prev - 1);
            }}
            preregister={preregister}
          />
        );

      case 8:
        return (
          <PhotoUploading
            stepNumber={model === 'cemex' ? currentStep + 1 : currentStep}
            onFinish={(updatedPreregister) => {
              setCurrentStep((prev) => prev + 1);
              setPreregister(updatedPreregister);
            }}
            onCancel={() => {
              setCurrentStep((prev) => prev - 1);
            }}
            preregister={preregister}
          />
        );

      case 9:
        return (
          <Resume
            stepNumber={model === 'cemex' ? currentStep + 1 : currentStep}
            onFinish={() => {
              savePreregister({ ...preregister, prm_data: null });
            }}
            onCancel={() => {
              setCurrentStep((prev) => prev - 1);
            }}
            onCreatePRM={() => {
              setPreregister((prev) => ({ ...prev, prm_data: {} }));
              setCurrentStep(10);
            }}
            preregister={preregister}
          />
        );

      case 10:
        return (
          <PRMRightAppearance
            onFinish={(updatedPreregister) => {
              setCurrentStep((prev) => prev + 1);
              setPreregister(updatedPreregister);
            }}
            onCancel={() => {
              setCurrentStep((prev) => prev - 1);
            }}
            preregister={preregister}
          />
        );

      case 11:
        return (
          <PRMLimitTime
            onFinish={(updatedPreregister) => {
              setCurrentStep((prev) => prev + 1);
              setPreregister(updatedPreregister);
            }}
            onCancel={() => {
              setCurrentStep((prev) => prev - 1);
            }}
            preregister={preregister}
          />
        );

      case 12:
        return (
          <PRMDefectsResults
            onFinish={(updatedPreregister) => {
              setCurrentStep((prev) => prev + 1);
              setPreregister(updatedPreregister);
            }}
            onCancel={() => {
              setCurrentStep((prev) => prev - 1);
            }}
            preregister={preregister}
          />
        );

      case 13:
        return (
          <PRMAddedSubstance
            onFinish={(updatedPreregister) => {
              setCurrentStep((prev) => prev + 1);
              setPreregister(updatedPreregister);
            }}
            onCancel={() => {
              setCurrentStep((prev) => prev - 1);
            }}
            preregister={preregister}
          />
        );

      case 14:
        return (
          <PRMSubstanceResults
            onFinish={(updatedPreregister) => {
              setCurrentStep((prev) =>
                preregister.specimens_number > 0 ? prev + 1 : prev + 2,
              );
              setPreregister(updatedPreregister);
            }}
            onCancel={() => {
              setCurrentStep((prev) => prev - 1);
            }}
            preregister={preregister}
          />
        );

      case 15:
        return (
          <PRMSpecimens
            onFinish={(updatedPreregister) => {
              setCurrentStep((prev) => prev + 1);
              setPreregister(updatedPreregister);
            }}
            onCancel={() => {
              setCurrentStep((prev) => prev - 1);
            }}
            preregister={preregister}
          />
        );

      case 16:
        return (
          <PRMResume
            onFinish={() => {
              setCurrentStep((prev) => prev + 1);
            }}
            onCancel={() => {
              setCurrentStep((prev) =>
                preregister.specimens_number > 0 ? prev - 1 : prev - 2,
              );
            }}
            preregister={preregister}
          />
        );

      case 17:
        return (
          <PRMSignature
            onFinish={(updatedPreregister) => {
              setPreregister(updatedPreregister);
              savePreregister({
                ...updatedPreregister,
                prm_data: {
                  ...updatedPreregister.prm_data,
                  non_conformity_data: null,
                },
              });
            }}
            onCancel={() => {
              setCurrentStep((prev) => prev - 1);
            }}
            preregister={preregister}
            onCreateNC={(updatedPreregister) => {
              setPreregister({
                ...updatedPreregister,
                prm_data: {
                  ...updatedPreregister.prm_data,
                  non_conformity_data: {},
                },
              });
              setCurrentStep(18);
            }}
          />
        );

      case 18:
        return (
          <NCData
            onFinish={(updatedPreregister) => {
              setCurrentStep((prev) => prev + 1);
              setPreregister(updatedPreregister);
            }}
            onCancel={() => {
              setCurrentStep((prev) => prev - 1);
            }}
            preregister={preregister}
          />
        );
      case 19:
        return (
          <NCResume
            onFinish={() => {
              savePreregister(preregister);
            }}
            onCancel={() => {
              setCurrentStep((prev) => prev - 1);
            }}
            preregister={preregister}
          />
        );

      case 99:
        return <SuccessPage formatmsg="preDNSucess"></SuccessPage>;
      case 100:
        return <SuccessPage formatmsg="preDNSaved"></SuccessPage>;
      default:
        return null;
    }
  }, [currentStep, model, preregister, savePreregister]);

  return step;
};

export default PreregisterWizard;
