import { TimePicker } from 'antd';
import { PropTypes } from 'prop-types';

const AntdTimePicker = ({ style, ...props }) => {
  return (
    <TimePicker
      allowClear={props.allowClear}
      format="HH:mm"
      style={{
        fontSize: '16px',
        color: ' #666666',
        background: '#f1f1f1',
        borderRadius: 0,
        width: '100%',
        padding: props.size === 'large' ? '14px' : undefined,
        ...style,
      }}
      popupClassName="antdTimePicker"
      cellRender={(current, { type, originNode }) => {
        return (
          <div className={`ant-picker-time-panel-cell-inner ${type}`}>
            {current < 10 ? `0${current}` : current}
          </div>
        );
      }}
      inputReadOnly
      needConfirm
      {...props}
    />
  );
};

AntdTimePicker.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  allowClear: PropTypes.bool,
};

AntdTimePicker.defaultProps = {
  style: {},
  size: 'medium',
  allowClear: false,
};

export default AntdTimePicker;
