import { useIntl } from 'react-intl';
import Step from '../../../../../../../../components/Step';
import { PropTypes } from 'prop-types';
import Acciona3CheckButton from '../../../../../../../../components/Acciona3CheckButton';
import { useState } from 'react';
import AccionaInput from '../../../../../../../../components/AccionaInput';
import AccionaFormItem from '../../../../../../../../components/AccionaFormItem';

const PRMDefectsResults = ({ onFinish, preregister, onCancel }) => {
  const intl = useIntl();
  const [defectsResults, setDefectsResults] = useState(
    preregister.prm_data?.defects_results || false,
  );
  const [defectsResultsComments, setDefectsResultsComments] = useState(
    preregister.prm_data?.defects_results_comments,
  );

  return (
    <Step
      title={intl.formatMessage({ id: 'preregisterWizard.section.prm.step01' })}
      stepNumber={1}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.prm',
      })}
      content={
        <>
          <AccionaFormItem>
            <Acciona3CheckButton
              label="defects_results"
              state={{
                value: defectsResults,
              }}
              onChange={(value) => setDefectsResults(value)}
            />
          </AccionaFormItem>
          <AccionaFormItem>
            <AccionaInput
              type="text"
              label="comment"
              onChange={(value) => setDefectsResultsComments(value)}
              state={{
                value: defectsResultsComments,
              }}
            />
          </AccionaFormItem>
        </>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish({
          ...preregister,
          prm_data: {
            ...preregister.prm_data,
            defects_results: defectsResults,
            defects_results_comments: defectsResultsComments,
          },
        });
      }}
    />
  );
};

PRMDefectsResults.propTypes = {
  preregister: PropTypes.shape({
    prm_data: PropTypes.shape({
      defects_results: PropTypes.bool,
      defects_results_img: PropTypes.number,
      defects_results_comments: PropTypes.number,
    }),
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

PRMDefectsResults.defaultProps = {
  preregister: {},
};

export default PRMDefectsResults;
