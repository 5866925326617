import { PropTypes } from 'prop-types';
import styles from './index.module.scss';
import AccionaButton from '../../../../../../../../../components/AccionaButton';
import {
  downloadMonitoringDataHistoricals,
  getMonitoringDataHistoricals,
} from '../../../../../../../../../services/api/hoodedControl/monitoring';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { generateXlsxDownload } from '../../../../../../../../../utils/generateXlsxDownload';
import { useIntl } from 'react-intl';
import ChannelsPickers from './components/ChannelsPickers';
import AntdRangePicker from '../../../../../../../../../components/AntdRangePicker';
import { ConnectionContext } from '../../../../../../../../../context/ConnectionContext/provider';

const { headerWrapper, icon, wrapper, chartWrapper } = styles;

const ChartWrapper = ({
  render,
  monitoringId,
  name,
  range,
  onChange,
  availableChannels,
  finished,
}) => {
  const intl = useIntl();
  const noDataFoundTimeOut = useRef();
  const realTimeInterval = useRef();
  const { createError } = useContext(ConnectionContext);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [data, setData] = useState({ promed: [], amb: [] });
  const [channelRange, setChannelRange] = useState([undefined, undefined]);

  const downloadXlsx = async () => {
    setDownloading(true);
    await downloadMonitoringDataHistoricals({
      monitoringId,
      startDate: range[0].toISOString(),
      endDate: range[1].toISOString(),
    })
      .then((response) => {
        generateXlsxDownload(
          response.data,
          `${monitoringId}_${name}_${range[0].toISOString()}_${range[1].toISOString()}.xls`,
        );
        setDownloading(false);
      })
      .catch((e) => {
        createError(e.response);
        setDownloading(false);
      });
  };

  const getData = useCallback(
    async (id) => {
      try {
        const {
          data: { data },
        } = await getMonitoringDataHistoricals({
          monitoringId,
          startDate: range[0].toISOString(),
          endDate: range[1].toISOString(),
        });
        setData(data);
        setLoading(false);
      } catch (e) {
        if (e.response.status === 425) {
          if (noDataFoundTimeOut.current) {
            clearTimeout(noDataFoundTimeOut.current);
            noDataFoundTimeOut.current = undefined;
          }
          noDataFoundTimeOut.current = setTimeout(() => getData(id), 10000);
        } else {
          createError(e.response);
          setLoading(false);
        }
      }
    },
    [createError, monitoringId, range],
  );

  useEffect(() => {
    if (monitoringId) {
      setLoading(true);
      getData(monitoringId);
    }
  }, [monitoringId, getData, range]);

  useEffect(() => {
    if (!finished) {
      realTimeInterval.current = setInterval(() => {
        getData(monitoringId);
      }, 60000);

      return () => {
        clearInterval(realTimeInterval.current);
      };
    }
  }, [monitoringId, getData, finished]);

  return (
    <div className={wrapper}>
      <div className={headerWrapper}>
        {availableChannels && (
          <ChannelsPickers
            availableChannels={availableChannels}
            onChange={setChannelRange}
            channelRange={channelRange}
          />
        )}
        <AccionaButton
          loading={downloading}
          classIcon={`fas fa-file-download ${icon}`}
          msg={intl.formatMessage({
            id: 'strength.hoodedcontrol.monitorings.view.download',
          })}
          type="default"
          onClick={downloadXlsx}
          style={{
            height: '27px',
            fontSize: '0.8em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px 10px',
          }}
        />
        <AntdRangePicker defaultValue={range} onChange={onChange} />
      </div>
      <div className={chartWrapper}>
        {render({ loading, data, range, channelRange })}
      </div>
    </div>
  );
};

ChartWrapper.propTypes = {
  render: PropTypes.func.isRequired,
  monitoringId: PropTypes.number,
  name: PropTypes.string,
  range: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  availableChannels: PropTypes.arrayOf(
    PropTypes.shape({
      channel: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  finished: PropTypes.bool.isRequired,
};

ChartWrapper.defaultProps = {
  name: '',
  monitoringId: undefined,
  availableChannels: undefined,
};
export default ChartWrapper;
