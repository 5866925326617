import axios from './api';
import { getUrl } from './utils';

const endPoint = getUrl('api').concat('/api/v2/projects');

const getProject = (id) =>
  axios
    .get(endPoint.concat(`/${id}`))
    .then((res) => res)
    .catch((error) => error.response);

const getProjects = () => {
  return axios
    .get(endPoint)
    .then((res) => res)
    .catch((error) => error.response);
};

export { getProject, getProjects };
