import React from 'react';
import { PropTypes } from 'prop-types';

const AccionaTextArea = ({ label, state, onChange, disabled, placeholder }) => {
  return (
    <textarea
      style={{ color: 'black' }}
      autoComplete="off"
      placeholder={placeholder}
      id={`textArea-${label}`}
      disabled={disabled}
      name={label}
      value={state?.value}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};

AccionaTextArea.propTypes = {
  label: PropTypes.string.isRequired,
  state: PropTypes.shape({ value: PropTypes.string }).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

AccionaTextArea.defaultProps = {
  disabled: false,
  placeholder: undefined,
};

export default AccionaTextArea;
