import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import AccionaButton from '../../../../../../../components/AccionaButton';
import AccionaList from '../../../../../../../components/AccionaList';
import AccionaListButton from '../../../../../../../components/AccionaList/AccionaListButton';
import Modal from '../../../../../../../components/AccionaModal';
import RemoveModal from '../../../../../../../components/RemoveModal';
import CreateMonitoring from '../Create';
import { COLUMNS_HEADERS } from './columns';
import View from '../View';
import BatteryStatus from '../../../../../../../components/BatteryStatus';
import {
  clearCreated,
  clearEdited,
  clearErrors,
  createMonitoring,
  deleteMonitorings,
  getMonitorings,
  updateMonitoring,
  finalizeMonitoring,
} from '../../../../../../../services/redux/strength/thermalControl/monitorings/actions';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ConnectionContext } from '../../../../../../../context/ConnectionContext/provider';

const List = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setLoading } = useContext(ConnectionContext);
  const { project } = useOutletContext();
  const { isCreated, isEdited } = useSelector(
    ({ thermalControlMonitorings }) => thermalControlMonitorings,
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [monitoringForEdit, setMonitoringForEdit] = useState();
  const [monitoringForView, setMonitoringForView] = useState();

  const onSingleDeleteButtonClick = (monitoring) => {
    setSelectedRows([monitoring]);
    setShowRemoveModal(true);
  };

  const handleOnBulkDeleting = () => {
    setShowRemoveModal(false);
    dispatch(deleteMonitorings(selectedRows[0]));
    setSelectedRows([]);
  };

  const onCreateMonitoring = (monitoring) => {
    setLoading(true);
    dispatch(createMonitoring(monitoring));
  };

  const editMonitoring = (monitoring) => {
    setLoading(true);
    dispatch(updateMonitoring(monitoring));
  };

  const finishMonitoring = (monitoring) => {
    setLoading(true);
    dispatch(finalizeMonitoring(monitoring));
  };

  useEffect(() => {
    if (isEdited) {
      setLoading(false);
      setMonitoringForEdit(undefined);
      setShowEditModal(false);
      dispatch(clearEdited());
    }
  }, [isEdited, setLoading, dispatch]);

  useEffect(() => {
    if (isCreated) {
      setLoading(false);
      setShowCreateModal(false);
      dispatch(clearCreated());
    }
  }, [isCreated, setLoading, dispatch]);

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch, showCreateModal, showEditModal]);

  return (
    <>
      <div className="wrapper">
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '10px',
          }}
        >
          <AccionaButton
            id="createBtn"
            onClick={() => {
              setShowCreateModal(true);
            }}
            msg={intl.formatMessage({ id: 'button.add' })}
            classIcon="icon fas fa-plus"
            type="white"
          />
        </div>
        <AccionaList
          columns={COLUMNS_HEADERS}
          getItems={getMonitorings}
          pid={project?.id_project}
          entity="thermalControlMonitorings"
          title={intl.formatMessage({
            id: 'strength.thermalcontrol.monitorings.list',
          })}
          dataParseFunction={(monitorings) =>
            monitorings.map((monitoring) => ({
              ...monitoring,
              device: (
                <>
                  {monitoring.device_data?.reference_number}
                  {'  '}
                  {monitoring.device_data?.batt !== undefined ? (
                    <BatteryStatus battery={monitoring.device_data?.batt} />
                  ) : (
                    ''
                  )}
                </>
              ),
              finished: monitoring.finished
                ? intl.formatMessage({ id: 'yes' })
                : intl.formatMessage({ id: 'no' }),
              rawObject: monitoring,
            }))
          }
          actions={({ rawObject: monitoring }) => (
            <>
              <AccionaListButton
                onClick={() => {
                  setShowViewModal(true);
                  setMonitoringForView(monitoring);
                }}
                type="view"
                toolTip={intl.formatMessage({
                  id: 'viewAction.monitorings',
                })}
              />
              {!monitoring.finished && (
                <>
                  <AccionaListButton
                    onClick={() => {
                      setShowEditModal(true);
                      setMonitoringForEdit(monitoring);
                    }}
                    type="edit"
                    toolTip={intl.formatMessage({
                      id: 'editAction.monitorings',
                    })}
                  />
                  <AccionaListButton
                    onClick={() => {
                      onSingleDeleteButtonClick(monitoring);
                    }}
                    type="delete"
                    toolTip={intl.formatMessage({
                      id: 'deleteAction.monitorings',
                    })}
                  />
                </>
              )}
            </>
          )}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <AccionaButton
            msg={intl.formatMessage({ id: 'back' })}
            onClick={() => navigate(-1)}
            classIcon="icon fas fa-chevron-left"
            style={{ float: 'right', marginTop: '10px', marginRight: '0px' }}
          />
        </div>
      </div>

      <RemoveModal
        entity="monitorings"
        selectedRows={selectedRows}
        msg={intl.formatMessage(
          { id: 'delete.monitorings' },
          { total: selectedRows.length || 0 },
        )}
        onOk={handleOnBulkDeleting}
        onCancel={() => setShowRemoveModal((prev) => !prev)}
        hidden={!showRemoveModal}
      />
      {monitoringForEdit && (
        <Modal
          title={intl
            .formatMessage({
              id: 'strength.thermalcontrol.monitorings.list.modal.edit.title',
            })
            .toUpperCase()}
          open={showEditModal}
          onCancel={() => {
            setShowEditModal(false);
          }}
          closable
          width="90vw"
        >
          <CreateMonitoring
            initialData={monitoringForEdit}
            onFinish={editMonitoring}
            onFinishComplete={finishMonitoring}
            onCancel={() => {
              setShowEditModal(false);
              setMonitoringForEdit(undefined);
            }}
          />
        </Modal>
      )}
      <Modal
        title={intl
          .formatMessage({
            id: 'strength.thermalcontrol.monitorings.list.modal.create.title',
          })
          .toUpperCase()}
        open={showCreateModal}
        onCancel={() => {
          setShowCreateModal(false);
        }}
        closable
        width="90vw"
      >
        <CreateMonitoring
          onFinish={onCreateMonitoring}
          onCancel={() => {
            setShowCreateModal(false);
          }}
        />
      </Modal>
      {monitoringForView && (
        <Modal
          title={intl
            .formatMessage({
              id: 'strength.thermalcontrol.monitorings.list.modal.view.title',
            })
            .toUpperCase()}
          open={showViewModal}
          onCancel={() => {
            setShowViewModal(false);
            setMonitoringForView(undefined);
          }}
          closable
          width="90vw"
        >
          <View
            onCancel={() => {
              setShowViewModal(false);
              setMonitoringForView(undefined);
            }}
            monitoringId={monitoringForView.id}
          />
        </Modal>
      )}
    </>
  );
};

export default List;
