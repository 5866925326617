import { all, fork, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import * as Api from '../../../../api/hoodedControl/devices';
import Device from '../../../../../models/thermalControl/Device';
import { callToAPI } from '../../../../../utils/callToAPI';

function* getDevices({ payload }) {
  yield callToAPI({
    apiCall: Api.getDevices,
    successResponseCode: 200,
    errorType: types.GET_HOOD_DEVICES_ERROR,
    *callback({ data }) {
      yield put({
        type: types.GET_HOOD_DEVICES_SUCCESS,
        payload: {
          devices: data.data.map((devices) => {
            return new Device(devices);
          }),
          total: data.total,
        },
      });
    },
    payload,
  });
}

function* fetchDevices({ payload }) {
  yield callToAPI({
    apiCall: Api.fetchDevices,
    successResponseCode: 200,
    errorType: types.FETCH_HOOD_DEVICES_ERROR,
    *callback({ data }) {
      yield put({
        type: types.FETCH_HOOD_DEVICES_SUCCESS,
        payload: {
          devices: data.data.map((devices) => {
            return new Device(devices);
          }),
          total: data.total,
        },
      });
    },
    payload,
  });
}
function* getUnusedDevices({ payload }) {
  yield callToAPI({
    apiCall: Api.getUnusedDevices,
    successResponseCode: 200,
    errorType: types.GET_UNUSED_HOOD_DEVICES_ERROR,
    *callback({ data }) {
      yield put({
        type: types.GET_UNUSED_HOOD_DEVICES_SUCCESS,
        payload: {
          devices: data.data.map((devices) => {
            return new Device(devices);
          }),
          total: data.total,
        },
      });
    },
    payload,
  });
}

function* createDevice({ payload }) {
  yield callToAPI({
    apiCall: Api.createDevice,
    successResponseCode: 201,
    errorType: types.CREATE_HOOD_DEVICE_ERROR,
    *callback({ data }) {
      yield put({
        type: types.CREATE_HOOD_DEVICE_SUCCESS,
        payload: new Device(data),
      });
    },
    payload,
  });
}

function* updateDevice({ payload }) {
  yield callToAPI({
    apiCall: Api.updateDevice,
    successResponseCode: 200,
    errorType: types.UPDATE_HOOD_DEVICE_ERROR,
    *callback({ data }) {
      yield put({
        type: types.UPDATE_HOOD_DEVICE_SUCCESS,
        payload: new Device(data),
      });
    },
    payload,
  });
}

function* deleteDevices({ payload }) {
  yield callToAPI({
    apiCall: Api.deleteDevice,
    successResponseCode: 200,
    errorType: types.DELETE_HOOD_DEVICES_ERROR,
    *callback() {
      yield all(
        payload.map((item) => {
          return put({
            type: types.DELETE_HOOD_DEVICES_SUCCESS,
            payload: item,
          });
        }),
      );
    },
    payload,
  });
}

/* watchers */
function* watcherGetDevices() {
  yield takeEvery(types.GET_HOOD_DEVICES_REQUEST, getDevices);
}
function* watcherFetchDevices() {
  yield takeEvery(types.FETCH_HOOD_DEVICES_REQUEST, fetchDevices);
}

function* watcherGetUnusedDevices() {
  yield takeEvery(types.GET_UNUSED_HOOD_DEVICES_REQUEST, getUnusedDevices);
}
function* watcherDeleteDevices() {
  yield takeEvery(types.DELETE_HOOD_DEVICES_REQUEST, deleteDevices);
}
function* watcherCreateDevice() {
  yield takeEvery(types.CREATE_HOOD_DEVICE_REQUEST, createDevice);
}
function* watcherUpdateDevice() {
  yield takeEvery(types.UPDATE_HOOD_DEVICE_REQUEST, updateDevice);
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetDevices),
    fork(watcherFetchDevices),
    fork(watcherDeleteDevices),
    fork(watcherCreateDevice),
    fork(watcherUpdateDevice),
    fork(watcherGetUnusedDevices),
  ]);
}
