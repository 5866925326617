import { PropTypes } from 'prop-types';
import styles from './index.module.scss';
import AccionaSelect from '../../../../../../../../../../../components/AccionaSelect';
import { useIntl } from 'react-intl';

const { channelsSelectors } = styles;

const ChannelsPickers = ({ availableChannels, onChange, channelRange }) => {
  const intl = useIntl();
  return (
    <div className={channelsSelectors}>
      <AccionaSelect
        disabled={!availableChannels.length}
        clearable
        id="temperature1"
        onClear={() => {
          onChange((prev) => [undefined, prev[1]]);
        }}
        size="small"
        options={availableChannels
          ?.filter(({ channel }) => channel !== channelRange[1])
          .map(({ channel, description }) => ({
            value: channel,
            label:
              channel !== 'ch5'
                ? `C${channel.substring(1)} ${description || ''}`
                : intl.formatMessage({ id: 'environment' }),
          }))}
        placeholder={intl.formatMessage({ id: 'temperature' }, { number: 1 })}
        value={channelRange[0]}
        onChange={(value) => onChange((prev) => [value, prev[1]])}
      />
      <AccionaSelect
        disabled={!availableChannels.length}
        id="temperature2"
        size="small"
        clearable
        onClear={() => {
          onChange((prev) => [prev[0], undefined]);
        }}
        options={availableChannels
          ?.filter(({ channel }) => channel !== channelRange[0])
          .map(({ channel, description }) => ({
            value: channel,
            label:
              channel !== 'ch5'
                ? `C${channel.substring(1)} ${description || ''}`
                : intl.formatMessage({ id: 'environment' }),
          }))}
        placeholder={intl.formatMessage({ id: 'temperature' }, { number: 2 })}
        value={channelRange[1]}
        onChange={(value) => onChange((prev) => [prev[0], value])}
      />
    </div>
  );
};

ChannelsPickers.propTypes = {
  availableChannels: PropTypes.arrayOf(
    PropTypes.shape({
      channel: PropTypes.string,
      description: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  channelRange: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ChannelsPickers.defaultProps = {};

export default ChannelsPickers;
