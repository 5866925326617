import React from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import './App.css';
import messages from './locale/translations/index';
import Routes from './routes';
import store from './services/redux/store';
import { ConfigProvider } from 'antd';
import ConnectionProvider from './context/ConnectionContext/provider';
import utc from 'dayjs/plugin/utc';
import minMax from 'dayjs/plugin/minMax';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import dayjs from 'dayjs';
import locale from 'antd/locale/es_ES';
import 'dayjs/locale/es';

dayjs.extend(utc);
dayjs.extend(minMax);
dayjs.extend(isSameOrBefore);
dayjs.locale(navigator.language.substring(0, 2));

function App() {
  return (
    <Provider store={store}>
      <IntlProvider
        locale={navigator.language}
        messages={messages(navigator.language)}
      >
        <ConfigProvider
          locale={navigator.language.includes('es') ? locale : undefined}
          theme={{
            token: {
              colorPrimary: 'red',
              fontFamily: 'Acciona Font',
              colorBgContainerDisabled: 'rgba(0, 0, 0, 0.2)',
            },
            components: {
              DatePicker: {
                cellHeight: 30,
                cellWidth: 42,
                timeCellHeight: 30,
                timeColumnWidth: 42,
                cellBgDisabled: 'rgba(0, 0, 0, 0.04)',
              },
              Input: {
                inputFontSize: '16px',
                activeBorderColor: 'transparent',
              },
            },
          }}
        >
          <ConnectionProvider>
            <Routes />
          </ConnectionProvider>
        </ConfigProvider>
      </IntlProvider>
    </Provider>
  );
}

export default App;
