import axios from './api';
import { getUrl } from './utils';

const minioURL = getUrl('api') + '/api/v2/minio';
const backUrl = getUrl('backend') + '/api/v2/files';

export const uploadFile = async (file, path, fileName) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  var formData = new FormData();
  formData.append('file', file, fileName);
  return axios
    .post(minioURL.concat(`/${path}`), formData, { headers, timeout: 50000 })
    .then((response) => response.data)
    .catch((error) => error);
};

export const createFile = async (file, fileName) => {
  const headers = {
    file: fileName,
  };
  return axios
    .post(backUrl.concat(`?model=delivery&onlySave=1`), file, {
      headers: headers,
      timeout: 50000,
    })
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
};

export const getFile = async (path, fileName) => {
  return axios
    .get(minioURL.concat(`/${path}/${fileName}`), { responseType: 'blob' })
    .then((res) => res);
};
