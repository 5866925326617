export function haveValidKeys(objArray, keys) {
  if (!objArray) return false;

  for (let i in objArray) {
    for (let k in keys) {
      if (!objArray[i]) return false;
      if (!objArray[i][keys[k]]) return false;
    }
  }
  return true;
}

export function getPid(searchString) {
  return Number(searchString.match(/^\?pid=(\d+)/)[1]) || 1;
}

export function unnestingObjects(obj, nestedKey) {
  for (let key in obj) obj[key] = obj[key][nestedKey];
  return obj;
}

export function allAttrTrue(object) {
  for (const i in object) {
    if (!object[i].isSelected) return false;
  }
  return true;
}

export function allAttrAreValidated(object) {
  for (const i in object) {
    if (object[i]?.status === 'error') return false;
  }
  return true;
}
export function getValue(str, obj) {
  if (typeof obj === 'object' && obj !== null)
    return str.split('.').reduce((o, d) => o[d], obj);
}
export function docility(number, normative = 'EHE') {
  const limitAccordinNormative = normative === 'EHE' ? 55 : 45;
  if (number >= 0 && number <= 25) {
    return 'dry';
  } else if (number > 25 && number <= limitAccordinNormative) {
    return 'plastic';
  } else if (number > limitAccordinNormative && number <= 95) {
    return 'soft';
  } else if (number > 95 && number <= 155) {
    return 'fluid';
  } else if (number > 155 && number <= 210) {
    return 'liquid';
  }
}

export var normalize = (function () {
  var from = 'ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç',
    to = 'AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc',
    mapping = {};

  for (var i = 0, j = from.length; i < j; i++)
    mapping[from.charAt(i)] = to.charAt(i);

  return function (str) {
    var ret = [];
    for (var i = 0, j = str.length; i < j; i++) {
      var c = str.charAt(i);
      if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c]);
      else ret.push(c);
    }
    return ret.join('');
  };
})();

export function normalizeTime(time) {
  var splittedTime = time.split(':');

  if (splittedTime.length === 3) return time;
  else if (splittedTime.length === 2) return time + ':00';
}

export function getProject(pid) {
  const userProjects = JSON.parse(localStorage.getItem('projectsList'));
  const project = userProjects.find(
    (project) => project.id_project === parseInt(pid, 10),
  );
  return project;
}

export function parseFilters(filters) {
  try {
    let params = '';
    Object.keys(filters).forEach((filter) => {
      if (filter === 'sortable') {
        if (filters.sortable)
          params += `orderBy=${filters[filter].replace('-', ':')}&`;
      } else if (
        filter.includes('project') ||
        filter.includes('pid') ||
        filter.includes('used') ||
        filter.includes('docket_number') ||
        filter.includes('id')
      ) {
        if (filters[filter] !== '') params += `${filter}=${filters[filter]}&`;
      } else {
        params += `${filter}=like:${filters[filter]}&`;
      }
    });
    return params;
  } catch (e) {
    console.error(e);
  }
}

export function getQueryData(page, size, filters) {
  const queryPage = `page=${page || 1}`;
  const querySize = `size=${size || 5}`;
  const queryFilters = filters ? parseFilters(filters) : undefined;
  return `?${queryPage}&${querySize}${
    queryFilters ? '&'.concat(queryFilters) : ''
  }`;
}

const sortObjectKeys = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(sortObjectKeys);
  }

  return Object.keys(obj)
    .sort()
    .reduce((result, key) => {
      result[key] = sortObjectKeys(obj[key]);
      return result;
    }, {});
};

export const areObjectsEqual = (obj1, obj2) => {
  const sortedObj1 = sortObjectKeys(obj1);
  const sortedObj2 = sortObjectKeys(obj2);
  return JSON.stringify(sortedObj1) === JSON.stringify(sortedObj2);
};
