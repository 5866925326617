import React, { useEffect, useRef } from 'react';
import { PropTypes } from 'prop-types';
import { Line } from '@ant-design/plots';
import { useIntl } from 'react-intl';
import chartConfig from './index.config';
import { ChartsColors } from '../../../../../../../../../constants/charts';
import { extractChannelIdentifiers } from '../../../../../../../../../utils/extractChannelIdentifiers';
import dayjs from 'dayjs';

const TemperatureChart = ({ loading, data, monitoring, range }) => {
  const intl = useIntl();
  const plot = useRef();
  const channelNames = useRef(extractChannelIdentifiers(data, 'temperature'));
  const selectedChannels = useRef([...channelNames.current]);
  const sliderRange = useRef([0, 1]);

  useEffect(() => {
    const handler = ({ target }) => {
      const { id, name } = target.cfg;
      const match = id.match(/ch(\d+)/);
      if (match && name.includes('name')) {
        const channel = parseInt(match[1], 10);
        if (selectedChannels.current.includes(channel)) {
          selectedChannels.current = selectedChannels.current.filter(
            (c) => c !== channel,
          );
        } else {
          selectedChannels.current = [...selectedChannels.current, channel];
        }
      }
      if (match && name.includes('radio')) {
        const channel = parseInt(match[1], 10);
        if (selectedChannels.current.includes(channel)) {
          selectedChannels.current = selectedChannels.current.filter(
            (c) => c === channel,
          );
        } else {
          selectedChannels.current = [...channelNames.current];
        }
      }
    };
    const _plot = plot.current;
    _plot.getChart().on('legend-item:click', handler);

    return () => _plot.getChart().off('legend-item:click', handler);
  }, []);

  useEffect(() => {
    const _plot = plot.current;
    const handler = ({ view: { controllers } }) => {
      const { end, start } = controllers[4];
      sliderRange.current = [start, end];
    };
    _plot.getChart().on('slider:mouseup', handler);

    return () => {
      _plot.getChart().off('slider:mouseup', handler);
    };
  }, []);

  useEffect(() => {
    const channels = extractChannelIdentifiers(data, 'temperature');
    channelNames.current = channels;
    if (selectedChannels.current.length === 0) {
      selectedChannels.current = channels;
    }
  }, [data]);

  useEffect(() => {
    sliderRange.current = [0, 1];
  }, [range]);

  return (
    <>
      <Line
        {...chartConfig}
        data={data}
        loading={loading}
        xAxis={{
          title: {
            text: intl.formatMessage({
              id: 'strength.thermalcontrol.monitorings.view.charts.date',
            }),
          },
          label: {
            offset: 10,
          },
        }}
        yAxis={{
          title: {
            text: intl.formatMessage({
              id: 'strength.thermalcontrol.monitorings.view.charts.temperature',
            }),
          },
        }}
        legend={{
          offsetX: 35,
          layout: 'horizontal',
          position: 'top-left',
          custom: true,
          selected: channelNames.current.reduce((acc, channel) => {
            acc[`ch${channel}`] = selectedChannels.current.includes(channel);
            return acc;
          }, {}),
          itemName: {
            formatter: (v) =>
              v === 'ch5'
                ? intl.formatMessage({ id: 'environment' })
                : monitoring[v],
          },
          items: channelNames.current.map((i) => ({
            name: `ch${i}`,
            value: `ch${i}`,
            id: `ch${i}`,
            marker: {
              symbol: 'hyphen',
              stroke: ChartsColors[+i - 1],
              style: { stroke: ChartsColors[+i - 1], lineWidth: 2 },
            },
            unchecked: !selectedChannels.current.includes(i),
          })),
        }}
        tooltip={{
          formatter: ({ channel, timestamp, temperature }) => {
            return {
              name:
                channel === 'ch5'
                  ? intl.formatMessage({ id: 'environment' })
                  : monitoring[channel],
              title: dayjs(timestamp).format('DD/MM/YYYY HH:mm'),
              value: temperature + 'ºC',
            };
          },
          customItems: (originalItems) => {
            originalItems.sort((a, b) =>
              a.data.channel.localeCompare(b.data.channel),
            );
            return originalItems;
          },
        }}
        slider={{
          formatter: (v) => dayjs(v).format('DD/MM/YYYY'),
          foregroundStyle: { fill: '#ff000090' },
          height: 30,
          textStyle: { fontWeight: 600, fill: 'black' },
          start: sliderRange.current[0],
          end: sliderRange.current[1],
        }}
        ref={plot}
      />
    </>
  );
};

TemperatureChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      channel: PropTypes.string,
      maturity: PropTypes.number,
      temperature: PropTypes.number,
      timestamp: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  monitoring: PropTypes.object.isRequired,
  range: PropTypes.arrayOf(PropTypes.object).isRequired,
};

TemperatureChart.defaultProps = {
  loading: false,
  data: [],
};

export default React.memo(TemperatureChart);
