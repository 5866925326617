import { uploadFile } from './files';

function getUrl(target) {
  let url = document.location.href;

  if (url.includes('review.')) {
    url = url.replace(/review\./g, '');
  }

  if (url.includes('localhost') || url.includes('127.0.0.1'))
    return 'http://localhost';
  else {
    if (url.includes('dev')) return `https://${target}.ocr.dev.digiconcrete.es`;
    else return `https://${target}.ocr.digiconcrete.es`;
  }
}

function transformFileToBase64(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
}

function transformBase64ToFile(base64, name, extension) {
  var arr = base64.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const imgObjetc = new File([u8arr], name + extension, { type: mime });

  return imgObjetc;
}

function transformBase64ToBlob(base64, sliceSize, contentType) {
  const byteCharacters = atob(base64.split(',')[1]);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });

  return blob;
}

function transformBlobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

async function prmImgUploader(fileList, name, { docketNumber, pid }) {
  try {
    const filesP = fileList.map((file, i) =>
      uploadFile(file, 'PRM', `${docketNumber}_${pid}_${name}_${i}`),
    );
    const result = await Promise.all(filesP);
    return result.length;
  } catch (error) {
    alert(error);
    throw error;
  }
}

export {
  getUrl,
  prmImgUploader,
  transformBase64ToBlob,
  transformBase64ToFile,
  transformBlobToBase64,
  transformFileToBase64,
};
