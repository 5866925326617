import { ChartsColors } from '../../../../../../../../../constants/charts';

const chartConfig = {
  xField: 'timestamp',
  yField: 'temperature',
  theme: {
    styleSheet: {
      fontFamily: 'Acciona Font',
    },
  },
  color: () => {
    return ChartsColors[0];
  },
};

export default chartConfig;
