import { PropTypes } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const AccionaFormItem = ({ children, style, hidden }) => {
  const intl = useIntl();
  return (
    <div
      hidden={hidden}
      className="form-row text-label input-gray"
      style={style}
    >
      <label htmlFor={children.props.label}>
        {intl.formatMessage({ id: children.props.label })}
      </label>
      {children.props.state ? (
        <div
          className={
            children.props.state?.status === 'error' ? 'input-err' : ''
          }
        >
          {children}
        </div>
      ) : (
        children
      )}
    </div>
  );
};

AccionaFormItem.propTypes = {
  children: PropTypes.element.isRequired,
  style: PropTypes.object,
  hidden: PropTypes.bool,
};

AccionaFormItem.defaultProps = {
  style: {},
  hidden: false,
};

export default AccionaFormItem;
