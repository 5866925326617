export const GET_PRE_DN_ONLINE_REQUEST = 'GET_PRE_DN_ONLINE_REQUEST';
export const GET_PRE_DN_ONLINE_SUCCESS = 'GET_PRE_DN_ONLINE_SUCCESS';
export const GET_PRE_DN_ONLINE_ERROR = 'GET_PRE_DN_ONLINE_ERROR';

export const DELETE_PRE_DN_ONLINE_REQUEST = 'DELETE_PRE_DN_ONLINE_REQUEST';
export const DELETE_PRE_DN_ONLINE_SUCCESS = 'DELETE_PRE_DN_ONLINE_SUCCESS';
export const DELETE_PRE_DN_ONLINE_ERROR = 'DELETE_PRE_DN_ONLINE_ERROR';

export const SAVE_PRE_DN_REQUEST = 'SAVE_PRE_DN_REQUEST';
export const SAVE_PRE_DN_SUCCESS = 'SAVE_PRE_DN_REQUEST';
export const SAVE_PRE_DN_ERROR = 'SAVE_PRE_DN_ERROR';
