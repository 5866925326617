export const GET_THERMAL_CONTROL_MONITORINGS_REQUEST =
  'GET_THERMAL_CONTROL_MONITORINGS_REQUEST';
export const GET_THERMAL_CONTROL_MONITORINGS_SUCCESS =
  'GET_THERMAL_CONTROL_MONITORINGS_SUCCESS';
export const GET_THERMAL_CONTROL_MONITORINGS_ERROR =
  'GET_THERMAL_CONTROL_MONITORINGS_ERROR';

export const DELETE_THERMAL_CONTROL_MONITORINGS_REQUEST =
  'DELETE_THERMAL_CONTROL_MONITORINGS_REQUEST';
export const DELETE_THERMAL_CONTROL_MONITORINGS_SUCCESS =
  'DELETE_THERMAL_CONTROL_MONITORINGS_SUCCESS';
export const DELETE_THERMAL_CONTROL_MONITORINGS_ERROR =
  'DELETE_THERMAL_CONTROL_MONITORINGS_ERROR';

export const UPDATE_THERMAL_CONTROL_MONITORING_REQUEST =
  'UPDATE_THERMAL_CONTROL_MONITORING_REQUEST';
export const UPDATE_THERMAL_CONTROL_MONITORING_SUCCESS =
  'UPDATE_THERMAL_CONTROL_MONITORING_SUCCESS';
export const UPDATE_THERMAL_CONTROL_MONITORING_ERROR =
  'UPDATE_THERMAL_CONTROL_MONITORING_ERROR';

export const CREATE_THERMAL_CONTROL_MONITORING_REQUEST =
  'CREATE_THERMAL_CONTROL_MONITORING_REQUEST';
export const CREATE_THERMAL_CONTROL_MONITORING_SUCCESS =
  'CREATE_THERMAL_CONTROL_MONITORING_SUCCESS';
export const CREATE_THERMAL_CONTROL_MONITORING_ERROR =
  'CREATE_THERMAL_CONTROL_MONITORING_ERROR';

export const FINALIZE_THERMAL_CONTROL_MONITORING_REQUEST =
  'FINALIZE_THERMAL_CONTROL_MONITORING_REQUEST';
export const FINALIZE_THERMAL_CONTROL_MONITORING_SUCCESS =
  'FINALIZE_THERMAL_CONTROL_MONITORING_SUCCESS';
export const FINALIZE_THERMAL_CONTROL_MONITORING_ERROR =
  'FINALIZE_THERMAL_CONTROL_MONITORING_ERROR';

export const CLEAR_CREATED_THERMAL_CONTROL_MONITORING =
  'CLEAR_CREATED_THERMAL_CONTROL_MONITORING';
export const CLEAR_EDITED_THERMAL_CONTROL_MONITORING =
  'CLEAR_EDITED_THERMAL_CONTROL_MONITORING';
