const GET_PROJECT_REQUEST = 'GET_PROJECT_REQUEST';
const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
const GET_PROJECT_ERROR = 'GET_PROJECT_ERROR';

const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST';
const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
const GET_PROJECTS_ERROR = 'GET_PROJECTS_ERROR';

const CLEAR_PROJECTS_STATE = 'CLEAR_PROJECTS_STATE';
const CLEAR_ERRORS = 'CLEAR_ERRORS';
const CLEAR_ERRORS_PROFILE = 'CLEAR_ERRORS_PROFILE';
const INIT_REDUCER = 'INIT_REDUCER';

const CREATE_ERRORS = 'CREATE_ERRORS';

export {
  GET_PROJECT_REQUEST,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_ERROR,
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_ERROR,
  CLEAR_PROJECTS_STATE,
  CLEAR_ERRORS,
  CLEAR_ERRORS_PROFILE,
  CREATE_ERRORS,
  INIT_REDUCER,
};
