import { Navigate, Outlet, useParams } from 'react-router-dom';
import { getProject } from '../../utils/utils';

const ProtectedComponent = () => {
  const { id: pid } = useParams();
  const project = getProject(pid);
  return project ? <Outlet context={{ project }} /> : <Navigate to="/login" />;
};

export default ProtectedComponent;
