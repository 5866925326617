import { ChartsColors } from '../../../../../../../../../constants/charts';

const chartConfig = {
  xField: 'timestamp',
  yField: 'maturity',
  seriesField: 'channel',
  theme: {
    styleSheet: {
      fontFamily: 'Acciona Font',
    },
  },
  color: ({ channel }) => {
    return ChartsColors[channel === 'mean' ? 9 : channel[2] - 1];
  },

  lineStyle: ({ channel }) => {
    if (channel === 'mean') {
      return { lineWidth: 3, lineDash: [5, 5] };
    }
  },
  interactions: [
    {
      type: 'legend-filter',
      cfg: {
        active: false,
      },
    },
  ],
};

export default chartConfig;
