import { useEffect, useState } from 'react';
import Step from '../../../../../../../../components/Step';
import AccionaFormItem from '../../../../../../../../components/AccionaFormItem';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import Acciona2CheckButton from '../../../../../../../../components/Acciona2CheckButton';
import { Collapse } from 'antd';
import AccionaInput from '../../../../../../../../components/AccionaInput';
import styles from './index.module.scss';
import { useOutletContext } from 'react-router-dom';

const { panel, row } = styles;

const MashControl = ({ onFinish, preregister, onCancel, stepNumber }) => {
  const intl = useIntl();
  const { project } = useOutletContext();
  const [mashControl, setMashControl] = useState(
    preregister.mash_control || false,
  );
  const [specimensNumber, setSpecimensNumber] = useState(
    preregister.specimens_number,
  );
  const [slump, setSlump] = useState(preregister.slump);
  const [mashControlExtra, setMashControlExtra] = useState(
    preregister.mash_control_extra || false,
  );
  const [docility, setDocility] = useState('void');
  const [error, setError] = useState({
    mashControl: false,
    slump: false,
    specimensNumber: false,
    mashControlExtra: false,
  });
  const [limitAccordingNormative, setLimitAccordingNormative] = useState(55);

  useEffect(() => {
    const calculateDocility = () => {
      if (slump > 210) {
        return 'void';
      } else if (slump >= 0 && slump <= 25) {
        return 'dry';
      } else if (slump > 25 && slump <= limitAccordingNormative) {
        return 'plastic';
      } else if (slump > limitAccordingNormative && slump <= 95) {
        return 'soft';
      } else if (slump > 95 && slump <= 155) {
        return 'fluid';
      } else if (slump > 155 && slump <= 210) {
        return 'liquid';
      }
    };
    switch (project.normative_name) {
      case 'EHE':
        setLimitAccordingNormative(55);
        break;
      case 'CE':
        setLimitAccordingNormative(45);
        break;
      default:
        setLimitAccordingNormative(55);
        break;
    }

    setDocility(calculateDocility());
  }, [project.normative_name, slump, limitAccordingNormative]);

  useEffect(() => {
    setError((prev) => ({ ...prev, specimensNumber: false }));
    if (
      specimensNumber &&
      (specimensNumber.toString().length < 0 ||
        specimensNumber.toString().length > 9)
    ) {
      setError((prev) => ({ ...prev, specimensNumber: true }));
    }
  }, [specimensNumber]);

  useEffect(() => {
    setError((prev) => ({ ...prev, slump: false }));
    if (slump && (slump.toString().length < 0 || slump.toString().length > 2)) {
      setError((prev) => ({ ...prev, slump: true }));
    }
  }, [slump]);

  return (
    <Step
      stepNumber={stepNumber}
      title={intl.formatMessage({
        id: 'preregisterWizard.section.preregister.step04',
      })}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.preregister',
      })}
      content={
        <>
          <AccionaFormItem>
            <Acciona2CheckButton
              label="mash_control"
              state={{
                value: mashControl,
                status: error.mashControl ? 'error' : 'success',
              }}
              onChange={(value) => setMashControl(value)}
            />
          </AccionaFormItem>
          <div style={{ display: mashControl ? '' : 'none' }}>
            <Collapse
              ghost
              activeKey={mashControl ? 1 : 0}
              items={[
                {
                  key: '1',
                  label: null,
                  showArrow: false,
                  children: (
                    <div className={panel}>
                      <AccionaFormItem
                        style={
                          error.specimensNumber
                            ? { marginBottom: '5px' }
                            : undefined
                        }
                      >
                        <AccionaInput
                          label="specimens_number"
                          type="number"
                          state={{
                            value: specimensNumber,
                            status: error.specimensNumber ? 'error' : 'success',
                          }}
                          onChange={(value) => setSpecimensNumber(value)}
                        />
                      </AccionaFormItem>
                      <div
                        className={`${
                          error.specimensNumber ? 'infoError' : ''
                        }`}
                      >
                        {error.specimensNumber &&
                          intl.formatMessage(
                            { id: 'validations.digitsBetween' },
                            { max: 10, min: 1 },
                          )}
                      </div>
                      <div className={row}>
                        <div>
                          <AccionaFormItem
                            style={
                              error.slump ? { marginBottom: '5px' } : undefined
                            }
                          >
                            <AccionaInput
                              type="number"
                              label="slump"
                              state={{
                                value: slump,
                                status: error.slump ? 'error' : 'success',
                              }}
                              onChange={(value) => setSlump(value)}
                            />
                          </AccionaFormItem>
                          <div className={`${error.slump ? 'infoError' : ''}`}>
                            {error.slump &&
                              intl.formatMessage(
                                { id: 'validations.digitsBetween' },
                                { max: 2, min: 0 },
                              )}
                          </div>
                        </div>
                        <AccionaFormItem
                          style={error ? { marginBottom: '5px' } : undefined}
                        >
                          <div label="consistency" id="consistency">
                            {docility && intl.formatMessage({ id: docility })}
                          </div>
                        </AccionaFormItem>
                      </div>
                      <AccionaFormItem>
                        <Acciona2CheckButton
                          label="mash_control_extra"
                          state={{
                            value: mashControlExtra,
                            status: error.mashControlExtra
                              ? 'error'
                              : 'success',
                          }}
                          onChange={(value) => setMashControlExtra(value)}
                        />
                      </AccionaFormItem>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish({
          ...preregister,
          mash_control: mashControl,
          specimens_number: specimensNumber,
          mash_control_extra: mashControlExtra,
          slump: slump,
        });
      }}
      disableNextButton={
        mashControl === undefined ||
        (mashControl && !specimensNumber && !slump) ||
        error.mashControl ||
        error.mashControlExtra ||
        error.slump ||
        error.specimensNumber
      }
    />
  );
};

MashControl.propTypes = {
  preregister: PropTypes.shape({
    mash_control: PropTypes.bool,
    specimens_number: PropTypes.number,
    mash_control_extra: PropTypes.bool,
    slump: PropTypes.number,
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
};

MashControl.defaultProps = {
  preregister: {},
};

export default MashControl;
