import { getQueryData } from '../../../utils/parseFilters';
import axios from '../api';
import { getUrl } from '../utils';

const endPoint = getUrl('api') + '/api/v2/thermalcontrol/mixtures';

export const getMixtures = async ({ page, size, filters }) => {
  if (filters?.pid) {
    filters.project = filters.pid;
    delete filters.pid;
  }

  return axios
    .get(endPoint.concat(getQueryData(page, size, filters)))
    .then((res) => res);
};

export const getMaturity = async ({ id, endDate }) => {
  return axios
    .get(endPoint.concat('/', id, '/maturity', `?endDate=${endDate}`))
    .then((res) => res);
};

export const getMixture = async (mixtureId) =>
  axios.get(endPoint.concat(`/${mixtureId}`));

export const deleteMixture = async ({ id }) =>
  axios.delete(endPoint.concat('/', id)).then((res) => res);

export const createMixture = async (mixture) =>
  axios.post(endPoint, mixture).then((res) => res);

export const calculateFunction = async (params) =>
  axios.post(endPoint.concat('/function'), params).then((res) => res);

export const updateMixture = async (mixture) =>
  axios.put(endPoint.concat('/', mixture.id), mixture).then((res) => res);

export const finalizeMixture = async (mixture) =>
  axios
    .put(endPoint.concat('/', mixture.id, '/finalize'), mixture)
    .then((res) => res);

export const checkMixtureExists = async (id) =>
  axios.get(endPoint.concat('/', 'search/', id), { timeout: '3000' });

export const getMixtureDataHistoricals = async ({
  mixtureId,
  startDate,
  endDate,
}) =>
  axios.get(
    endPoint.concat(
      `/${mixtureId}/historical?startDate=${startDate}&endDate=${endDate}`,
    ),
  );

export const downloadMixtureDataHistoricals = async ({
  mixtureId,
  startDate,
  endDate,
}) =>
  axios.get(
    endPoint.concat(
      `/${mixtureId}/data/download?startDate=${startDate}&endDate=${endDate}`,
    ),
    { responseType: 'blob' },
  );
