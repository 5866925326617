import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { getLogin } from '../../services/redux/auth/actions';
import usePrevious from '../../hooks/usePrevious';

const ConnectionContext = createContext();

const ConnectionProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [online, setOnline] = useState(window.navigator.onLine);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const prevOnline = usePrevious(online);

  const createError = useCallback(
    (
      webError = {
        status: 500,
        message: 'Error Not Found',
        statusText: 'Error Not Found',
      },
    ) => {
      setError(webError);
    },
    [],
  );
  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');

    if (online && prevOnline === false && username && password) {
      dispatch(getLogin({ username, password }));
    }
  }, [dispatch, online, prevOnline]);

  window.addEventListener('offline', () => setOnline(false));
  window.addEventListener('online', () => setOnline(true));

  const objectValue = useMemo(
    () => ({
      error,
      createError,
      clearError,
      loading,
      setLoading,
      online: {
        state: online,
        setOnline: setOnline,
      },
    }),
    [createError, error, loading, online],
  );

  return (
    <ConnectionContext.Provider value={objectValue}>
      {children}
    </ConnectionContext.Provider>
  );
};

ConnectionProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { ConnectionContext };
export default ConnectionProvider;
