import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';

const Details = ({ preRegisterDoc }) => {
  const keys = {
    other: [
      'docket_number',
      'arriving_date',
      'arriving_time',
      'start_discharging_time',
      'finish_discharging_time',
      'mash_control',
      'mash_control_extra',
      'specimens_number',
      'slump',
      'detailed_location',
      'elements',
    ],
    cemex: [
      'docket_number',
      'arriving_date',
      'manufacture_time',
      'arriving_time',
      'start_discharging_time',
      'finish_discharging_time',
      'recipe',
      'mash_control',
      'mash_control_extra',
      'slump',
      'specimens_number',
      'detailed_location',
      'plant',
      'delivered',
      'elements',
    ],
  };
  const { formatMessage } = useIntl();
  return (
    <div className="row row-project" style={{ margin: '0 10px 0 10px' }}>
      <div className="col-xs-12 col-sm-12 col-md-12">
        {keys[preRegisterDoc.model].map((key) => {
          let content = (
            <div style={{ fontSize: '17px' }} key={key}>
              <span style={{ fontWeight: 'bold', marginRight: '10px' }}>
                {formatMessage({ id: key })}:
              </span>
              {(() => {
                switch (key) {
                  case 'mash_control_extra':
                    return preRegisterDoc[key] === 0 ||
                      preRegisterDoc[key] === false
                      ? formatMessage({ id: 'no' })
                      : formatMessage({ id: 'yes' });
                  case 'mash_control':
                    return preRegisterDoc[key] === 0 ||
                      preRegisterDoc[key] === false
                      ? formatMessage({ id: 'no' })
                      : formatMessage({ id: 'yes' });
                  case 'specimens_number':
                    return preRegisterDoc[key] === ''
                      ? 'N/A'
                      : preRegisterDoc[key];
                  case 'slump':
                    return preRegisterDoc[key] === ''
                      ? 'N/A'
                      : preRegisterDoc[key] * 10;
                  case 'arriving_date':
                    return preRegisterDoc[key];
                  case 'elements':
                    if (preRegisterDoc[key] === null) {
                      return formatMessage({ id: 'notElements' });
                    } else {
                      const elementsArray = preRegisterDoc[key].split(',');
                      return (
                        <ul>
                          {elementsArray.map((element, index) => (
                            <li key={index}>{'- ' + element.trim()}</li>
                          ))}
                        </ul>
                      );
                    }
                  default:
                    return preRegisterDoc[key];
                }
              })()}
            </div>
          );
          return content;
        })}
      </div>
    </div>
  );
};

Details.propTypes = {
  preRegisterDoc: PropTypes.object,
};

Details.defaultProps = {
  preRegisterDoc: undefined,
};

export default Details;
