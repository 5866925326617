import axios from 'axios';
import { getUrl } from './utils';

const endPoint = getUrl('api') + '/api/v2/users';

const getLogin = async ({ username, password }) => {
  return axios
    .post(endPoint.concat('/login'), { username, password })
    .then((res) => res)
    .catch((error) => error.response);
};

export default getLogin;
