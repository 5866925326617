import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ConnectionContext } from '../../context/ConnectionContext/provider';
import { getLogin, setLoginError } from '../../services/redux/auth/actions';
import iconPassWhite from '../../style/img/icon_pass_white.svg';
import iconUser from '../../style/img/icon_user.svg';
import loginImg from '../../style/img/logo_white_login.png';
import digiconcreteLogo from '../../style/img/digiconcrete_logo.png';
import ErrorPage from '../Utils/ErrorPage';
import Footer from '../../Layout/components/Footer';

const Login = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userName, setUserName] = useState(
    localStorage.getItem('username') || '',
  );
  const [password, setPassword] = useState(
    localStorage.getItem('password') || '',
  );
  const { online, createError, error } = useContext(ConnectionContext);
  const { loginError, isLoggedIn } = useSelector(({ auth }) => auth);

  const onClickButton = () => {
    if (online.state === false)
      createError({
        status: 503,
        statusText: 'service.unavailable',
        message: 'loggin.offline',
      });
    else dispatch(getLogin({ username: userName, password }));
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/app/projects', { replace: true });
    }
  }, [navigate, isLoggedIn]);

  if (error) {
    return (
      <div
        style={{
          background: 'white',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ErrorPage errorMessages={error} />
      </div>
    );
  }

  return (
    <div className="login">
      <div className="wrapper">
        <div className="body">
          <div>
            <h1>
              <img
                src={digiconcreteLogo}
                alt="Acciona"
                style={{ marginBottom: '25px', width: '350px' }}
              />
            </h1>
            <div
              className="errors alert alert-danger"
              style={loginError ? {} : { display: 'none' }}
            >
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                onClick={() => dispatch(setLoginError(false))}
              >
                &times;
              </button>
              <i className="fa fa-ban-circle"></i>
              <strong>
                <span runat="server" id="errorMessage">
                  {formatMessage({ id: 'loggin.invalid' })}
                </span>
              </strong>
            </div>
            <div className="login-card">
              <div className="body">
                <div className="form-row">
                  <input
                    className="text-box single-line"
                    id="UserName"
                    name="UserName"
                    type="text"
                    placeholder={formatMessage({ id: 'loggin.user' })}
                    onChange={(event) => setUserName(event.target.value)}
                    value={userName}
                  />
                  <div className="form-img">
                    <img src={iconUser} alt="Username" />
                  </div>
                </div>
                <div className="form-row">
                  <input
                    id="Password"
                    name="Password"
                    type="password"
                    placeholder={formatMessage({
                      id: 'loggin.password',
                    })}
                    onChange={(event) => setPassword(event.target.value)}
                    value={password}
                  />
                  <div className="form-img">
                    <img src={iconPassWhite} alt="Password" />
                  </div>
                </div>
              </div>
              <div>
                <div className="form-row text-center">
                  <input
                    type="submit"
                    value="Log in"
                    className="button"
                    onClick={() => {
                      onClickButton();
                    }}
                  />
                </div>
              </div>
            </div>
            <img
              src={loginImg}
              alt="Acciona"
              style={{ width: '200px', height: 'auto', marginTop: '25px' }}
            />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default Login;
