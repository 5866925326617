import { useState } from 'react';
import Step from '../../../../../../../../components/Step';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import styles from './index.module.scss';
import { useParams } from 'react-router-dom';
import AntdFormItem from '../../../../../../../../components/AntdFormItem';
import AntdDatePicker from '../../../../../../../../components/AntdDatePicker';
import AntdTimePicker from '../../../../../../../../components/AntdTimePicker';
import dayjs from 'dayjs';

const { wrapper } = styles;

const Delivery = ({ onFinish, preregister, onCancel, stepNumber }) => {
  const intl = useIntl();
  const { model } = useParams();
  const [arrivingTime, setArrivingTime] = useState(
    preregister.arriving_time
      ? dayjs(preregister.arriving_time, 'HH:mm')
      : dayjs(),
  );
  const [arrivingDate, setArrivingDate] = useState(
    preregister.arriving_date
      ? dayjs(preregister.arriving_date, 'DD/MM/YYYY')
      : dayjs(),
  );

  return (
    <Step
      stepNumber={stepNumber}
      title={intl.formatMessage({
        id: 'preregisterWizard.section.preregister.step02',
      })}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.preregister',
      })}
      content={
        <div className={wrapper}>
          <AntdFormItem
            layout="vertical"
            label={intl.formatMessage({ id: 'arriving_time' })}
          >
            <AntdTimePicker
              size="large"
              onChange={(inputValue) => setArrivingTime(inputValue)}
              value={arrivingTime}
              disabledTime={
                arrivingDate.isSame(dayjs(), 'day')
                  ? () => {
                      const currentHour = dayjs().hour();
                      const currentMinute = dayjs().minute();

                      return {
                        disabledHours: () => {
                          const hours = [];
                          for (let i = currentHour + 1; i <= 23; i++) {
                            hours.push(i);
                          }
                          return hours;
                        },
                        disabledMinutes: (selectedHour) => {
                          if (selectedHour === currentHour) {
                            const minutes = [];
                            for (let i = currentMinute + 1; i <= 59; i++) {
                              minutes.push(i);
                            }
                            return minutes;
                          }
                          return [];
                        },
                      };
                    }
                  : undefined
              }
              allowClear
            />
          </AntdFormItem>
          <AntdFormItem
            layout="vertical"
            label={intl.formatMessage({ id: 'arriving_date' })}
          >
            <AntdDatePicker
              size="large"
              popupClassName="antdDatePicker"
              value={arrivingDate}
              showTime={false}
              onChange={(inputValue) => {
                setArrivingDate(inputValue);
              }}
              disabled={model === 'cemex'}
              disabledDate={(current) => {
                return current && current > dayjs().endOf('day');
              }}
              allowClear
            />
          </AntdFormItem>
        </div>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish({
          ...preregister,
          arriving_time: arrivingTime.format('HH:mm'),
          arriving_date: arrivingDate.format('DD/MM/YYYY'),
        });
      }}
      disableNextButton={!arrivingTime || !arrivingDate}
    />
  );
};

Delivery.propTypes = {
  preregister: PropTypes.shape({
    arriving_date: PropTypes.string,
    arriving_time: PropTypes.string,
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
};

Delivery.defaultProps = {
  preregister: {},
};

export default Delivery;
