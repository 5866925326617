import axios from './api';
import { getUrl } from './utils';

const endPoint = getUrl('api') + '/api/v1/deliverynote';

export const listDeliveryNote = (filters) =>
  axios
    .get(endPoint.concat(`/list?${filters}`))
    .then((res) => res)
    .catch((error) => error.response);
