import { MinusCircleOutlined } from '@ant-design/icons';
import { PropTypes } from 'prop-types';
import AccionaModal from '../../../../../../../../../components/AccionaModal';
import style from './index.module.scss';
import AccionaButton from '../../../../../../../../../components/AccionaButton';
import { useIntl } from 'react-intl';
import { useMemo, useState } from 'react';

const { footer, wrapper, addNewRowButton, disabled } = style;

const UNIT = (
  <>
    Kg/m<sup>3</sup>
  </>
);

export const MixtureDetailsTable = ({
  data,
  onChange,
  onRemove,
  editable,
  showTotal,
}) => {
  const intl = useIntl();
  return (
    <table className="dataTable" width="100%">
      <thead>
        <tr>
          <th>{intl.formatMessage({ id: 'material' })}</th>
          <th>{intl.formatMessage({ id: 'quantity' })}</th>
          {editable && <th width="60px"></th>}
        </tr>
      </thead>
      <tbody>
        {data.map(({ material, quantity }, index) => (
          <tr key={index}>
            <td align="center">
              {editable ? (
                <input
                  type="text"
                  placeholder={intl.formatMessage({ id: 'material' })}
                  value={material}
                  onChange={({ target }) =>
                    onChange('material', target.value || undefined, index)
                  }
                />
              ) : (
                material
              )}
            </td>
            <td align="center">
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {editable ? (
                  <input
                    type="number"
                    placeholder="0"
                    value={quantity}
                    onChange={({ target }) =>
                      onChange(
                        'quantity',
                        parseFloat(target.value) || undefined,
                        index,
                      )
                    }
                  />
                ) : (
                  quantity
                )}
                <span style={{ marginLeft: '5px' }}>{UNIT}</span>
              </div>
            </td>
            {editable && (
              <td align="center">
                <MinusCircleOutlined
                  onClick={() => {
                    onRemove(index);
                  }}
                />
              </td>
            )}
          </tr>
        ))}
        {showTotal && (
          <tr>
            <td align="center">
              <strong>Total</strong>
            </td>
            <td align="center">
              <strong>
                {data
                  .map(({ quantity }) => quantity)
                  .reduce((partialSum, a) => partialSum + a, 0)}{' '}
                {UNIT}
              </strong>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

MixtureDetailsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({ material: PropTypes.string, quantity: PropTypes.number }),
  ),
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  editable: PropTypes.bool,
  showTotal: PropTypes.bool,
};

MixtureDetailsTable.defaultProps = {
  data: [],
  onChange: () => {},
  onRemove: () => {},
  editable: true,
  showTotal: false,
};

const MixtureDetailsModal = ({
  open,
  onCancel,
  onOk,
  mixtureDetails: _THERMAL_CONTROL_MIXTUREDetails,
}) => {
  const intl = useIntl();
  const [mixtureDetails, setMixtureDetails] = useState(
    _THERMAL_CONTROL_MIXTUREDetails ? [..._THERMAL_CONTROL_MIXTUREDetails] : [],
  );
  const addNewRow = () => {
    setMixtureDetails((prev) => [
      ...prev,
      { material: undefined, quantity: undefined },
    ]);
  };
  const updateRow = (type, value, index) => {
    const newList = [...mixtureDetails];
    newList[index][type] = value;

    setMixtureDetails(newList);
  };
  const removeRow = (index) => {
    const newList = [...mixtureDetails];
    newList.splice(index, 1);

    setMixtureDetails(newList);
  };

  const isAddingDisabled = useMemo(() => {
    if (!mixtureDetails.length) {
      return false;
    }
    return mixtureDetails.some((detail) =>
      Object.values(detail).includes(undefined),
    );
  }, [mixtureDetails]);

  const everyInputIsCompleted = () => {
    return mixtureDetails.every((d) => Object.values(d).every((v) => v));
  };

  return (
    <AccionaModal
      title={intl
        .formatMessage({
          id: 'strength.thermalcontrol.mixtures.create.mixtureDetailsModal.title',
        })
        .toLocaleUpperCase()}
      open={open}
      width="500px"
      onCancel={onCancel}
      closable
    >
      <div className={wrapper}>
        {mixtureDetails.length > 0 && (
          <MixtureDetailsTable
            data={mixtureDetails}
            onChange={updateRow}
            onRemove={removeRow}
          />
        )}
        <div
          className={`${addNewRowButton} ${isAddingDisabled ? disabled : ''}`}
          onClick={() => {
            if (!isAddingDisabled) addNewRow();
          }}
        >
          + {intl.formatMessage({ id: 'button.add' }).toUpperCase()}
        </div>
        <div className={`${footer}`}>
          <AccionaButton
            type="default"
            msg={intl.formatMessage({ id: 'cancel' })}
            onClick={onCancel}
          />
          <AccionaButton
            msg={intl.formatMessage({
              id: 'button.add',
            })}
            onClick={() => onOk(mixtureDetails)}
            disabled={!everyInputIsCompleted()}
          />
        </div>
      </div>
    </AccionaModal>
  );
};

MixtureDetailsModal.propTypes = {
  open: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  mixtureDetails: PropTypes.arrayOf(
    PropTypes.shape({
      material: PropTypes.string,
      quantity: PropTypes.number,
    }),
  ),
};

MixtureDetailsModal.defaultProps = {
  open: false,
  onOk: () => {},
  onCancel: () => {},
  mixtureDetails: [],
};

export default MixtureDetailsModal;
