import { PropTypes } from 'prop-types';
import { useEffect } from 'react';
import style from './index.module.scss';
import { useIntl } from 'react-intl';
import AccionaButton from '../../../../../../../components/AccionaButton';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { SwapRightOutlined } from '@ant-design/icons';
import AverageTemperatureChart from './components/AverageTemperatureChart';
import ChartWrapper from './components/ChartWrapper';
import { getMixture } from '../../../../../../../services/redux/strength/thermalControl/mixtures/actions';
import { MixtureDetailsTable } from './components/MixtureDetailsModal';
import AccionaCollapsableCard from '../../../../../../../components/AccionaCollapsableCard';

const { wrapper, footer, calibration, parameters, header } = style;

const View = ({ mixtureId, onCancel }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const mixture = useSelector(({ thermalControlMixtures }) =>
    thermalControlMixtures.list.find((mixture) => mixture.id === mixtureId),
  );

  const getMaxDate = (dates) => {
    return dayjs.max(dates);
  };

  useEffect(() => {
    dispatch(getMixture(mixtureId));
  }, [dispatch, mixtureId]);

  return (
    <div className={wrapper}>
      <AccionaCollapsableCard
        className={header}
        briefContent={
          <div style={{ display: 'flex' }}>
            <dl>
              <dt>{intl.formatMessage({ id: 'mixture' })}</dt>
              <dd>{mixture?.name}</dd>
            </dl>
            <dl>
              <dt>{intl.formatMessage({ id: 'project' })}</dt>
              <dd>{`${mixture?.project_data?.work_code} ${mixture?.project_data?.name}`}</dd>
            </dl>
          </div>
        }
        moreContent={
          <>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <dl>
                <dt>{intl.formatMessage({ id: 'device' })}</dt>
                <dd>{mixture?.device_data.reference_number}</dd>
              </dl>
              <dl>
                <dt>{intl.formatMessage({ id: 'maturity_method' })}</dt>
                <dd>{mixture?.maturity_method}</dd>
              </dl>
              <dl>
                <dt>{intl.formatMessage({ id: 'monitoring_time_period' })}</dt>
                <dd>
                  {dayjs(mixture.start_time).format('DD/MM/YYYY HH:mm')}{' '}
                  <SwapRightOutlined />{' '}
                  {mixture.parameters.length
                    ? getMaxDate(
                        mixture.parameters.map(({ datetime }) =>
                          dayjs(datetime),
                        ),
                      ).format('DD/MM/YYYY HH:mm')
                    : '--'}
                </dd>
              </dl>
              <dl>
                <dt>{intl.formatMessage({ id: 'daysElapsed' })}</dt>
                <dd>
                  {intl.formatMessage(
                    { id: 'days' },
                    {
                      total:
                        dayjs(
                          mixture.parameters.length
                            ? getMaxDate(
                                mixture.parameters.map(({ datetime }) =>
                                  dayjs(datetime),
                                ),
                              )
                            : dayjs(),
                        ).diff(dayjs(mixture.start_time), 'days') || 0,
                    },
                  )}
                </dd>
              </dl>
            </div>
            <div style={{ display: 'flex' }}>
              <dl>
                <dt>{intl.formatMessage({ id: 'composition' })}</dt>
                <dd>
                  {mixture.details?.length && (
                    <MixtureDetailsTable
                      data={mixture.details || []}
                      editable={false}
                      showTotal
                    />
                  )}
                </dd>
              </dl>
            </div>
          </>
        }
      />
      <div className={parameters}>
        <ChartWrapper
          finished={mixture?.finished}
          startTime={mixture?.start_time}
          endTime={
            mixture.parameters.length
              ? getMaxDate(
                  mixture.parameters.map(({ datetime }) => dayjs(datetime)),
                ).toISOString()
              : dayjs().toISOString()
          }
          mixtureId={mixtureId}
          name="temperature"
          render={({ data, loading, range }) => {
            return (
              <AverageTemperatureChart
                data={data}
                loading={loading}
                range={range}
              />
            );
          }}
        />
      </div>
      <div className={calibration}>
        <dl>
          <dt>{intl.formatMessage({ id: 'calibration' })}</dt>
          <dd>{mixture?.equations?.equation}</dd>
        </dl>
      </div>
      <div className={`${footer}`}>
        <AccionaButton
          type="primary"
          msg={intl.formatMessage({ id: 'close' })}
          onClick={onCancel}
        />
      </div>
    </div>
  );
};

View.propTypes = {
  mixtureId: PropTypes.number,
  onCancel: PropTypes.func,
};

View.defaultProps = {
  mixtureId: undefined,
  onCancel: () => {},
};
export default View;
