import { Result } from 'antd';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import AccionaButton from '../../components/AccionaButton';
import { ConnectionContext } from '../../context/ConnectionContext/provider';
import { PropTypes } from 'prop-types';

const ErrorPage = ({ errorMessages }) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { clearError } = useContext(ConnectionContext);

  const onClick = () => {
    clearError();
    navigate(errorMessages.navigateTo || '/login');
  };

  return (
    <Result
      id="errorResult"
      icon={
        <i className="fas fa-times-circle fa-8x" style={{ color: '#ff0000' }} />
      }
      status="error"
      title={formatMessage({ id: errorMessages.message || 'error' })}
      subTitle={`${errorMessages.status} - ${formatMessage({
        id: errorMessages.statusText || 'error',
      })}`}
      extra={[
        <div className="form-row" key={1}>
          <AccionaButton
            id="errorBtn"
            onClick={() => onClick()}
            classIcon="icon fas fa-times"
            msg={formatMessage({ id: 'button.create.end' })}
          />
        </div>,
      ]}
    />
  );
};

ErrorPage.propTypes = {
  errorMessages: PropTypes.shape({
    navigateTo: PropTypes.string,
    message: PropTypes.string,
    status: PropTypes.string,
    statusText: PropTypes.string,
  }).isRequired,
};

export default ErrorPage;
