import { useIntl } from 'react-intl';
import AccionaButton from '../../../../../../../components/AccionaButton';
import { Collapse } from 'antd';
import { useLayoutEffect, useMemo, useState } from 'react';
import { CE, EHE } from './normative';
import { PropTypes } from 'prop-types';
import InProgressPreregisterModal from '../InProgressPreregisterModal';
import AccionaSimpleModal from '../../../../../../../components/AccionaSimpleModal';
import { useParams } from 'react-router-dom';
import {
  getObjectStore,
  removeFromCache,
} from '../../../../../../../indexedDB/utils';
import { PENDING_PREREGISTERS_TABLE } from '../../../../../../../indexedDB/data';

const InnerModal = ({ project, onCancel, onOk, model, setModel }) => {
  const intl = useIntl();

  const processedData = useMemo(() => {
    switch (project.normative_name) {
      case 'EHE':
        return EHE;
      case 'CE':
        return CE;
      default:
        return EHE;
    }
  }, [project]);

  return (
    <>
      {model ? (
        <div
          style={{
            fontFamily: "'Acciona Font', sans-serif",
            color: '#f00',
            textTransform: 'uppercase',
          }}
        >
          <p>
            {intl.formatMessage(
              { id: `mandatoryFieldsContent` },
              { normative: project.normative_name },
            )}
          </p>
          <div id="infoNormative">
            <Collapse
              items={[
                {
                  key: 1,
                  label: intl.formatMessage({ id: `more_info` }),
                  children: processedData(),
                },
              ]}
            />
          </div>
          <div className="form-row" style={{ width: '100%' }}>
            <AccionaButton
              classIcon="icon fas fa-times"
              className="btn btn-red"
              msg={intl.formatMessage({ id: 'no' })}
              onClick={onCancel}
              style={{ marginTop: '20px', width: '120px' }}
            />
            <AccionaButton
              className="btn btn-red"
              classIcon="icon fas fa-check"
              msg={intl.formatMessage({ id: 'yes' })}
              onClick={() => onOk(model)}
              style={{ marginTop: '20px', width: '120px', float: 'right' }}
            />
          </div>
        </div>
      ) : (
        <div className="containerModelButtons">
          <AccionaButton
            className=""
            msg={intl.formatMessage({ id: 'cemex' })}
            onClick={navigator.onLine ? () => setModel('cemex') : null}
            disabled={!navigator.onLine}
          />
          <AccionaButton
            className=""
            msg={intl.formatMessage({ id: 'other' })}
            onClick={() => setModel('other')}
          />
        </div>
      )}
    </>
  );
};

const InitPreregisterModal = ({ visible, onCancel, project, onOk }) => {
  const intl = useIntl();
  const { id: pid } = useParams();
  const [model, setModel] = useState();
  const [preregisterInProgress, setPreregisterInProgress] = useState();

  useLayoutEffect(() => {
    const getPendingPreregister = async () => {
      const object = await getObjectStore(PENDING_PREREGISTERS_TABLE);
      const request = object.get(+pid);
      request.onsuccess = () => {
        if (request.result) {
          setPreregisterInProgress(request.result);
        }
      };
      return object;
    };
    getPendingPreregister();
  }, [pid]);

  if (preregisterInProgress) {
    return (
      <InProgressPreregisterModal
        open={visible}
        onCancel={() => {
          setPreregisterInProgress(undefined);
          removeFromCache(PENDING_PREREGISTERS_TABLE, +pid);
        }}
        onOk={() => onOk(preregisterInProgress.model, preregisterInProgress)}
      />
    );
  }

  return (
    <AccionaSimpleModal
      show={visible}
      onCancel={onCancel}
      title={
        model
          ? intl.formatMessage({ id: `mandatoryFieldsTitle` })
          : intl.formatMessage({ id: `modelTitle` })
      }
      content={
        <InnerModal
          visible={Boolean(model)}
          onCancel={onCancel}
          project={project}
          model={model}
          setModel={setModel}
          onOk={onOk}
        />
      }
    />
  );
};

InnerModal.propTypes = {
  project: PropTypes.shape({ normative_name: PropTypes.string }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  model: PropTypes.string,
  setModel: PropTypes.func.isRequired,
};

InnerModal.defaultProps = {
  model: undefined,
};

InitPreregisterModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  project: PropTypes.shape({ normative_name: PropTypes.string }).isRequired,
};

InitPreregisterModal.defaultProps = {
  visible: false,
};

export default InitPreregisterModal;
