import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import AccionaBigButton from '../../../../../components/AccionaBigButton';

const Home = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  return (
    <div className="wrapper fullscreen">
      <div className="row">
        <div className="col-xs-12 col-sm-4 col-md-4">
          <AccionaBigButton
            icon="thermalControl"
            id="thermalControl"
            onClick={() => navigate(`thermalControl`)}
            msg={formatMessage({ id: `thermalControl` })}
          />
        </div>
        <div className="col-xs-12 col-sm-4 col-md-4">
          <AccionaBigButton
            icon="hoodedControl"
            id="hoodedControl"
            onClick={() => navigate(`hoodedControl`)}
            msg={formatMessage({ id: `hoodedControl` })}
          />
        </div>
      </div>
    </div>
  );
};
export default Home;
