import { Input } from 'antd';

const AntdInput = ({ ...props }) => {
  return (
    <Input
      {...props}
      style={{
        fontSize: '16px',
        color: ' #666666',
        borderRadius: 0,
      }}
    />
  );
};

export default AntdInput;
