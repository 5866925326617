import { PropTypes } from 'prop-types';
import styles from './index.module.scss';
import { useState } from 'react';

const { wrapper, brief, more, visible } = styles;

const AccionaCollapsableCard = ({ briefContent, moreContent, className }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <div className={`${wrapper}`}>
      <i
        className={`icon fas fa-chevron-${collapsed ? 'up' : 'down'}`}
        onClick={() => {
          setCollapsed((prev) => !prev);
        }}
      />
      <div className={`${className ?? ''}`}>
        <div className={brief}>{briefContent}</div>
        <div className={`${more} ${collapsed ? visible : ''}`}>
          {moreContent}
        </div>
      </div>
    </div>
  );
};

AccionaCollapsableCard.propTypes = {
  briefContent: PropTypes.element.isRequired,
  moreContent: PropTypes.element.isRequired,
  className: PropTypes.string,
};

AccionaCollapsableCard.defaultProps = {
  className: undefined,
};

export default AccionaCollapsableCard;
