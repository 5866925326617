import React from 'react';
import { PropTypes } from 'prop-types';
import styles from './index.module.scss';
import { ReactComponent as Spin } from './ajax-loader-white.svg';

const { button, byDefault, primary, white, onlyIcon, spin } = styles;

const Spinner = () => (
  <Spin height="24" width="24" alt="Loading" className={spin}></Spin>
);

const AccionaButton = ({
  onClick,
  msg,
  classIcon,
  className,
  htmlType,
  disabled,
  classIconRight,
  style,
  id,
  type,
  loading,
}) => {
  const getClassNameByType = () => {
    switch (type) {
      case `primary`:
        return primary;
      case `white`:
        return white;
      default:
        return byDefault;
    }
  };
  return (
    <button
      style={style}
      className={
        className ||
        `${button} ${getClassNameByType()} ${classIcon && !msg ? onlyIcon : ''}`
      }
      onClick={onClick}
      type={htmlType}
      disabled={disabled}
      id={id}
    >
      {classIcon && (loading ? <Spinner /> : <i className={classIcon} />)}
      {msg}
      {classIconRight &&
        (loading ? <Spinner /> : <i className="icon fa fas-loading" />)}
    </button>
  );
};

AccionaButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  msg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  classIcon: PropTypes.string,
  classIconRight: PropTypes.string,
  className: PropTypes.string,
  htmlType: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  type: PropTypes.oneOf(['default', 'primary', 'white']),
  loading: PropTypes.bool,
};

AccionaButton.defaultProps = {
  onClick: () => {},
  msg: undefined,
  classIcon: undefined,
  classIconRight: undefined,
  className: '',
  htmlType: 'button',
  disabled: false,
  style: {},
  id: 'AccionaButton',
  type: 'primary',
  loading: false,
};

export default AccionaButton;
