class Device {
  constructor({
    id,
    project,
    reference_number,
    description,
    inUse = false,
    location,
    batt,
  }) {
    this.id = id;
    this.project = project;
    this.reference_number = reference_number;
    this.description = description;
    this.inUse = inUse;
    this.location = location;
    this.batt = batt;
  }
}

export default Device;
