import {
  CLEAR_CREATED_THERMAL_CONTROL_MIXTURE,
  CLEAR_EDITED_THERMAL_CONTROL_MIXTURE,
  CREATE_THERMAL_CONTROL_MIXTURE_REQUEST,
  DELETE_THERMAL_CONTROL_MIXTURES_REQUEST,
  FINALIZE_THERMAL_CONTROL_MIXTURE_REQUEST,
  GET_THERMAL_CONTROL_MIXTURES_REQUEST,
  GET_THERMAL_CONTROL_MIXTURE_REQUEST,
  UPDATE_THERMAL_CONTROL_MIXTURE_REQUEST,
} from './types';

const getMixtures = (payload) => ({
  type: GET_THERMAL_CONTROL_MIXTURES_REQUEST,
  payload,
});

const getMixture = (payload) => ({
  type: GET_THERMAL_CONTROL_MIXTURE_REQUEST,
  payload,
});

const deleteMixtures = (payload) => ({
  type: DELETE_THERMAL_CONTROL_MIXTURES_REQUEST,
  payload,
});

const createMixture = (payload) => ({
  type: CREATE_THERMAL_CONTROL_MIXTURE_REQUEST,
  payload,
});

const finalizeMixture = (payload) => ({
  type: FINALIZE_THERMAL_CONTROL_MIXTURE_REQUEST,
  payload,
});

const updateMixture = (payload) => ({
  type: UPDATE_THERMAL_CONTROL_MIXTURE_REQUEST,
  payload,
});

const clearErrors = () => ({
  type: 'CLEAR_ERRORS',
});

const clearCreated = () => ({
  type: CLEAR_CREATED_THERMAL_CONTROL_MIXTURE,
});

const clearEdited = () => ({
  type: CLEAR_EDITED_THERMAL_CONTROL_MIXTURE,
});

export {
  deleteMixtures,
  getMixtures,
  createMixture,
  updateMixture,
  finalizeMixture,
  clearErrors,
  clearCreated,
  clearEdited,
  getMixture,
};
