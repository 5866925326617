export const GET_HOOD_MONITORINGS_REQUEST = 'GET_HOOD_MONITORINGS_REQUEST';
export const GET_HOOD_MONITORINGS_SUCCESS = 'GET_HOOD_MONITORINGS_SUCCESS';
export const GET_HOOD_MONITORINGS_ERROR = 'GET_HOOD_MONITORINGS_ERROR';

export const DELETE_HOOD_MONITORINGS_REQUEST =
  'DELETE_HOOD_MONITORINGS_REQUEST';
export const DELETE_HOOD_MONITORINGS_SUCCESS =
  'DELETE_HOOD_MONITORINGS_SUCCESS';
export const DELETE_HOOD_MONITORINGS_ERROR = 'DELETE_HOOD_MONITORINGS_ERROR';

export const UPDATE_HOOD_MONITORING_REQUEST = 'UPDATE_HOOD_MONITORING_REQUEST';
export const UPDATE_HOOD_MONITORING_SUCCESS = 'UPDATE_HOOD_MONITORING_SUCCESS';
export const UPDATE_HOOD_MONITORING_ERROR = 'UPDATE_HOOD_MONITORING_ERROR';

export const CREATE_HOOD_MONITORING_REQUEST = 'CREATE_HOOD_MONITORING_REQUEST';
export const CREATE_HOOD_MONITORING_SUCCESS = 'CREATE_HOOD_MONITORING_SUCCESS';
export const CREATE_HOOD_MONITORING_ERROR = 'CREATE_HOOD_MONITORING_ERROR';

export const FINALIZE_HOOD_MONITORING_REQUEST =
  'FINALIZE_HOOD_MONITORING_REQUEST';
export const FINALIZE_HOOD_MONITORING_SUCCESS =
  'FINALIZE_HOOD_MONITORING_SUCCESS';
export const FINALIZE_HOOD_MONITORING_ERROR = 'FINALIZE_HOOD_MONITORING_ERROR';

export const CLEAR_CREATED_HOOD_MONITORING = 'CLEAR_CREATED_HOOD_MONITORING';
export const CLEAR_EDITED_HOOD_MONITORING = 'CLEAR_EDITED_HOOD_MONITORING';
