import styles from './index.module.scss';
import { PropTypes } from 'prop-types';

const { wrapper } = styles;

const AccionaAlert = ({ message }) => {
  return <div className={wrapper}>{message}</div>;
};

AccionaAlert.propTypes = {
  message: PropTypes.string.isRequired,
};

export default AccionaAlert;
