import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import AccionaBigButton from '../../../../../../components/AccionaBigButton';

const Home = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  return (
    <div className="wrapper fullscreen">
      <div className="row">
        <div className="col-xs-12 col-sm-4 col-md-4">
          <AccionaBigButton
            icon="monitorings"
            id="monitorings"
            onClick={() => navigate(`monitorings`)}
            msg={formatMessage({ id: `monitorings` })}
          />
        </div>
        <div className="col-xs-12 col-sm-4 col-md-4">
          <AccionaBigButton
            icon="devices"
            id="devices"
            onClick={() => navigate(`devices`)}
            msg={formatMessage({ id: `devices` })}
          />
        </div>
      </div>
    </div>
  );
};
export default Home;
