import { useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import styles from './index.module.scss';

const { wrapper } = styles;

const BatteryStatus = ({ battery }) => {
  const [isVisible, setIsVisible] = useState(false);
  const statusStyle = useMemo(() => {
    if (battery === 100) {
      return { className: 'battery-full', color: 'green' };
    }
    if (battery >= 75) {
      return { className: 'battery-three-quarters', color: 'green' };
    }
    if (battery >= 50) {
      return { className: 'battery-half', color: 'green' };
    }
    if (battery > 25) {
      return { className: 'battery-half', color: '#faad14' };
    }
    if (battery > 0) {
      return { className: 'battery-quarter', color: 'red' };
    }

    return { className: 'battery-empty', color: 'red' };
  }, [battery]);
  return (
    <span className={wrapper}>
      <i
        className={`fa fa-${statusStyle.className}`}
        style={{ color: statusStyle.color }}
        onClick={() => {
          setIsVisible((prev) => !prev);
        }}
      ></i>
      <span style={{ color: statusStyle.color, opacity: isVisible ? 1 : 0 }}>
        {battery + '%'}
      </span>
    </span>
  );
};

BatteryStatus.propTypes = {
  battery: PropTypes.number,
};

BatteryStatus.defaultProps = {
  battery: 0,
};

export default BatteryStatus;
