import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import AccionaButton from '../AccionaButton';
import styles from './index.module.scss';
import { useContext, useEffect } from 'react';
import { ConnectionContext } from '../../context/ConnectionContext/provider';
import { connect } from 'react-redux';

const AccionaListTabs = ({
  selectedTab,
  setSelectedTab,
  onBack,
  render,
  tabs,
  title,
  titleClassName,
  tabsClassName,
  extraTitle,
}) => {
  const intl = useIntl();
  const { online } = useContext(ConnectionContext);

  useEffect(() => {
    if (online.state === false) setSelectedTab(tabs[1]);
  }, [online.state, tabs, setSelectedTab]);

  return (
    <div id="AccionaList">
      <div>
        <div className={styles.header}>
          <div>
            <div className={`${titleClassName ?? ''}`}>{title}</div>
            {extraTitle && <div>{extraTitle}</div>}
          </div>
          <div>
            {tabs.map((tab) => (
              <AccionaButton
                key={tab}
                onClick={() => setSelectedTab(tab)}
                disabled={!online.state}
                msg={intl.formatMessage({
                  id: `button${tab[0].toUpperCase()}${tab.substring(1)}`,
                })}
                className={`${styles.tab} ${
                  selectedTab === tab ? '' : styles.noSelected
                } ${tabsClassName ?? ''}`}
              />
            ))}
          </div>
        </div>
        {render()}
      </div>
      <div>
        <AccionaButton
          msg={intl.formatMessage({ id: 'back' })}
          onClick={onBack}
          classIcon="icon fas fa-chevron-left"
          style={{ float: 'right', marginTop: '10px', marginRight: '0px' }}
        />
      </div>
    </div>
  );
};
const mapStateToProps = ({ offline }) => ({
  totalOfflineDocs: offline.total,
});

AccionaListTabs.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  totalOfflineDocs: PropTypes.number.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  header: PropTypes.element,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  tabsClassName: PropTypes.string,
  extraTitle: PropTypes.element,
};

AccionaListTabs.defaultProps = {
  title: 'Table',
  titleClassName: undefined,
  tabsClassName: undefined,
  totalOfflineDocs: 0,
  header: undefined,
  extraTitle: undefined,
};

export default connect(mapStateToProps, {})(AccionaListTabs);
