import React, { Fragment, useCallback } from 'react';
import { useMatches, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const { breadcrumbs, currentCrumb, projectHome } = styles;

const Breadcrumbs = () => {
  const intl = useIntl();
  const params = useParams();
  const matches = useMatches();
  const crumbs = matches.filter((match) => Boolean(match.handle?.crumb));
  const project = useSelector((state) =>
    state.projects.list.find(
      (project) =>
        project.id_project === (params?.id ? parseInt(params.id, 10) : 0),
    ),
  );

  const getBreadcrumb = useCallback(
    ({ index, handle, pathname, params }) => {
      const crumbText = `${
        // eslint-disable-next-line prettier/prettier
        handle.isProjectHome ? (project?.name ?? '') : handle.crumb
      }`;
      const isLastCrumb = index === crumbs.length - 1;
      const isSecondLastCrumb = index === crumbs.length - 2;
      const isEllipsisCrumb = crumbs.length > 2 && index === crumbs.length - 3;

      if (isEllipsisCrumb) {
        return (
          <Link to={pathname}>
            <i className="icon fas fa-chevron-left"></i>
          </Link>
        );
      } else if (isSecondLastCrumb) {
        return (
          <>
            <Link
              to={pathname}
              className={handle.isProjectHome ? projectHome : ''}
            >
              {crumbText}
            </Link>
            {!isLastCrumb && ' > '}
          </>
        );
      } else if (isLastCrumb) {
        return <span className={currentCrumb}>{crumbText}</span>;
      }

      return null;
    },
    [crumbs, project?.name],
  );
  return (
    <ul className={breadcrumbs}>
      {crumbs.map(({ handle, pathname, params }, index) => {
        return (
          <Fragment key={index}>
            {handle.isProjectHome && crumbs.length < 3 && (
              <li>
                <Link to="/app/projects">
                  {crumbs.length === 2 ? (
                    <i className="icon fas fa-chevron-left"></i>
                  ) : (
                    intl.formatMessage({ id: 'projects' })
                  )}
                </Link>
                {crumbs.length === 2 ? '' : ' > '}
              </li>
            )}
            <li key={index}>
              {getBreadcrumb({ index, handle, pathname, params })}
            </li>
          </Fragment>
        );
      })}
    </ul>
  );
};

export default Breadcrumbs;
