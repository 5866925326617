import { DatePicker } from 'antd';

const AntdRangePicker = ({ ...props }) => {
  return (
    <DatePicker.RangePicker
      allowClear={false}
      format="DD/MM/YYYY HH:mm"
      placement="bottomRight"
      popupStyle={{ transform: 'scale3d(0.9, 0.9, 0.9)' }}
      cellRender={(current, { type, originNode }) => {
        if (type === 'year' || type === 'month') {
          return (
            <div className={`ant-picker-cell-inner ${type}`}>
              {type === 'year' ? current.year() : current.format('MMM')}
            </div>
          );
        }
        return originNode;
      }}
      showTime
      inputReadOnly
      {...props}
    />
  );
};

export default AntdRangePicker;
