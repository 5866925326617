import { getQueryData } from '../../../utils/parseFilters';
import axios from '../api';
import { getUrl } from '../utils';

const endPoint = getUrl('api') + '/api/v2/hoodedControl/monitoring';

export const getMonitorings = async ({ page, size, filters }) => {
  if (filters.pid) {
    filters.project = filters.pid;
    delete filters.pid;
  }
  return axios
    .get(endPoint.concat(getQueryData(page, size, filters)))
    .then((res) => res);
};

export const getMonitoringData = async (monitoringId) =>
  axios.get(endPoint.concat(`/${monitoringId}/data`));

export const deleteMonitoring = async ({ id }) =>
  axios.delete(endPoint.concat('/', id)).then((res) => res);

export const createMonitoring = async (monitoring) =>
  axios.post(endPoint, monitoring).then((res) => res);

export const updateMonitoring = async (monitoring) =>
  axios.put(endPoint.concat('/', monitoring.id), monitoring).then((res) => res);

export const getMonitoringDataHistoricals = async ({
  monitoringId,
  startDate,
  endDate,
}) =>
  axios.get(
    endPoint.concat(
      `/${monitoringId}/data/historical?startDate=${startDate}&endDate=${endDate}`,
    ),
  );

export const downloadMonitoringDataHistoricals = async ({
  monitoringId,
  startDate,
  endDate,
}) =>
  axios.get(
    endPoint.concat(
      `/${monitoringId}/data/download?startDate=${startDate}&endDate=${endDate}`,
    ),
    { responseType: 'blob' },
  );

export const finalizeMonitoring = async (monitoring) =>
  axios
    .put(endPoint.concat('/', monitoring.id, '/finalize'), monitoring)
    .then((res) => res);
