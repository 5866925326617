import {
  GET_LOGIN_REQUEST,
  GET_LOGOUT_REQUEST,
  SET_LOGIN_ERROR,
} from './types';

const getLogin = ({ username, password }) => ({
  type: GET_LOGIN_REQUEST,
  payload: { username, password },
});

const getLogout = () => ({
  type: GET_LOGOUT_REQUEST,
});

const setLoginError = (payload) => ({
  type: SET_LOGIN_ERROR,
  payload,
});

export { getLogin, getLogout, setLoginError };
