export const detectedList = [
  '1_Cliente / Client',
  '2_Control Interno / Internal Control',
  '3_Otros / Others',
];

export const typeList = [
  '0_Uso incorrecto de documentos',
  '15_Errores de proyecto no detectados en su revisión',
  '20_Falta de documentación acreditativa de la calidad',
  '30_Recepción de Materiales: Material defectuoso o en mal estado, con defectos, o es diferente al solicitado (excepto hormigón in situ)',
  '31_Descarga, manipulación y acopio en obra: Materiales que han sufrido daños en uno de estos procesos.',
  '32_No conformidades en el suministro de hormigón que se pueden detectar en el momento de la recepción',
  '33_Errores en la planificación de los ensayos',
  '34_Resultados de ensayos de materiales no satisfactorios (incluyendo bajas resistencias del hormigón )',
  '35_Resultados no satisfactorios de pruebas de instalaciones o equipos',
  '40_Errores replanteo (topográficos o métricos)',
  '50_Defectos de geometría, planeidad, horizontalidad, verticalidad',
  '51_Defectos de humedad o compactación de terrenos, suelos o rellenos',
  '52_Defectos de colocación encofrados',
  '53_Defectos de armaduras pasivas (elaboración, montaje y colocación)',
  '54_Defectos de armaduras activas. Tesado',
  '55_Defectos de hormigonado',
  '56_Defectos de curado, desencofrado, descimbrado',
  '57_Defectos de colocación de prefabricados hormigón',
  '58_Defectos de montaje de instalaciones o de sus componentes',
  '59_Defectos de soldaduras y uniones de estructuras metálicas',
  '60_Defectos de estanqueidad (impermeabilización)',
  '61_Defectos de acabados',
  '62_Defectos de ejecución de juntas',
  '63_Defectos de unión al soporte (baldosas, azulejos etc…)',
  '64_Resultados no satisfactorios de pruebas finales de procesos',
  '65_Protección inadecuada de partes terminadas',
  '66_Defectos de realización no tipificados',
  '70_Emisiones atmosféricas incontroladas o superiores a límites',
  '71_Ruidos y vibraciones',
  '72_Vertidos incontrolados a cauces de agua o al mar',
  '73_Gestión inadecuada de residuos peligrosos',
  '74_Gestión inadecuada de otros residuos (tierras, RCD, RSU...)',
  '75_Contaminación de suelo',
  '76_Empleo incontrolado o inadecuado de recursos naturales y materias primas',
  '77_Afección al patrimonio cultural y arqueológico',
  '78_Afección a servicios existentes',
  '79_Afección al medio natural (fauna, flora, captaciones,…)',
  '80_Otras no conformidades medioambientales',
  '100_Gestión y documentación',
  '101_Señalización/Cerramiento/Iluminación/Organización general obra',
  '102_Instalaciones de higiene y bienestar / Primeros auxilios',
  '103_Circulación y accesos a tajos',
  '104_Protecciones personales',
  '105_Prevención técnica y protecciones colectivas',
  '106_Medios auxiliares',
  '107_Maquinaria y equipos de trabajo',
  '108_Instalaciones provisionales de obra',
  'ZZZ_otros',
  '00_Design',
  '10_Incorrect use of documents',
  '30_Material receiving: Defect or in bad condition material, or different from material ordered (except "in situ" concrete)',
  '32_Concrete supply nonconformities that are detected upon receiving',
  '33_Errors in test planning',
  '34_Non satisfactory material testing results (including low concrete strengths)',
  '36_Others materials and equipment nonconformities',
  '40_Surveying errors (topographical o metrical)',
  '50_Defects in geometry, flatness, horizontality, verticality',
  '52_Defective placement of formwork',
  '53_Defects in passive reinforcement (fabrication, assembly and placement)',
  '55_Defects from concreting',
  '58_Defects in assembly of systems and components',
  '60_Watertight defects (waterproofing)',
  '61_Finishing work defects',
  '67_Other non conformities on execution of work processes',
  '68_breach of procedure or instruction',
  '69_Overpass forecast vibrations speed limit near quick clay areas',
  '79_Effects on environment (fauna, flora, water uptake...)',
  '200_Overpass forecast vibrations speed limit over 50%',
  '201_Overpass forecast vibrations speed limit under 50%',
  '202_Overpass forecast vibrations speed limit in tunnels under 50%',
  '203_Overpass forecast vibrations speed limit in tunnels over 50%',
  'D20_Lack of quality related supporting documents',
  'D21_Other nonconformities on   ',
];

export const processList = [
  '101_REPLANTEOS',
  '102_REPOSICIÓN DE SERVICIOS AFECTADOS',
  '103_DEMOLICIONES Y DERRIBOS',
  '104_OBTENCIÓN, ALMACÉN Y DESECHO DE MATERIALES',
  '105_MONTAJE DE INSTALACIONES AUXILIARES',
  '106_LIMPIEZA Y MANTENIMIENTO DE OBRA Y ACCESOS',
  '107_EXPLOTACIÓN DE INSTALACIONES AUXILIARES',
  '201_VACIADOS, DESMONTES Y TERRAPLENES',
  '202_EXCAVACIONES Y RELLENOS EN ZANJAS, CIMIENTOS Y POZOS',
  '203_DRAGADOS, EXCAVACIONES Y RELLENOS MARINOS',
  '204_PERFORACIÓN DE TÚNELES, GALERÍAS Y POZOS',
  '205_CUERPO DE PRESA DE MATERIALES SUELTOS',
  '301_TRATAMIENTO Y DRENAJE DE TERRENOS',
  '302_SOSTENIMIENTO Y PROTECCIÓN DE TERRENOS',
  '303_TABLESTACADOS Y SIMILARES',
  '304_ESCOLLERAS Y MUROS DE PIEDRA',
  '305_MUROS DE TIERRA',
  '306_MANTOS DE PROTECCIÓN CON ELEMENTOS PREFABRICADOS',
  '401_CIMENTACIONES DE HORMIGÓN',
  '402_ESTRUCTURAS Y OBRAS DE HORMIGÓN IN SITU',
  '403_ESTRUCTURAS Y OBRAS DE HORMIGÓN PREFABRICADO',
  '404_ESTRUCTURAS DE ACERO',
  '405_ESTRUCTURAS DESMONTABLES Y PREFABRICADAS',
  '406_MACIZOS DE HORMIGÓN',
  '407_MACIZOS DE HORMIGÓN COMPACTADO',
  '408_OTRAS ESTRUCTURAS',
  '501_MONTAJE Y PROTECCIÍN DE TUBERÍAS',
  '502_HINCA DE TUBERÍAS',
  '503_OBRAS Y ELEMENTOS DE RECOGIDA DE AGUA',
  '504_RED HORIZONTAL DE SANEAMIENTO',
  '601_ELEMENTOS DE PROTECCIÓN (GEOTEXTILES, ETC.)',
  '602_SUBBASES Y BASES',
  '603_PAVIMENTOS BITUMINOSOS',
  '604_PAVIMENTOS DE HORMIGÓN',
  '605_PAVIMENTOS DE AGLOMERADO DE CEMENTO',
  '606_PAVIMENTOS CERÁMICOS',
  '607_PAVIMENTOS DE PIEDRA NATURAL',
  '608_PAVIMENTOS DE MADERA Y CORCHO',
  '609_PAVIMENTOS SINTÉTICOS Y TEXTILES',
  '610_PAVIMENTOS DEPORTIVOS',
  '611_PAVIMENTOS ELEVADOS',
  '612_PAVIMENTOS ESPECIALES',
  '613_RECICLADO DE PAVIMENTOS',
  '614_BORDILLOS, PELDAÑOS Y OTROS',
  '701_JUNTAS',
  '702_APOYOS',
  '703_IMPERMEABILIZACIONES DE CUBIERTAS, TABLEROS Y PARAMENTOS',
  '705_AISLAMIENTOS TÉRMICOS Y ACÚSTICOS (CUBIERTAS Y PARAMENTOS)',
  '801_MONTAJE DE VÍA',
  '802_MONTAJE DE CATENARIA EN ELECTRIFICACIÓN DE VÍA',
  '901_DISTRIBUCIONES INTERIORES Y AYUDAS',
  '902_FACHADAS Y MUROS',
  '903_CELOSÍAS',
  '1001_CUBIERTAS',
  '1002_TECHOS',
  '1101_ENFOSCADOS Y ENLUCIDOS',
  '1102_ESTUCADOS Y ESGRAFÍADOS',
  '1103_REVESTIMIENTOS DE MORTEROS Y MATERIAL AGLOMERADO',
  '1104_REVESTIMIENTOS CERÁMICOS',
  '1105_REVESTIMIENTOS DE MADERA Y CORCHO',
  '1106_REVESTIMIENTOS CONTINUOS',
  '1107_REVESTIMIENTOS ESPECIALES',
  '1108_CHAPADOS Y APLACADOS',
  '1201_CARPINTERÍA EXTERIOR',
  '1202_CARPINTERÍA INTERIOR',
  '1203_PUERTAS ESPECIALES',
  '1204_CERRAJERÍA',
  '1205_MAMPARAS',
  '1206_PERSIANAS',
  '1207_VIDRIOS',
  '1301_PINTURAS Y BARNICES',
  '1302_SEÑALIZACIÓN, BALIZAMIENTO Y BARRERAS DOBLE ONDA',
  '1303_BARRERAS PREFABRICADAS CONTRA RUIDO O VIENTO',
  '1304_DEFENSAS Y ELEMENTOS DE AMARRE',
  '1305_CERCADOS Y VALLAS',
  '1306_PLANTACIONES Y SIEMBRAS',
  '1401_FONTANERÍA Y AGUA CALIENTE SANITARIA',
  '1402_INSTALACIONES DE MOBILIARIO Y EQUIPAMIENTO URBANO',
  '1403_INSTALACIOINES DE MOBILIARIO DE EDIFICIO',
  '1404_INSTALACIONES AUDIOVISUALES Y DE COMUNICACIONES',
  '1405_INSTALACIONES DE CLIMATIZACIÓN Y VENTILACIÓN',
  '1406_INSTALACIONES DE DETECCIÓN, PROTECCIÓN Y CONTROL',
  '1407_INSTALACIONES DEPORTIVAS',
  '1408_INSTALACIONES ELÉCTRICAS E ILUMINACIÓN',
  '1409_INSTALACIONES ESPECIALES',
  '1410_INSTALACIONES INDUSTRIALES',
  '1411_INSTALACIONES PARA COMBUSTIBLES LÍQUIDOS Y GASES',
  '1412_INSTALACIONES PARA DEPURACIÓN, TRATAMIENTO Y DESALACIÓN DE AGUAS',
  '1413_INSTALACIONES EN PRESAS Y CANALES',
  '1414_INSTALACIONES DE AUSCULTACIÓN',
  '1415_INSTALACIONES DE RIEGO',
  '1416_INSTALACIONES DE BOMBEO',
  '1417_INSTALACIONES DE TRANSPORTE POR CABLE',
  '1418_APARATOS ELEVADORES',
  '1419_ESCALERAS Y PASILLOS MECÁNICOS',
  '1420_PASARELAS Y PUENTES TELESCÓPICOS',
  '1421_INSTALACIONES DE TRANSPORTE NEUMÁTICO',
  '1430_OTRAS INSTALACIONES',
  'ZZZZ Otros',
  '101_SURVEYING',
];
