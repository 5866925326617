export const GET_THERMAL_CONTROL_MIXTURES_REQUEST =
  'GET_THERMAL_CONTROL_MIXTURES_REQUEST';
export const GET_THERMAL_CONTROL_MIXTURES_SUCCESS =
  'GET_THERMAL_CONTROL_MIXTURES_SUCCESS';
export const GET_THERMAL_CONTROL_MIXTURES_ERROR =
  'GET_THERMAL_CONTROL_MIXTURES_ERROR';

export const GET_THERMAL_CONTROL_MIXTURE_REQUEST =
  'GET_THERMAL_CONTROL_MIXTURE_REQUEST';
export const GET_THERMAL_CONTROL_MIXTURE_SUCCESS =
  'GET_THERMAL_CONTROL_MIXTURE_SUCCESS';
export const GET_THERMAL_CONTROL_MIXTURE_ERROR =
  'GET_THERMAL_CONTROL_MIXTURE_ERROR';

export const DELETE_THERMAL_CONTROL_MIXTURES_REQUEST =
  'DELETE_THERMAL_CONTROL_MIXTURES_REQUEST';
export const DELETE_THERMAL_CONTROL_MIXTURES_SUCCESS =
  'DELETE_THERMAL_CONTROL_MIXTURES_SUCCESS';
export const DELETE_THERMAL_CONTROL_MIXTURES_ERROR =
  'DELETE_THERMAL_CONTROL_MIXTURES_ERROR';

export const UPDATE_THERMAL_CONTROL_MIXTURE_REQUEST =
  'UPDATE_THERMAL_CONTROL_MIXTURE_REQUEST';
export const UPDATE_THERMAL_CONTROL_MIXTURE_SUCCESS =
  'UPDATE_THERMAL_CONTROL_MIXTURE_SUCCESS';
export const UPDATE_THERMAL_CONTROL_MIXTURE_ERROR =
  'UPDATE_THERMAL_CONTROL_MIXTURE_ERROR';

export const CREATE_THERMAL_CONTROL_MIXTURE_REQUEST =
  'CREATE_THERMAL_CONTROL_MIXTURE_REQUEST';
export const CREATE_THERMAL_CONTROL_MIXTURE_SUCCESS =
  'CREATE_THERMAL_CONTROL_MIXTURE_SUCCESS';
export const CREATE_THERMAL_CONTROL_MIXTURE_ERROR =
  'CREATE_THERMAL_CONTROL_MIXTURE_ERROR';

export const FINALIZE_THERMAL_CONTROL_MIXTURE_REQUEST =
  'FINALIZE_THERMAL_CONTROL_MIXTURE_REQUEST';
export const FINALIZE_THERMAL_CONTROL_MIXTURE_SUCCESS =
  'FINALIZE_THERMAL_CONTROL_MIXTURE_SUCCESS';
export const FINALIZE_THERMAL_CONTROL_MIXTURE_ERROR =
  'FINALIZE_THERMAL_CONTROL_MIXTURE_ERROR';

export const CLEAR_CREATED_THERMAL_CONTROL_MIXTURE =
  'CLEAR_CREATED_THERMAL_CONTROL_MIXTURE';
export const CLEAR_EDITED_THERMAL_CONTROL_MIXTURE =
  'CLEAR_EDITED_THERMAL_CONTROL_MIXTURE';
