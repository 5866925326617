import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { SimpleBar } from '../SimpleBar';
import Modal from '../AccionaModal';
import { useParams } from 'react-router-dom';
import { PreRegisterDocumentCache } from '../../models/PreRegisterDocument';
import { savePreDeliveryNoteOnline } from '../../services/api/preDeliveryNote';
import { DELETE_PRE_DN_OFFLINE_REQUEST } from '../../services/redux/ocr/preDNOffline/types';
import { Alert } from 'antd';
import styles from './index.module.scss';

const { list } = styles;

const UploadModal = ({ show = true, onCancel, cachedDeliveryNotes }) => {
  const { formatMessage } = useIntl();
  const { id } = useParams();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [{ total, uploaded, fetching, error }, setUpload] = useState({
    total: 0,
    uploaded: 0,
    uploadAll: false,
    fetching: false,
    error: 0,
  });
  const [duplicates, setDuplicates] = useState([]);

  useEffect(() => {
    const uploadCachedPreDeliveryNotes = async () => {
      setUpload((prev) => ({ ...prev, fetching: true }));
      setUpload((prev) => ({ ...prev, total: cachedDeliveryNotes.length }));

      for (const i in cachedDeliveryNotes) {
        const preDNCache = PreRegisterDocumentCache.fromCache(
          cachedDeliveryNotes[i],
        );
        try {
          const uploadResponse = await savePreDeliveryNoteOnline(preDNCache);
          if (uploadResponse.status === 201) {
            dispatch({
              type: DELETE_PRE_DN_OFFLINE_REQUEST,
              payload: preDNCache.id,
            });
            setUpload((prev) => ({ ...prev, uploaded: prev.uploaded + 1 }));
          } else {
            setUpload((prev) => ({ ...prev, error: prev.error + 1 }));
          }
        } catch ({ response }) {
          setUpload((prev) => ({ ...prev, error: prev.error + 1 }));
          if (
            response.data.statusCode === 400 &&
            response.data.message.includes('project already exists')
          ) {
            setDuplicates((prev) => [...prev, preDNCache.docket_number]);
          }
        }
      }
      setUpload((prev) => ({ ...prev, uploadAll: true, fetching: false }));
    };
    if (id && cachedDeliveryNotes.length > 0) uploadCachedPreDeliveryNotes();
  }, [cachedDeliveryNotes, dispatch, id]);

  return (
    <Modal
      open={show}
      onCancel={() => {
        if (!fetching) {
          setUpload({
            total: 0,
            uploaded: 0,
            uploadAll: false,
            fetching: false,
            error: 0,
          });
          onCancel();
        }
      }}
      width="80vw"
    >
      <div style={{ margin: '10px' }}>
        <p>{formatMessage({ id: 'upload.title' }, { total: total })}</p>
        <p>
          {formatMessage(
            { id: 'upload.process' },
            { total: total, res: uploaded },
          )}
        </p>
        <p>
          {formatMessage({ id: 'upload.error' }, { total: total, res: error })}
        </p>
        {duplicates.length > 0 && (
          <Alert
            message="Números de albarán duplicados"
            description={
              <>
                <p>
                  {intl.formatMessage({ id: 'upload.duplicateDocketNumbers' })}
                </p>
                <ul className={list}>
                  {duplicates.map((d) => (
                    <li>{d}</li>
                  ))}
                </ul>
              </>
            }
            type="warning"
          />
        )}
        <SimpleBar values={[total, uploaded + error]} />{' '}
      </div>
    </Modal>
  );
};

UploadModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  countOffline: PropTypes.func.isRequired,
  cachedDeliveryNotes: PropTypes.arrayOf(PropTypes.object),
};

UploadModal.defaultProps = {
  cachedDeliveryNotes: [],
};

export default connect(() => ({}), {})(UploadModal);
