import * as TYPES from './types';

const initialState = {
  list: [],
  fetching: false,
  errorCreated: false,
  isCreated: false,
  isEdited: false,
  errorMessages: false,
  total: 0,
};
const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES.GET_HOOD_MONITORINGS_REQUEST:
    case TYPES.DELETE_HOOD_MONITORINGS_REQUEST:
    case TYPES.CREATE_HOOD_MONITORING_REQUEST:
    case TYPES.UPDATE_HOOD_MONITORING_REQUEST:
    case TYPES.FINALIZE_HOOD_MONITORING_REQUEST:
      return {
        ...state,
        fetching: true,
        isCreated: false,
        errorCreated: false,
      };

    case TYPES.GET_HOOD_MONITORINGS_ERROR:
    case TYPES.DELETE_HOOD_MONITORINGS_ERROR:
    case TYPES.UPDATE_HOOD_MONITORING_ERROR:
    case TYPES.FINALIZE_HOOD_MONITORING_ERROR:
      return {
        ...state,
        errorMessages: payload,
        fetching: false,
      };

    case TYPES.CREATE_HOOD_MONITORING_ERROR: {
      return {
        ...state,
        errorCreated: payload,
        fetching: false,
      };
    }

    case TYPES.DELETE_HOOD_MONITORINGS_SUCCESS: {
      const list = state.list;
      const index = list.findIndex((item) => item.id === payload.id);
      if (index !== -1) list.splice(index, 1);
      else list.push(index);
      return {
        ...state,
        list,
        fetching: false,
        total: state.total - 1,
      };
    }

    case TYPES.CREATE_HOOD_MONITORING_SUCCESS: {
      return {
        ...state,
        list: [...state.list, payload],
        fetching: false,
        total: state.total + 1,
        isCreated: true,
      };
    }

    case TYPES.UPDATE_HOOD_MONITORING_SUCCESS:
    case TYPES.FINALIZE_HOOD_MONITORING_SUCCESS: {
      const list = state.list;
      const index = list.findIndex((item) => item.id === payload.id);
      if (index !== -1) list.splice(index, 1, payload);
      else list.push(payload);
      return {
        ...state,
        list,
        fetching: false,
        isEdited: true,
      };
    }

    case TYPES.GET_HOOD_MONITORINGS_SUCCESS: {
      return {
        ...state,
        total: payload.total,
        list: [...state.list, ...payload.monitorings],
        fetching: false,
      };
    }

    case 'CLEAR_ERRORS': {
      return {
        ...state,
        errorCreated: false,
        errorMessages: false,
      };
    }

    case TYPES.CLEAR_CREATED_HOOD_MONITORING: {
      return { ...state, isCreated: false };
    }

    case TYPES.CLEAR_EDITED_HOOD_MONITORING: {
      return { ...state, isEdited: false };
    }

    case 'EMPTY_HOODEDCONTROLMONITORINGS_LIST': {
      return { ...state, list: [] };
    }

    default:
      return { ...state };
  }
};

export default reducer;
