class NonConformity {
  constructor({
    created,
    resume,
    responsible,
    description,
    detected_by,
    location,
    process,
    type,
  }) {
    this.created = created;
    this.resume = resume;
    this.responsible = responsible;
    this.description = description;
    this.detected_by = detected_by;
    this.location = location;
    this.process = process;
    this.type = type;
  }
}

export class NonConformityAPI extends NonConformity {
  constructor({
    id,
    created,
    resume,
    responsible,
    description,
    detected_by,
    location,
    process,
    type,
    file_name,
  }) {
    super({
      created,
      resume,
      responsible,
      description,
      detected_by,
      location,
      process,
      type,
    });
    this.id = id;
    this.file_name = file_name;
  }
}

export class NonConformityCache extends NonConformity {
  constructor({
    created,
    resume,
    responsible,
    description,
    detected_by,
    location,
    process,
    type,
  }) {
    super({
      created,
      resume,
      responsible,
      description,
      detected_by,
      location,
      process,
      type,
    });
  }
  toJSON() {
    return { ...this };
  }
  toApi() {
    return new NonConformityAPI({ ...this });
  }
}
