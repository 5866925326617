import Step from '../../../../../../../../components/Step';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import AccionaFormItem from '../../../../../../../../components/AccionaFormItem';
import AccionaInput from '../../../../../../../../components/AccionaInput';
import { useEffect, useState } from 'react';
import AccionaDropdown from '../../../../../../../../components/AccionaDropdown';
import AccionaTextArea from '../../../../../../../../components/AccionaTextArea';
import { detectedList, processList, typeList } from './selectorsData';
import { useOutletContext } from 'react-router-dom';
import AntdFormItem from '../../../../../../../../components/AntdFormItem';
import AntdDatePicker from '../../../../../../../../components/AntdDatePicker';
import dayjs from 'dayjs';

const NCData = ({ onFinish, preregister, onCancel }) => {
  const intl = useIntl();
  const { project } = useOutletContext();
  const { non_conformity_data: nc } = preregister.prm_data;
  const [nonConformityData, setNonConformityData] = useState({
    ...nc,
    created: nc.created ? dayjs(nc.created) : dayjs(),
  });
  const [error, setError] = useState({
    created: false,
    resume: false,
    responsible: false,
    detected_by: false,
    location: false,
    process: false,
    type: false,
  });

  useEffect(() => {
    setError((prev) => ({
      ...prev,
      resume: !nonConformityData.resume || nonConformityData.resume?.length < 3,
      detected_by: !nonConformityData.detected_by,
      location: !nonConformityData.location,
      process: !nonConformityData.process,
      type: !nonConformityData.type,
    }));
  }, [
    nonConformityData.detected_by,
    nonConformityData.location,
    nonConformityData.process,
    nonConformityData.resume,
    nonConformityData.type,
  ]);

  return (
    <Step
      title={intl.formatMessage({ id: 'preregisterWizard.section.nc.step01' })}
      stepNumber={1}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.nc',
      })}
      content={
        <>
          <AntdFormItem label={intl.formatMessage({ id: 'nc.created' })}>
            <AntdDatePicker />
          </AntdFormItem>
          <AccionaFormItem>
            <p label="nc">
              {intl.formatMessage(
                { id: 'nc.title' },
                {
                  center: project.name,
                  workcode: project.work_code,
                },
              )}
            </p>
          </AccionaFormItem>
          <AntdFormItem
            layout="vertical"
            label={intl.formatMessage({ id: 'nc.created' })}
          >
            <AntdDatePicker
              size="large"
              value={nonConformityData.created}
              showTime={false}
              onChange={(inputValue) => {
                setNonConformityData((prev) => ({
                  ...prev,
                  created: inputValue.format('DD/MM/YYYY'),
                }));
              }}
              allowClear
            />
          </AntdFormItem>
          <AccionaFormItem>
            <AccionaInput
              label="nc.resume"
              state={{
                value: nonConformityData.resume,
                status: error.resume ? 'error' : 'success',
              }}
              onChange={(value) => {
                setNonConformityData((prev) => ({
                  ...prev,
                  resume: value,
                }));
              }}
              valu
            />
          </AccionaFormItem>
          <div className={`${error.resume ? 'infoError' : ''}`}>
            {error.resume}
          </div>
          <AccionaFormItem>
            <p label="nc.responsible">{localStorage.getItem('username')}</p>
          </AccionaFormItem>
          <AccionaFormItem>
            <AccionaTextArea
              label="nc.description"
              state={{
                value: nonConformityData.description,
                status: error.description ? 'error' : 'success',
              }}
              onChange={(value) => {
                setNonConformityData((prev) => ({
                  ...prev,
                  description: value,
                }));
              }}
            />
          </AccionaFormItem>
          <div className={`${error.description ? 'infoError' : ''}`}>
            {error.description}
          </div>
          <AccionaFormItem>
            <AccionaDropdown
              id="ncDetectedBySelector"
              label="nc.detected_by"
              options={detectedList}
              selected={nonConformityData.detected_by}
              state={{
                value: nonConformityData.detected_by,
                status: error.detected_by ? 'error' : 'success',
              }}
              onClick={(value) => {
                setNonConformityData((prev) => ({
                  ...prev,
                  detected_by: value,
                }));
              }}
            />
          </AccionaFormItem>
          <div className={`${error.detected_by ? 'infoError' : ''}`}>
            {error.detected_by}
          </div>
          <AccionaFormItem>
            <AccionaInput
              label="nc.location"
              state={{
                value: nonConformityData.location,
                status: error.location ? 'error' : 'success',
              }}
              onChange={(value) => {
                setNonConformityData((prev) => ({
                  ...prev,
                  location: value,
                }));
              }}
            />
          </AccionaFormItem>
          <div className={`${error.location ? 'infoError' : ''}`}>
            {error.location}
          </div>
          <AccionaFormItem>
            <AccionaDropdown
              id="ncProcessSelector"
              label="nc.process"
              selected={nonConformityData.process}
              options={processList}
              state={{
                value: nonConformityData.process,
                status: error.process ? 'error' : 'success',
              }}
              onClick={(value) => {
                setNonConformityData((prev) => ({
                  ...prev,
                  process: value,
                }));
              }}
            />
          </AccionaFormItem>
          <div className={`${error.process ? 'infoError' : ''}`}>
            {error.process}
          </div>
          <AccionaFormItem>
            <AccionaDropdown
              id="ncTypeSelector"
              label="nc.type"
              options={typeList}
              selected={nonConformityData.type}
              state={{
                value: nonConformityData.type,
                status: error.type ? 'error' : 'success',
              }}
              onClick={(value) => {
                setNonConformityData((prev) => ({
                  ...prev,
                  type: value,
                }));
              }}
            />
          </AccionaFormItem>
          <div className={`${error.type ? 'infoError' : ''}`}>{error.type}</div>
        </>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish({
          ...preregister,
          prm_data: {
            ...preregister.prm_data,
            non_conformity_data: {
              ...nonConformityData,
              created: nonConformityData.created?.format('DD/MM/YYYY'),
              responsible: localStorage.getItem('username'),
            },
          },
        });
      }}
      disableNextButton={
        error.location ||
        error.resume ||
        !nonConformityData.resume ||
        !nonConformityData.location
      }
    />
  );
};

NCData.propTypes = {
  preregister: PropTypes.shape({
    prm_data: PropTypes.shape({
      non_conformity_data: PropTypes.shape({
        created: PropTypes.string,
        resume: PropTypes.string,
        responsible: PropTypes.string,
        detected_by: PropTypes.string,
        location: PropTypes.string,
        process: PropTypes.string,
        type: PropTypes.string,
      }),
    }),
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

NCData.defaultProps = {
  preregister: {},
};

export default NCData;
