import { PropTypes } from 'prop-types';
//Importaciones temporales
import logoAcciona from '../../style/img/placeholder.jpg';
import flagES from '../../style/img/flags/es.png';

const ProjectsList = ({ allProjects, onClick }) => {
  return (
    <div className="wrapper">
      <div className="row row-project" style={{ background: 'none' }}>
        {allProjects.map((project) => {
          return (
            <span key={project.id_project} onClick={() => onClick(project)}>
              <div className="col-sm-6">
                <div
                  className="project-wrapper"
                  style={{
                    padding: '15px',
                    marginBottom: '20px',
                    backgroundColor: '#fff',
                    borderRadius: '5px',
                  }}
                >
                  <div className="row">
                    <div className="col-xs-9" style={{ textAlign: 'center' }}>
                      <img alt="logoAcciona" src={logoAcciona}></img>
                    </div>
                    <div className="col-xs-3" style={{ textAlign: 'center' }}>
                      <img
                        alt="logoAcciona"
                        src={logoAcciona}
                        style={{ display: 'block', marginBottom: '15px' }}
                      ></img>
                      <img
                        alt="flagES"
                        src={flagES}
                        style={{ width: '50%' }}
                      ></img>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: '10px' }}>
                    <div className="col-xs-12">
                      <h4
                        style={{
                          fontFamily: "'Acciona Font', sans-serif",
                          color: '#f00',
                          textTransform: 'uppercase',
                        }}
                      >
                        {project.name}
                      </h4>
                      <p>{project.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          );
        })}
      </div>
    </div>
  );
};

ProjectsList.propTypes = {
  allProjects: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func.isRequired,
};

ProjectsList.defaultProps = {
  allProjects: [],
};

export default ProjectsList;
