import { PropTypes } from 'prop-types';
import styles from './index.module.scss';
import { createPortal } from 'react-dom';
import { useEffect } from 'react';
const { wrapper, hidden, content, innerContent, innerTitle, closeIcon } =
  styles;

const Modal = ({
  children,
  open,
  onCancel,
  width,
  title,
  closable,
  backgroundColor,
  id,
  height,
}) => {
  useEffect(() => {
    const currentScroll = window.scrollY;
    if (open) {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      window.scrollTo(0, currentScroll);
    }

    return () => {
      document.body.style.overflow = 'auto';
      window.scrollTo(0, currentScroll);
    };
  }, [open]);

  return createPortal(
    <div className={`${wrapper} ${!open ? hidden : ''}`} id={id}>
      <div className={content} style={{ backgroundColor }}>
        {closable && (
          <i className={`icon fas fa-times ${closeIcon}`} onClick={onCancel} />
        )}
        {title && <div className={innerTitle}>{title}</div>}
        <div className={innerContent} style={{ width, height }}>
          {open ? children : null}
        </div>
      </div>
    </div>,
    document.body,
  );
};

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  string: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  closable: PropTypes.bool,
  backgroundColor: PropTypes.string,
  id: PropTypes.string,
};

Modal.defaultProps = {
  onCancel: () => {},
  onSuccess: () => {},
  width: '100%',
  height: 'auto',
  title: undefined,
  closable: false,
  backgroundColor: '#ffffff',
  id: undefined,
};

export default Modal;
