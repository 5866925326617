export const GET_THERMAL_CONTROL_DEVICES_REQUEST =
  'GET_THERMAL_CONTROL_DEVICES_REQUEST';
export const GET_THERMAL_CONTROL_DEVICES_SUCCESS =
  'GET_THERMAL_CONTROL_DEVICES_SUCCESS';
export const GET_THERMAL_CONTROL_DEVICES_ERROR =
  'GET_THERMAL_CONTROL_DEVICES_ERROR';

export const FETCH_THERMAL_CONTROL_DEVICES_REQUEST =
  'FETCH_THERMAL_CONTROL_DEVICES_REQUEST';
export const FETCH_THERMAL_CONTROL_DEVICES_SUCCESS =
  'FETCH_THERMAL_CONTROL_DEVICES_SUCCESS';
export const FETCH_THERMAL_CONTROL_DEVICES_ERROR =
  'FETCH_THERMAL_CONTROL_DEVICES_ERROR';

export const GET_UNUSED_THERMAL_CONTROL_DEVICES_REQUEST =
  'GET_UNUSED_THERMAL_CONTROL_DEVICES_REQUEST';
export const GET_UNUSED_THERMAL_CONTROL_DEVICES_SUCCESS =
  'GET_UNUSED_THERMAL_CONTROL_DEVICES_SUCCESS';
export const GET_UNUSED_THERMAL_CONTROL_DEVICES_ERROR =
  'GET_UNUSED_THERMAL_CONTROL_DEVICES_ERROR';

export const DELETE_THERMAL_CONTROL_DEVICES_REQUEST =
  'DELETE_THERMAL_CONTROL_DEVICES_REQUEST';
export const DELETE_THERMAL_CONTROL_DEVICES_SUCCESS =
  'DELETE_THERMAL_CONTROL_DEVICES_SUCCESS';
export const DELETE_THERMAL_CONTROL_DEVICES_ERROR =
  'DELETE_THERMAL_CONTROL_DEVICES_ERROR';

export const UPDATE_THERMAL_CONTROL_DEVICE_REQUEST =
  'UPDATE_THERMAL_CONTROL_DEVICE_REQUEST';
export const UPDATE_THERMAL_CONTROL_DEVICE_SUCCESS =
  'UPDATE_THERMAL_CONTROL_DEVICE_SUCCESS';
export const UPDATE_THERMAL_CONTROL_DEVICE_ERROR =
  'UPDATE_THERMAL_CONTROL_DEVICE_ERROR';

export const CREATE_THERMAL_CONTROL_DEVICE_REQUEST =
  'CREATE_THERMAL_CONTROL_DEVICE_REQUEST';
export const CREATE_THERMAL_CONTROL_DEVICE_SUCCESS =
  'CREATE_THERMAL_CONTROL_DEVICE_SUCCESS';
export const CREATE_THERMAL_CONTROL_DEVICE_ERROR =
  'CREATE_THERMAL_CONTROL_DEVICE_ERROR';

export const CLEAR_CREATED_THERMAL_CONTROL_DEVICE =
  'CLEAR_CREATED_THERMAL_CONTROL_DEVICE';
export const CLEAR_EDITED_THERMAL_CONTROL_DEVICE =
  'CLEAR_EDITED_THERMAL_CONTROL_DEVICE';
