import React from 'react';
import { PropTypes } from 'prop-types';
import styles from './index.module.scss';
import AccionaButton from '../AccionaButton';
import { useIntl } from 'react-intl';

const { confirmOverlay, confirmBox, footer } = styles;

const AccionaConfirm = ({ message, onConfirm, onCancel }) => {
  const intl = useIntl();
  return (
    <div className={confirmOverlay}>
      <div className={confirmBox}>
        <p>{message}</p>
        <div className={footer}>
          <AccionaButton
            onClick={onConfirm}
            msg={intl.formatMessage({ id: 'ok' })}
          ></AccionaButton>
          <AccionaButton
            onClick={onCancel}
            msg={intl.formatMessage({ id: 'cancel' })}
          ></AccionaButton>
        </div>
      </div>
    </div>
  );
};

AccionaConfirm.propTypes = {
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
export default AccionaConfirm;
