import {
  GET_PROJECT_REQUEST,
  GET_PROJECTS_REQUEST,
  CLEAR_PROJECTS_STATE,
  INIT_REDUCER,
  CLEAR_ERRORS,
  CLEAR_ERRORS_PROFILE,
  CREATE_ERRORS,
} from './types';

const getProject = (payload) => ({
  type: GET_PROJECT_REQUEST,
  payload,
});

const getProjects = (payload) => {
  return {
    type: GET_PROJECTS_REQUEST,
    payload,
  };
};

const clearProjectsState = () => ({
  type: CLEAR_PROJECTS_STATE,
});

const initReducer = () => ({
  type: INIT_REDUCER,
});

const clearErrors = () => ({
  type: CLEAR_ERRORS,
});

const clearErrorsProfile = () => ({
  type: CLEAR_ERRORS_PROFILE,
});

const createErrors = (payload) => ({
  type: CREATE_ERRORS,
  payload: payload,
});

export {
  getProject,
  getProjects,
  clearProjectsState,
  initReducer,
  clearErrors,
  clearErrorsProfile,
  createErrors,
};
