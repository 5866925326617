import {
  CLEAR_CREATED_HOOD_MONITORING,
  CLEAR_EDITED_HOOD_MONITORING,
  CREATE_HOOD_MONITORING_REQUEST,
  DELETE_HOOD_MONITORINGS_REQUEST,
  FINALIZE_HOOD_MONITORING_REQUEST,
  GET_HOOD_MONITORINGS_REQUEST,
  UPDATE_HOOD_MONITORING_REQUEST,
} from './types';

const getMonitorings = (payload) => ({
  type: GET_HOOD_MONITORINGS_REQUEST,
  payload,
});

const deleteMonitorings = (payload) => ({
  type: DELETE_HOOD_MONITORINGS_REQUEST,
  payload,
});

const createMonitoring = (payload) => ({
  type: CREATE_HOOD_MONITORING_REQUEST,
  payload,
});

const updateMonitoring = (payload) => ({
  type: UPDATE_HOOD_MONITORING_REQUEST,
  payload,
});

const finalizeMonitoring = (payload) => ({
  type: FINALIZE_HOOD_MONITORING_REQUEST,
  payload,
});

const clearErrors = () => ({
  type: 'CLEAR_ERRORS',
});

const clearCreated = () => ({
  type: CLEAR_CREATED_HOOD_MONITORING,
});

const clearEdited = () => ({
  type: CLEAR_EDITED_HOOD_MONITORING,
});

export {
  deleteMonitorings,
  getMonitorings,
  createMonitoring,
  updateMonitoring,
  finalizeMonitoring,
  clearErrors,
  clearCreated,
  clearEdited,
};
