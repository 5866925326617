import {
  DELETE_PRE_DN_ONLINE_REQUEST,
  GET_PRE_DN_ONLINE_REQUEST,
  SAVE_PRE_DN_REQUEST,
} from './types';

export const getPreDNOnline = (payload) => ({
  type: GET_PRE_DN_ONLINE_REQUEST,
  payload,
});

export const savePreDN = (payload) => ({
  type: SAVE_PRE_DN_REQUEST,
  payload,
});

export const deletePreDNOnline = (payload) => ({
  type: DELETE_PRE_DN_ONLINE_REQUEST,
  payload,
});
