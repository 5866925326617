import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import styles from './index.module.scss';
import MaturityMethods from '../../../../../../../../../constants/maturityMethods';

const { sensors, minmaxTemperatureWrapper, sensor } = styles;

const DataRow = ({ title, description, channelData, maturityMethod }) => {
  const intl = useIntl();
  const getColumnMethod = () => {
    return (
      <div>
        {intl.formatMessage({
          id: `strength.thermalcontrol.monitorings.view.${
            maturityMethod === MaturityMethods.NURSE_SAUL
              ? 'maturity'
              : 'equivalentAge'
          }`,
        })}{' '}
        <span>
          {`(${intl.formatMessage({
            id: `maturityMethod.${maturityMethod}`,
          })})`}
        </span>
        :
      </div>
    );
  };

  return (
    <div className={sensor}>
      <div>
        <p>{title}</p>
      </div>
      <div>
        <p>
          {intl.formatMessage({
            id: 'strength.hoodedcontrol.monitorings.view.description',
          })}
          :
        </p>
        <p>{description}</p>
      </div>
      <div>
        <p>
          {intl.formatMessage({
            id: 'strength.hoodedcontrol.monitorings.view.temperature',
          })}
          :
        </p>
        <p>
          {channelData?.temperature?.toFixed(1) &&
            `${channelData?.temperature?.toFixed(1)}ºC`}
        </p>
      </div>
      <div>
        <p>
          {intl.formatMessage({
            id: 'strength.hoodedcontrol.monitorings.view.max.min.temperature',
          })}
          :
        </p>
        <p className={minmaxTemperatureWrapper}>
          {channelData?.max && <div>{channelData?.max.toFixed(1)}ºC</div>}
          {channelData?.max && channelData?.min !== undefined && '/'}
          {channelData?.min !== undefined && (
            <div>{channelData?.min.toFixed(1)}ºC</div>
          )}
        </p>
      </div>
      <div>
        <p>{getColumnMethod()}</p>
        <p>
          {channelData?.maturity
            ? `${channelData?.maturity?.toFixed(1)}${
                maturityMethod === MaturityMethods.NURSE_SAUL ? 'ºC-h' : ` d`
              }`
            : ''}
        </p>
      </div>
    </div>
  );
};

DataRow.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  channelData: PropTypes.object.isRequired,
  maturityMethod: PropTypes.string.isRequired,
};

DataRow.defaultProps = {
  description: '',
};

const MonitoringData = ({ monitoring }) => {
  const intl = useIntl();
  return (
    <div className={sensors}>
      {[...Array(6)].map((_, index) => {
        const channelData = monitoring.data?.promed[`ch${index + 1}`];
        const channel = monitoring[`ch${index + 1}`];
        return (
          <DataRow
            key={`Ch${index + 1}`}
            title={intl.formatMessage(
              { id: 'strength.hoodedcontrol.monitorings.create.channel' },
              { number: index + 1 },
            )}
            description={channel}
            channelData={channelData}
            maturityMethod={monitoring.maturity_method}
          />
        );
      })}
      <DataRow
        title=""
        description={intl.formatMessage({
          id: 'strength.hoodedcontrol.monitorings.view.average.concrete',
        })}
        channelData={monitoring.data?.promed.mean}
        maturityMethod={monitoring.maturity_method}
      />
      {[...Array(2)].map((_, index) => {
        const channelData = monitoring.data?.amb[`ch${index + 7}`];
        return (
          <DataRow
            key={`Ch${index + 7}`}
            title={intl.formatMessage(
              { id: 'strength.hoodedcontrol.monitorings.create.channel' },
              { number: index + 7 },
            )}
            description={intl.formatMessage({
              id: index === 0 ? 'probe' : 'environment',
            })}
            channelData={channelData}
            maturityMethod={monitoring.maturity_method}
          />
        );
      })}
    </div>
  );
};

MonitoringData.propTypes = {
  monitoring: PropTypes.shape({
    maturity_method: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
};

export default MonitoringData;
