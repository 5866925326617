import { getQueryData } from '../../../utils/parseFilters';
import axios from '../api';
import { getUrl } from '../utils';

const endPoint = getUrl('api') + '/api/v2/hoodedControl/devices';

export const getDevices = async ({ page, size, filters }) => {
  if (filters.pid) {
    // filters.project = filters.pid;
    delete filters.pid;
  }
  return axios
    .get(endPoint.concat(getQueryData(page, size, filters)))
    .then((res) => res);
};

export const fetchDevices = async ({ page, size, filters }) => {
  if (filters.pid) {
    filters.project = filters.pid;
    delete filters.pid;
  }
  return axios
    .get(endPoint.concat('/search', getQueryData(page, size, filters)))
    .then((res) => res);
};

export const getUnusedDevices = async () => {
  return axios.get(endPoint.concat('/unused')).then((res) => res);
};

export const getDevice = async (deviceId) =>
  axios.get(endPoint.concat(`/${deviceId}`));

export const deleteDevice = async ({ id }) =>
  axios.delete(endPoint.concat('/', id)).then((res) => res);

export const createDevice = async (device) =>
  axios.post(endPoint, device).then((res) => res);

export const updateDevice = async (device) =>
  axios.put(endPoint.concat('/', device.id), device).then((res) => res);

export const checkDeviceExists = async (id) =>
  axios.get(endPoint.concat('/', 'search/', id), { timeout: '3000' });
