import { useContext, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { ConnectionContext } from '../context/ConnectionContext/provider';
import ErrorPage from '../pages/Utils/ErrorPage';
import { useDispatch, useSelector } from 'react-redux';
import Header from './components/Header';
import Breadcrumbs from './components/Breadcrumbs';
import Footer from './components/Footer';
import { clearErrors, getProjects } from '../services/redux/projects/actions';
import styles from './index.module.scss';

const { content } = styles;

const Layout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);
  const { errorMessages } = useSelector((state) => state.projects);
  const { loading, setLoading, error, createError } =
    useContext(ConnectionContext);

  useEffect(() => {
    if (isLoggedIn) {
      setLoading(true);
      try {
        dispatch(getProjects({ size: 100, page: 1 }));
        dispatch(clearErrors(null));
        setLoading(false);
      } catch (error) {
        createError({
          status: error.status,
          statusText: error.statusText,
          errorType: 'GET_PROJECTS_ERROR',
        });
      }
    }
  }, [createError, dispatch, isLoggedIn, setLoading]);

  useEffect(() => {
    if (errorMessages) {
      createError({
        status: errorMessages.status,
        statusText: errorMessages.statusText,
        errorType: 'GET_PROJECTS_ERROR',
      });
    }
  }, [createError, errorMessages]);

  useEffect(() => {
    setLoading(false);
  }, [location, setLoading]);

  return isLoggedIn ? (
    <>
      <Header />
      <Breadcrumbs />
      <div className={content}>
        {error ? (
          <ErrorPage errorMessages={error} />
        ) : (
          <>
            {loading && <Spinner loading />}
            <Outlet />
          </>
        )}
      </div>
      <Footer />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default Layout;
