/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { PropTypes } from 'prop-types';

const AccionaBigButton = ({ msg, icon, onClick }) => (
  <div className={`home-button ${icon}`}>
    <a className="home-button" id="AccionaBigUploadButton" onClick={onClick}>
      <div className="image-btn"> </div>
      <span> {msg}</span>
    </a>
  </div>
);

AccionaBigButton.propTypes = {
  msg: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
};

AccionaBigButton.defaultProps = {
  msg: 'UPLOAD',
  icon: 'data',
};

export default AccionaBigButton;
