export const GET_HOOD_DEVICES_REQUEST = 'GET_HOOD_DEVICES_REQUEST';
export const GET_HOOD_DEVICES_SUCCESS = 'GET_HOOD_DEVICES_SUCCESS';
export const GET_HOOD_DEVICES_ERROR = 'GET_HOOD_DEVICES_ERROR';

export const FETCH_HOOD_DEVICES_REQUEST = 'FETCH_HOOD_DEVICES_REQUEST';
export const FETCH_HOOD_DEVICES_SUCCESS = 'FETCH_HOOD_DEVICES_SUCCESS';
export const FETCH_HOOD_DEVICES_ERROR = 'FETCH_HOOD_DEVICES_ERROR';

export const GET_UNUSED_HOOD_DEVICES_REQUEST =
  'GET_UNUSED_HOOD_DEVICES_REQUEST';
export const GET_UNUSED_HOOD_DEVICES_SUCCESS =
  'GET_UNUSED_HOOD_DEVICES_SUCCESS';
export const GET_UNUSED_HOOD_DEVICES_ERROR = 'GET_UNUSED_HOOD_DEVICES_ERROR';

export const DELETE_HOOD_DEVICES_REQUEST = 'DELETE_HOOD_DEVICES_REQUEST';
export const DELETE_HOOD_DEVICES_SUCCESS = 'DELETE_HOOD_DEVICES_SUCCESS';
export const DELETE_HOOD_DEVICES_ERROR = 'DELETE_HOOD_DEVICES_ERROR';

export const UPDATE_HOOD_DEVICE_REQUEST = 'UPDATE_HOOD_DEVICE_REQUEST';
export const UPDATE_HOOD_DEVICE_SUCCESS = 'UPDATE_HOOD_DEVICE_SUCCESS';
export const UPDATE_HOOD_DEVICE_ERROR = 'UPDATE_HOOD_DEVICE_ERROR';

export const CREATE_HOOD_DEVICE_REQUEST = 'CREATE_HOOD_DEVICE_REQUEST';
export const CREATE_HOOD_DEVICE_SUCCESS = 'CREATE_HOOD_DEVICE_SUCCESS';
export const CREATE_HOOD_DEVICE_ERROR = 'CREATE_HOOD_DEVICE_ERROR';

export const CLEAR_CREATED_HOOD_DEVICE = 'CLEAR_CREATED_HOOD_DEVICE';
export const CLEAR_EDITED_HOOD_DEVICE = 'CLEAR_EDITED_HOOD_DEVICE';
