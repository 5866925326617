const GET_LOGIN_REQUEST = 'GET_LOGIN_REQUEST';
const GET_LOGIN_SUCCESS = 'GET_LOGIN_SUCCESS';
const GET_LOGIN_ERROR = 'GET_LOGIN_ERROR';
const GET_LOGOUT_REQUEST = 'GET_LOGOUT_REQUEST';
const GET_LOGOUT_SUCCESS = 'GET_LOGOUT_SUCCESS';
const GET_LOGOUT_ERROR = 'GET_LOGOUT_ERROR';

const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';

export {
  GET_LOGIN_REQUEST,
  GET_LOGIN_SUCCESS,
  GET_LOGIN_ERROR,
  GET_LOGOUT_REQUEST,
  GET_LOGOUT_SUCCESS,
  GET_LOGOUT_ERROR,
  SET_LOGIN_ERROR,
};
