import { all, fork, put, takeEvery } from 'redux-saga/effects';
import * as types from './types';
import Device from '../../../../../models/thermalControl/Device';
import * as api from '../../../../api/thermalControl/devices';
import { callToAPI } from '../../../../../utils/callToAPI';

function* getDevices({ payload }) {
  yield callToAPI({
    apiCall: api.getDevices,
    successResponseCode: 200,
    errorType: types.GET_THERMAL_CONTROL_DEVICES_ERROR,
    *callback({ data }) {
      yield put({
        type: types.GET_THERMAL_CONTROL_DEVICES_SUCCESS,
        payload: {
          devices: data.data.map((devices) => {
            return new Device(devices);
          }),
          total: data.total,
        },
      });
    },
    payload,
  });
}

function* getUnusedDevices({ payload }) {
  yield callToAPI({
    apiCall: api.getUnusedDevices,
    successResponseCode: 200,
    errorType: types.GET_UNUSED_THERMAL_CONTROL_DEVICES_ERROR,
    *callback({ data }) {
      yield put({
        type: types.GET_UNUSED_THERMAL_CONTROL_DEVICES_SUCCESS,
        payload: {
          devices: data.data.map((devices) => {
            return new Device(devices);
          }),
          total: data.total,
        },
      });
    },
    payload,
  });
}

/* watchers */
function* watcherGetDevices() {
  yield takeEvery(types.GET_THERMAL_CONTROL_DEVICES_REQUEST, getDevices);
}

function* watcherGetUnusedDevices() {
  yield takeEvery(
    types.GET_UNUSED_THERMAL_CONTROL_DEVICES_REQUEST,
    getUnusedDevices,
  );
}

export default function* rootSaga() {
  yield all([fork(watcherGetDevices)]);
  yield all([fork(watcherGetUnusedDevices)]);
}
