import { useState } from 'react';
import Step from '../../../../../../../../components/Step';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import styles from './index.module.scss';

const { wrapper, checkbox } = styles;

const Checkbox = ({ name, onChange, checked }) => {
  const intl = useIntl();

  return (
    <div className={checkbox}>
      <label key={name}>
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={onChange}
        />
        {intl.formatMessage({ id: name })}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const parseElements = (elements) => {
  const parsedElements = {
    pilares: false,
    murosPortantes: false,
    pilotes: false,
    pilas: false,
    vigas: false,
    forjado: false,
    tablerosPuente: false,
    murosContencion: false,
    zapatas: false,
    losas: false,
    estribosPuente: false,
    bloques: false,
    losasMarco: false,
    riostras: false,
    encepados: false,
    pantallas: false,
    micropilotes: false,
  };

  if (elements) {
    const splitElements = elements.split(',');

    splitElements.forEach((e) => {
      parsedElements[e] = true;
    });
  }

  return parsedElements;
};

const Elements = ({ onFinish, preregister, onCancel, stepNumber }) => {
  const intl = useIntl();
  const [elements, setElements] = useState(parseElements(preregister.elements));

  const middleIndex = Math.ceil(Object.keys(elements).length / 2);

  const onCheckboxChange = ({ target }) => {
    const { name, checked } = target;
    setElements((prevElements) => ({ ...prevElements, [name]: checked }));
  };

  return (
    <Step
      title={intl.formatMessage({
        id: 'preregisterWizard.section.preregister.step05',
      })}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.preregister',
      })}
      stepNumber={stepNumber}
      content={
        <div className={wrapper}>
          <div>
            {Object.keys(elements)
              .slice(0, middleIndex)
              .map((element) => {
                return (
                  <Checkbox
                    key={element}
                    name={element}
                    checked={elements[element]}
                    onChange={onCheckboxChange}
                  />
                );
              })}
          </div>
          <div>
            {Object.keys(elements)
              .slice(middleIndex)
              .map((element) => {
                return (
                  <Checkbox
                    key={element}
                    name={element}
                    checked={elements[element]}
                    onChange={onCheckboxChange}
                  />
                );
              })}
          </div>
        </div>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish({
          ...preregister,
          elements: Object.keys(elements)
            .filter((key) => {
              if (elements[key]) {
                return key;
              }
              return null;
            })
            .filter((key) => key)
            .join(','),
        });
      }}
    />
  );
};

Elements.propTypes = {
  preregister: PropTypes.shape({
    elements: PropTypes.string,
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
};

Elements.defaultProps = {
  preregister: {},
};

export default Elements;
