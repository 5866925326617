import { Result } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import AccionaButton from '../../components/AccionaButton';
import { PropTypes } from 'prop-types';
const SuccessPage = ({ formatmsg, extraButton }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const intl = useIntl();

  return (
    <Result
      status="success"
      id="succesResult"
      icon={
        <i className="fas fa-check-circle fa-8x" style={{ color: '#82c91e' }} />
      }
      title={<FormattedMessage id={formatmsg} />}
      className="success"
      extra={[
        <div key="extraSuccess1" className="form-row">
          <AccionaButton
            id="successBtn"
            onClick={() => navigate(`/app/project/${id}`)}
            msg={intl.formatMessage({ id: 'button.create.end' })}
          />
          {extraButton}
        </div>,
      ]}
    />
  );
};

SuccessPage.propTypes = {
  pid: PropTypes.string,
  formatmsg: PropTypes.string.isRequired,
  extraButton: PropTypes.element,
};

SuccessPage.defaultProps = {
  pid: '3',
  extraButton: undefined,
};

export default SuccessPage;
